import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";
import {stationLookup} from "../models/stations";

const Custom: React.FC = () => {
    var station = stationLookup.get(ProductionItemOperations.Custom);
    if (!station) {
        throw new Error(`Station not found`);
    }
    return (
        <>
            <PageTitle
                color={station.color}
                breadcrumbs={[]}
            >
                {station.title}
            </PageTitle>

            <ProductionOperation scope={[`Custom:c-0`,`Status:Started`,`Status:NotStarted`]} operation={ProductionItemOperations.Custom} />
        </>
    )
}

export default Custom
