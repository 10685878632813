import React, {FC} from 'react';
import ApiClient from '../../../modules/api-client/ApiClient';
import EkoFormField from '../EkoFormField';
import {StopProfilePicker} from '../../../backoffice/modules/pim/stopprofile/StopProfilePicker';

export const CustomerPreferencesDeurBuitendraaiend: FC = () => {

	const queryFn = () => ApiClient.Preferences.Preferences.getStopProfiles().then((res) => res.data);

	return (
		<>
			<EkoFormField
				row={true}
				label={'Standaard aanslagprofiel'}
				field={{
					name: 'stopProfileId',
					component: StopProfilePicker({
						required: false,
						multi: false,
						queryFn: queryFn,
					}),
				}}
			/>
		</>
	);
};