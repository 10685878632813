import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {ConfiguredSillSluitpot, ProductionItemOperations} from '../../../../modules/api-client/generated';
import ApiClient from '../../../../modules/api-client/ApiClient';
import Loading from '../../../../shared/components/Loading';
import Error from '../../../../shared/components/Error';
import {EkoCard} from '../../../../shared/components/card/EkoCard';
import {EkoCardBody} from '../../../../shared/components/card/EkoCardBody';
import {DestructCompartment} from '../../core';
import {stationLookup} from "../models/stations";

export interface AfmontageSpecificationProps {
    id: string,
    remark?: string | undefined
    bakNumber?: string | undefined
    instruction?: string | undefined
}

const AfmontageSpecification: React.FC<AfmontageSpecificationProps> = (props) => {

        const {
            isInitialLoading,
            isLoading,
            data,
            isError,
        } = useQuery(['ApiClient.Core.ConfiguredSill.getForCatalogItem', props.id], async () => {
            const response = await ApiClient.Core.ConfiguredSill.getForCatalogItem(props.id);
            return response.data;
        });

        if (isLoading || isInitialLoading) {
            return <Loading message={'Dorpel specificatie ophalen....'}/>;
        }

        if (isError) return <Error/>;


        const {sill} = data;

        if (!sill) return <></>;

        const glaslatten = sill.compartments.filter(c => DestructCompartment(c).glazingBar).length;

        const sluitpotten = sill.compartments.filter(c => {
            return DestructCompartment(c).sluitpotten?.filter(x => x.sluitpotBlock!.id).length;
        }).length;

        const beglazingsprofielen = sill.compartments.filter(c => DestructCompartment(c).glazingProfile).length;
        const aanslagprofielen = sill.compartments.filter(c => DestructCompartment(c).stopProfile).length;
        const lprofile = sill.compartments.filter(c => DestructCompartment(c).lProfile).length;
        const protectors = sill.compartments.filter(c => c.protector).length;

        return (
            <>

                <div className={'row mb-4'}>
                    {sluitpotten > 0 &&
                        <div className={'col-3 mb-5'}>
                            <div className={'card h-100'}
                                 style={{backgroundColor: stationLookup.get(ProductionItemOperations.SluitpottenGemonteerd)?.color}}>
                                <div className={'card-body'}>
                                    <h1 className={'card-title'}>SLUITPOTTEN</h1>
                                    {sill.compartments.map((compartment, i) => {
                                        const sluitpotten = (compartment as {
                                            sluitpotten: ConfiguredSillSluitpot[]
                                        }).sluitpotten;

                                        return (
                                            <div key={i}>
                                                {sluitpotten?.map((sluitpot, i) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <span>{sluitpot.sluitpotHardware?.code}</span>
                                                            <div
                                                                className={'fw-bold'}>{sluitpot.includeHardware ? ' BESLAG MONTEREN!' : ''}</div>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                    {props.bakNumber && props.bakNumber != '-' &&
                        <div className={'col-3 mb-5'}>
                            <div className={'card h-100'}
                                 style={{backgroundColor: stationLookup.get(ProductionItemOperations.ManchettesGemonteerd)?.color}}>
                                <div className={'card-body'}>
                                    <h1 className={'card-title fs-2'}>Manchetten zak</h1>
                                    <h1 className={''}>{props.bakNumber}</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {(beglazingsprofielen > 0 || aanslagprofielen > 0 || lprofile > 0) &&
                        <div className={'col-3 mb-5'}>
                            <div className={'card h-100'}
                                 style={{backgroundColor: stationLookup.get(ProductionItemOperations.LattenGemonteerd)?.color}}>
                                <div className={'card-body'}>

                                    <h1 className={'card-title'}>Latten plaatsen</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {glaslatten > 0 &&
                        <div className={'col-3 mb-5'}>
                            <div className={'card h-100'}
                                 style={{backgroundColor: stationLookup.get(ProductionItemOperations.LattenGemonteerd)?.color}}>
                                <div className={'card-body'}>

                                    <h1 className={'card-title'}>Glaslatten</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {protectors > 0 &&
                        <div className={'col-3 mb-5'}>
                            <div className={'card h-100'}
                                 style={{backgroundColor: stationLookup.get(ProductionItemOperations.LattenGemonteerd)?.color}}>
                                <div className={'card-body'}>

                                    <h1 className={'card-title'}>Beschermkappen</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {sill.wrap &&
                        <div className={'col-3 mb-5'}>
                            <div className={'card h-100'}
                                 style={{backgroundColor: stationLookup.get(ProductionItemOperations.Ingepakt)?.color}}>
                                <div className={'card-body'}>

                                    <h1 className={'card-title'}>Inpakken</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {props.remark &&
                        <div className={'col-6 mb-5'}>
                            <div className={'card h-100'}
                                 style={{backgroundColor: stationLookup.get('afmelden')?.color}}>
                                <div className={'card-body'}>

                                    <h1 className={'card-title'}>{props.remark}</h1>
                                </div>
                            </div>
                        </div>
                    }
                    {props.instruction &&
                        <div className={'col-6 mb-5'}>
                            <div className={'card h-100'}
                                 style={{
                                     whiteSpace: 'pre',
                                     backgroundColor: stationLookup.get(ProductionItemOperations.HefschuifVouwwand)?.color
                                 }}>
                                <div className={'card-body'}>

                                    <h2 className={'card-title'}
                                        dangerouslySetInnerHTML={{__html: props.instruction!}}></h2>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        );

    }
;

export default AfmontageSpecification;
