import React, {CSSProperties, useEffect, useState} from 'react';
import {Accept, useDropzone} from 'react-dropzone';
import {useDragging} from '../../hooks/useDragging';
import toast from 'react-hot-toast';
import {FileCategory, FileRestriction} from '../../../modules/api-client/generated';
import ApiClient from '../../../modules/api-client/ApiClient';
import {useQuery} from '@tanstack/react-query';
import {FileModal} from './FileModal';

export interface FileUploadProps {
	onFileUpload: (fileId: string) => void;
	show: 'drag' | 'always';
	fileCategory: FileCategory;
	sasUri?: string;
	minHeight?: string;
	uploadType: 'image' | 'other';
	editImageLabel?: string;
}

export const FileUpload: React.FC<FileUploadProps> = (props) => {
	const [base64, setBase64] = useState<string | null>(null);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [file, setFile] = useState<File | null>(null);

	const {data: restrictions} = useQuery<FileRestriction>(['fileStorageGetFileRestrictions'], () => ApiClient.FileStorage.getFileRestrictions(props.fileCategory).then((res) => res.data));

	const allowedExtensions =
		restrictions != null
			? Object.values(restrictions?.allowedExtensions as Accept)
					.flat()
					.join(', ')
			: '';

	const {getRootProps, getInputProps, open} = useDropzone({
		multiple: false,
		accept: restrictions?.allowedExtensions as Accept,
		maxSize: restrictions?.maxSize!,
		onDropAccepted: (acceptedFiles) => {
			if (acceptedFiles.length > 0) {
				handleDrop(acceptedFiles[0]);
			}
		},
		onDropRejected: (fileRejections) => {
			const rejection = fileRejections[0];
			const message = rejection.errors[0].code === 'file-invalid-type' ? `Alleen '${allowedExtensions}' bestanden zijn toegestaan.` : rejection.errors[0].message;

			toast.error(message);
		},
	});

	const toBase64 = (file: Blob): Promise<string> =>
		new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
			reader.onerror = (error) => reject(error);
		}).then((res) => {
			setBase64(res);
			return res;
		});

	const handleDrop = (fileDropped: File) => {
		toBase64(fileDropped);
		setFile(fileDropped);
	};

	useEffect(() => {
		if (file) setShowModal(true);
	}, [file]);

	const handleModalSave = (fileId: string) => {
		setShowModal(false);
		props.onFileUpload(fileId);
	};

	const handleModalDismiss = () => {
		setFile(null);
		setBase64(null);
		setShowModal(false);
	};

	const dragging = useDragging();
	const visible = props.show === 'always' || (props.show === 'drag' && dragging);

	const style: CSSProperties = {
		position: 'absolute',
		inset: visible ? '0' : '-10000px -10000px 20000px 20000px',
		visibility: visible ? 'visible' : 'collapse',
		display: visible ? 'flex' : 'none',
		opacity: visible ? '.8' : '0',
		minHeight: props.minHeight !== undefined ? props.minHeight : '200px',
	};

	const editImageLabel = props.editImageLabel ?? 'Wijzig afbeelding';

	return (
		<>
			<div
				{...getRootProps({
					className: 'dropzone flex-column justify-content-center',
					style: style,
				})}
			>
				<div className="m-2">
					<input {...getInputProps()} />
					<div className="h3">{`Sleep hier een '${allowedExtensions}' bestand.`}</div>
				</div>
			</div>
			{props.uploadType === 'image' && (!!base64 || !!props.sasUri) ? (
				<div className="h-100 d-flex flex-column">
					<div className="form-control flex-fill overflow-hidden">
						<img src={base64 !== null ? base64 : props.sasUri} alt="" style={{height: '100%', width: '100%', objectFit: 'contain', maxHeight: '100%'}} />
					</div>
					<div className="py-3 d-flex justify-content-start">
						<button type="button" className="btn btn-light-primary me-3 btn-sm" onClick={open}>
							{editImageLabel}
						</button>
					</div>
				</div>
			) : (
				<div
					className={`form-control d-flex justify-content-center align-items-center ${base64 ? '' : 'cursor-pointer'}`}
					onClick={() => {
						if (!base64) open();
					}}
					onDoubleClick={open}
					style={{height: '200px'}}
				>
					<div className="d-flex flex-column justify-content-center align-items-center">
						<button type="button" className="btn btn-light-primary me-3" onClick={open}>
							<i className="fa-regular fa-folder-open"></i> Blader
						</button>
						<div className="mt-2">{`of sleep hier een '${allowedExtensions}' bestand`}</div>
					</div>
				</div>
			)}
			{file && showModal && (
				<FileModal
					base64={base64 ?? ''}
					handleSave={handleModalSave}
					handleDismiss={handleModalDismiss}
					file={file}
					show={showModal}
					fileCategory={props.fileCategory}
					uploadType={props.uploadType}
				/>
			)}
		</>
	);
};
