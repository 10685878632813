import React, {useState} from 'react';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../../shared/components/card';
import {useParams} from 'react-router-dom';
import {EkoTable, TableHeader} from '../../../../../shared/components/table';
import {DateTime,  Duration} from "luxon";
import {PeriodInput} from "../../../../../shared/components/date/PeriodInput";
import {useQuery} from "@tanstack/react-query";
import {
	EmployeeProductionLogRow,
	EmployeeProductionStatRow, ProductionOperationRepresentation
} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import {dateToString, weekNumber} from "../../../../../shared/components/date";
import {DailyOperationBarChart} from "../../../pm/components/DailyOperationBarChart";
import {DailyOperationDoughnut} from "../../../pm/components/DailyOperationDoughnut";
import {useProductionOperations} from "../../../pm/hooks/use-list-production-operations";
import {TotalsHeader} from "../../../../../shared/components/TotalsHeader";

export function EmployeeStatistics() {
	const { employeeId } = useParams<{ employeeId: string }>();
	const defaultFrom = DateTime.now().startOf('week').minus({ day: DateTime.now().weekday > 3 ? 3 : 0 }).toFormat("yyyy-MM-dd");
	const defaultTo = DateTime.now().endOf('week').plus({ day: DateTime.now().weekday > 3 ? 3 : 0 }).toFormat("yyyy-MM-dd");
	const [fromDate, setFromDate] = useState<string>(defaultFrom);
	const [toDate, setToDate] = useState<string>(defaultTo);
	const [showCount, setShowCount] = useState<boolean>(false);

	const {
		isInitialLoading,
		data: list,
		isError
	} = useQuery<EmployeeProductionStatRow[]>(
		['employeeProductionStatRow', fromDate, toDate, employeeId],
		() => ApiClient.Pm.Reporting.employeeProductionStats(employeeId!, fromDate, toDate).then((res) => res.data)
	);


	const startDateTimeString = DateTime.fromFormat(fromDate, "yyyy-MM-dd").toISO()?? '';
	const endDateTimeString = DateTime.fromFormat(toDate, "yyyy-MM-dd").plus({days:1}).toISO() ?? '';
	const {
		isInitialLoading: isInitalLoadingEmployeeLog,
		data: employeeLog,
		isError: isErrorEmployeeLog
	} = useQuery<EmployeeProductionLogRow[]>(
		['employeeLog', fromDate, toDate, employeeId],
		() => ApiClient.Pm.Reporting.employeeProductionLog(startDateTimeString, endDateTimeString, employeeId!, undefined).then((res) => res.data)
	);

	const getGroupedLogs = (data: EmployeeProductionLogRow[], maxDelayInSeconds: number) => {
		if(data?.length === 0) {
			return [];
		}
		const groupedData: EmployeeProductionLogRow[] = [];
		let lastLog: EmployeeProductionLogRow = data[0];

		for (let i = 1; i < data.length; i++) {
			const currentLog = data[i];
			const timeDifference = DateTime.fromISO(currentLog.dateTime).diff(DateTime.fromISO(lastLog.dateTime), 'seconds').seconds;

			if (timeDifference <= maxDelayInSeconds && lastLog.operation === currentLog.operation) {
				lastLog.count += currentLog.count;
				lastLog.dateTime = currentLog.dateTime;
			} else {
				groupedData.push(lastLog);
				lastLog = currentLog;
			}
		}
		groupedData.push(lastLog);
		return groupedData;
	};

	const getGroupedLogsOld = (data: EmployeeProductionLogRow[], maxDelayInSeconds: number) => {
		const groupedData: EmployeeProductionLogRow[][] = [];
		let currentGroup: EmployeeProductionLogRow[] = [];

		for (let i = 0; i < data.length; i++) {
			if (currentGroup.length === 0) {
				currentGroup.push(data[i]);
			} else {
				const lastLog = currentGroup[currentGroup.length - 1];
				const currentLog = data[i];
				const timeDifference = DateTime.fromISO(currentLog.dateTime).diff(DateTime.fromISO(lastLog.dateTime), 'seconds').seconds;

				if (timeDifference <= maxDelayInSeconds && lastLog.operation === currentLog.operation) {
					currentGroup.push(currentLog);
				} else {
					groupedData.push(currentGroup);
					currentGroup = [currentLog];
				}
			}
		}

		if (currentGroup.length > 0) {
			groupedData.push(currentGroup);
		}

		return groupedData;
	};


	const {
		isInitialLoading: isInitialLoadingProductionOperations,
		data: productionOperations,
		isError: isErrorProductionOperations
	} = useProductionOperations();

	const setDateRange = (currentWeek: number, year: number = DateTime.now().year) => {
		const dt = DateTime.fromObject({
			weekYear: year,
			weekNumber: currentWeek
		});
		setFromDate(dt.startOf('week').toFormat("yyyy-MM-dd"));
		setToDate(dt.endOf('week').toFormat("yyyy-MM-dd"));
	}

	const setSingleDateAsRange = (date: string) => {
		setFromDate(date);
		setToDate(date);
	}

	const getTimeDisplayValue = (isoDateTime : string) => {
		return DateTime.fromISO(isoDateTime).toFormat('HH:mm:ss');
	}

	const resetRange = () => {
		setFromDate(defaultFrom);
		setToDate(defaultTo);
	};

	const startWithLowerCase = (str : string) => {
		return str.charAt(0).toLowerCase() + str.slice(1);
	}

	const calculateRowTotalCount = (stat: EmployeeProductionStatRow, productionOperations: ProductionOperationRepresentation[]) => {
		return productionOperations.reduce((sum, operation) => {
			const count = stat.productionByOperation[startWithLowerCase(operation.code)] || 0;
			return sum + count;
		}, 0);
	};

	const calculateRowTotalDurationInSeconds = (stat: EmployeeProductionStatRow, productionOperations: ProductionOperationRepresentation[]) => {
		return productionOperations.reduce((sum, operation) => {
			const count = stat.productionByOperation[startWithLowerCase(operation.code)] || 0;
			const durationInSeconds = getDurationInSeconds(operation.averageDuration);
			return sum + (durationInSeconds * count);
		}, 0);
	};

	const getOperationDuration = (operactionCode : string) => {
		const isoDurationString = productionOperations!.find(po => po.code === operactionCode)!.averageDuration;
		return Duration.fromISOTime(isoDurationString);
	}

	const getTotalOperationDuration = (operationCode : string) => {
		const opDuration = getOperationDuration(operationCode);
		const count = getOperationTotalCount(operationCode);
		const totalDurationInSeconds = opDuration.as('seconds') * count;
		return Duration.fromMillis(totalDurationInSeconds * 1000);
	}

	const getDurationInSeconds = (isoDurationString : string) => {
		return Duration.fromISOTime(isoDurationString).as('seconds');
	}

	const getDisplayValue = (stat : EmployeeProductionStatRow, operation : ProductionOperationRepresentation) => {
		const count = stat.productionByOperation[startWithLowerCase(operation.code)];
		if(!count) {
			return '-';
		}
		if(showCount) {
			return count;
		}
		return Duration.fromMillis(Duration.fromISOTime(operation.averageDuration).as('seconds') * count * 1000).toFormat('hh:mm');
	}

	const getDisplayRowTotalValue = (stat : EmployeeProductionStatRow, productionOperations: ProductionOperationRepresentation[]) => {
		if(showCount) {
			return calculateRowTotalCount(stat, productionOperations);
		}
		return toShortFormattedDuration(Duration.fromMillis(calculateRowTotalDurationInSeconds(stat, productionOperations) * 1000));
	}

	const getGrandTotalCount = () => {
		if(!list || !productionOperations) {
			return 0;
		}
		return list.reduce((sum, stat) => {
			return sum + calculateRowTotalCount(stat, productionOperations);
		}, 0);
	}
	const getGrandDuration = () => {
		if(!list || !productionOperations) {
			return '-';
		}
		const totalDurationInSeconds = list.reduce((sum, stat) => {
			return sum + calculateRowTotalDurationInSeconds(stat, productionOperations);
		}, 0);
		return toShortFormattedDuration(Duration.fromMillis(totalDurationInSeconds * 1000));
	}

	const getOperationTotalCount = (operationCode: string) => {
		return list!.reduce((sum, stat) => {
				return sum + (stat.productionByOperation[startWithLowerCase(operationCode)] || 0);
			}, 0);
	}

	const toShortFormattedDuration = (duration : Duration) => {
		if(!duration) {
			return '-';
		}
		return duration.toFormat('hh:mm');
	}

	if (isInitialLoading || isInitialLoadingProductionOperations || isInitalLoadingEmployeeLog) {
		return <Loading/>;
	}

	if (isError || isErrorProductionOperations || isErrorEmployeeLog) {
		return <>ERROR!</>;
	}

	if (!list || !productionOperations || !employeeLog) {
		return <>Geen data gevonden!</>;
	}

	let currentWeek = 0;
	let currentDate = '';
	return (
		<>
			<EkoCard>
				<EkoCardHeader title={employeeId}>
					<EkoCardToolbar>
						<div className='form-check form-switch'>
							<input className='form-check-input' type='checkbox' role='switch'
								   onChange={() => setShowCount(!showCount)}
								   checked={showCount}
								   id='displayCount'/>
							<label className='form-check-label me-2' htmlFor='displayCount'>Toon aantallen</label>
						</div>
						<PeriodInput
							startDate={fromDate}
							endDate={toDate}
							onStartDateChange={setFromDate}
							onEndDateChange={setToDate}
							resetRange={resetRange}
						/>
					</EkoCardToolbar>
				</EkoCardHeader>
			<EkoCardBody>
				<div className="container">
					<div className="row">
						<div className='col-12 col-lg-6'>
							<DailyOperationBarChart fromDate={fromDate} toDate={toDate} showCount={showCount} />
						</div>
						<div className='col-12 col-lg-6'>
							<DailyOperationDoughnut fromDate={fromDate} toDate={toDate} showCount={showCount} />
						</div>
					</div>
				</div>
				<EkoCardHeader title={'Dagtotalen voor ' + employeeId}/>
				<EkoTable classNameDivWrapper={'tableFixHead tableFixFirstColumn'}>
					<TableHeader className={'align-text-top'}>
						<th></th>
						<th>
							<TotalsHeader totalCount={getGrandTotalCount()}
										  formattedTotalDuration={getGrandDuration()}
										  showCount={showCount}
										  reference={'Totaal'}/>
						</th>
						{productionOperations.map(op => (
							<th>
								<TotalsHeader totalCount={getOperationTotalCount(op.code)}
											  formattedTotalDuration={toShortFormattedDuration(getTotalOperationDuration(op.code))}
											  showCount={showCount}
											  reference={op.title}/>
							</th>
						))}
				</TableHeader>
						<tbody>
						{list.map((entry, index) => {
							const newWeek = weekNumber(entry.date) !== currentWeek;
							currentWeek = weekNumber(entry.date);

							return (
								<>
									{newWeek &&
										<tr className={`fw-bold fs-6}`}>
											<th colSpan={13}><span style={{cursor: 'pointer'}}
																   onClick={() => setDateRange(DateTime.fromFormat(entry.date, "yyyy-MM-dd").weekNumber,
																   								DateTime.fromFormat(entry.date, "yyyy-MM-dd").year)}>
												Week {currentWeek}
                                    </span></th>
										</tr>
									}
									<tr key={index}>
										<td className={'text-start text-nowrap'}>
											{dateToString(entry.date, {
												weekday: 'short',
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
												timeZone: 'UTC'
											})}
										</td>
										<td>{getDisplayRowTotalValue(entry, productionOperations)}</td>
										{productionOperations.map(operation => (
											<td  className={'text-center'} key={operation.code}>
												{getDisplayValue(entry, operation)}
											</td>
										))}
									</tr>
								</>
							);
						})}
						</tbody>
				</EkoTable>
				<EkoCardHeader title={'Productielog ' + employeeId}>
				</EkoCardHeader>
				<EkoTable classNameDivWrapper={'tableFixHead tableFixFirstColumn'}>
					<TableHeader className={'align-text-top'}>
						<th>Datum/Tijd</th>
						<th>Handeling</th>
						<th>Aantal</th>
					</TableHeader>
					<tbody>
					{getGroupedLogs(employeeLog, 10).map((logRow, index) => {
						const newDay = DateTime.fromISO(logRow.dateTime).toISODate() !== currentDate;
						currentDate = DateTime.fromISO(logRow.dateTime).toISODate() as string;
						return <>
							{newDay &&
								<tr className={`fw-bold fs-6}`}>
									<th colSpan={13}>
										<span style={{cursor: 'pointer'}}
														   onClick={() => setSingleDateAsRange(DateTime.fromISO(logRow.dateTime).toISODate() as string)}>
											{dateToString(currentDate, {
												weekday: 'short',
												year: 'numeric',
												month: 'numeric',
												day: 'numeric',
												timeZone: 'UTC'
											})}
										</span>
									</th>
								</tr>
							}
							<tr key={index}>
								<td className={'text-start text-nowrap'}>
									{getTimeDisplayValue(logRow.dateTime)}
								</td>
								<td>{logRow.operation}</td>
								<td>{logRow.count}</td>
							</tr>
						</>;
					})}
					</tbody>
				</EkoTable>
			</EkoCardBody>
		</EkoCard>
		</>
	);
}

export default EmployeeStatistics;