import React from "react";
import {PageTitle} from "../../../../../_metronic/layout/core";
import {ProductionItemOperations} from "../../../../modules/api-client/generated";
import {ProductionOperation} from "../components/ProductionOperation";
import {stationLookup} from "../models/stations";
import {Outlet, Route, Routes, useNavigate, useParams} from "react-router-dom";
import {ProductionNeutSpecification} from "../../../../shared/components/ProductionNeutSpecification";
import {Modal} from "react-bootstrap";
import {modalsRoot} from "../../../../global-helpers";
import {CustomerAddressesModalBody} from "../../crm/components/CustomerAddressesModalBody";
import {tr} from "date-fns/locale";

export const ProductionNeut: React.FC<{fullscreen?:string | true | undefined}> = ({fullscreen = undefined}) => {
    const {code} = useParams<{ code: string }>();
    const navigate = useNavigate();
    const cancel = () => navigate(-1);

    return (
        <>
            <Modal
                container={modalsRoot}
                tabIndex={-1}
                aria-hidden='true'
                autoFocus={true}
                dialogClassName={'modal-dialog'}
                scrollable={true}
                show={true}
                fullscreen={fullscreen}
                size='xl'
                onHide={cancel}
                animation={false}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Neut {code}</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <ProductionNeutSpecification neutCode={code}/>

                </Modal.Body>
            </Modal>ProductionNeut.tsx
        </>
    )
}

