import React, {MouseEvent, useCallback} from 'react';

export interface SpinnerButtonV2Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	dotdotdot?: boolean;
}

const SpinnerButtonV2: React.FC<SpinnerButtonV2Props> = (props) => {
	const [spinning, setSpinning] = React.useState(false);

	const onClickWithSpin = useCallback(
		async (event: MouseEvent<HTMLButtonElement>) => {
			if (props.onClick) {
				setSpinning(true);

				try {
					if (typeof props.onClick === 'function') {
						const result: any = props.onClick(event);

						if (typeof result === 'object' && 'then' in result) {
							await result;
						}
					}
				} finally {
					setSpinning(false);
				}
			}
		},
		[props.onClick]
	);

	const btnProps = {
		...props,
		...{disabled: props.disabled ?? false ? true : spinning},
		onClick: onClickWithSpin,
	};

	delete btnProps.dotdotdot;

	return (
		<button {...btnProps}>
			{(spinning ?? false) && (
				<div className="spinner-border spinner-border-sm me-3" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			)}
			{props.children}
			{spinning && (props.dotdotdot ?? true) && <span>...</span>}
		</button>
	);
};

export default SpinnerButtonV2;
