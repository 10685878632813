/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { KTSVG } from '../../../../../../_metronic/helpers';
import { OrderStatus } from '../edit-order/components/OrderStatus';
import { EkoTable,TableHeader } from '../../../../../shared/components/table';
import { SmRoutePath } from '../../SmRoutes';
import { formatDate } from '../../../../../shared/components/date';
import {build, useEkosietFilter} from '../../../../../shared/context/FilterContext';
import { useListOrdersOdata } from '../hooks/use-list-orders-odata';
import { OrderListView } from '../../../../../modules/api-client/generated';
import { Pagination } from 'react-bootstrap';
import UrgentBadge from "../../../../../shared/components/UrgentBadge";
import Euro from "../../../../../shared/components/Euro";
import {PutPlannedExpeditionDateForm} from "../edit-order/components/forms/PutPlannedExpeditionDateForm";
import Loading from "../../../../../shared/components/Loading";
import {EditOrderModal} from "../edit-order/components/EditOrderModal";
import {PutProductionDateForm} from "../edit-order/components/forms/PutProductionDateForm";

interface OrderTableProps {
	selection?: Map<string, OrderListView>;
	setSelection?: Dispatch<SetStateAction<Map<string, OrderListView>>>;
	customerId?: string;
}

const OrderTable: React.FC<OrderTableProps> = ({ selection, setSelection, customerId }) => {
	const [searchParams, setSearchParams] = useSearchParams()	
	const [pageSize] = useState(150);
	const [currentPage, setPage] = useState(1);
	const [orderCount, setOrdersCount] = useState(0);
	const [pageCount, setPageCount] = useState(1);
	const skip = (currentPage - 1) * pageSize;
	const { filterQuery, filters,containsFilter,setFilters } = useEkosietFilter();

	useEffect(() => {
		let updatedFilters = [...filters]; // Clone the current filters
		if (customerId && !containsFilter('CustomerId')) {
			// Define the new customer ID filter expression
			const customerFilterExpression = { CustomerId: { eq: { type: 'guid', value: customerId } } };
			// Remove any existing customer ID filter
			updatedFilters = updatedFilters.filter(filter => filter.filterName !== 'CustomerId');
			// Add the new customer ID filter
			updatedFilters.push({
				filterName: 'CustomerId',
				filterExpression: customerFilterExpression,
				values: [customerId]
			});
			setFilters('CustomerId', updatedFilters);
		}
		// Build the filter query with the updated filters
	}, [customerId, filters, build]);


	const selectAllRef = useRef<HTMLInputElement | null>(null);
	
	const {
		isInitialLoading,
		data: orderlist,
		isError,
	} = useListOrdersOdata(pageSize, skip, "OrderDrafted desc", filterQuery, true);

	useEffect(() => {
		const page = searchParams.get('page');
		const pageParamNumber = parseInt(page || '1', 10); // comes in as a string, convert to int

		if (pageParamNumber > 0 && pageParamNumber <= pageCount) {
			setPage(pageParamNumber)
		}
	}, [pageCount, searchParams]);

	useEffect(() => {
		if (orderlist && orderlist['@odata.count']) {
			setOrdersCount(orderlist['@odata.count'] as number)
		}
	}, [orderlist]);

	useEffect(() => {
		if (orderCount > 0) {
			setPageCount(Math.ceil(orderCount / pageSize))
		}
	}, [orderCount, pageSize]);

	const selectionEnabled = typeof selection !== 'undefined';

	const allSelected = useMemo(() => orderlist && orderlist.value.filter((x: OrderListView) => !selection?.has(x.id)).length === 0, [selection, orderlist]);

	const selectAll = useCallback(() => {
		setSelection?.call(this, () => {
			let newSelection = new Map();
			orderlist?.value.forEach((profile: OrderListView) => {
				if (!newSelection.has(profile.id)) {
					newSelection.set(profile.id, profile);
				}
			});
			return newSelection;
		});
	}, [orderlist, setSelection]);

	const selectNone = useCallback(() => {

		setSelection?.call(this, (selection) => {
			if (selection.size > 0) {
				return new Map<string, OrderListView>();
			}
			return selection;
		});
	}, [setSelection]);

	const handleSelection = (selected: boolean, profile: OrderListView) => {

		setSelection?.call(this, (selection) => {
			let newSelection = new Map<string, OrderListView>(selection);
			if (selected) {
				newSelection.set(profile.id, profile);
			} else {
				newSelection.delete(profile.id);
			}
			return newSelection;
		});

	};

	useEffect(() => {
		if (!selectAllRef?.current || typeof selection === 'undefined') return;

		selectAllRef.current!.indeterminate = !allSelected && selection.size > 0;
	}, [selection, allSelected, selectAllRef]);

	const paginationItems = useMemo(() => {
		let items = []
		for (let number = 1; number <= pageCount; number++) {
			items.push(
				<Pagination.Item key={number} active={number === currentPage} onClick={() => { setSearchParams(`?${new URLSearchParams({ page: number.toString() })}`) }} >
					{number}
				</Pagination.Item>,
			);
		}
		return items
	}, [pageCount, currentPage, setSearchParams]);

	const [showEditModal, setShowEditModal] = useState<boolean>(false)
	const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>('xxl-down')
	const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
	const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);
	var editPlannedExpeditionDate = (orderId:string,plannedExpeditionDate?:Date|null) => {
		setModalComponent(() => <PutPlannedExpeditionDateForm
			handleClose={() => setShowEditModal(false)}
			plannedExpeditionDate={plannedExpeditionDate}
			updateStateToPlanned={true}
			id={orderId}/>)
		setModalTitle('Wijzig geplande leveringsdatum')
		setShowEditModal(true)
	}
	var editPlannedProductionDate = (orderId:string,plannedProductionDate?:Date|null) => {
		setModalComponent(() => <PutProductionDateForm
			handleClose={() => setShowEditModal(false)}
			productionDate={plannedProductionDate}
			id={orderId}/>)
		setModalTitle('Wijzig geplande productiedatum')
		setShowEditModal(true)
	}

	if (isInitialLoading) {
		return <Loading/>;
	}

	if (isError) {
		return <>Fout bij het ophalen van de orders!</>;
	}

	if (!orderlist || !orderlist.value) return <></>;

	return (
		<>
			<EditOrderModal show={showEditModal} handleClose={() => setShowEditModal(false)}
							title={modalTitle}
							size={'xl'}
							fullscreen={modalFullscreen}
							component={modalComponent}
			/>
			<EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
				<TableHeader>
					{selectionEnabled &&
						<th className='p-0 fit-content' style={{ height: '1px' }}>
							<label className='w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer'>
								<input
									ref={selectAllRef}
									className='form-check-input m-0'
									type='checkbox'
									id={`profile-select-all`}
									checked={allSelected}
									onChange={(e) => (e.target.checked ? selectAll() : selectNone())}
								/>
							</label>
						</th>
					}
					<th className='min-w-40px'>Order Code</th>
					<th className='min-w-140px'>Klant</th>
					<th className='min-w-50px text-center'>Productiedatum</th>
					<th className='min-w-50px text-center'>Geplande leverdatum</th>
					<th className='text-center'>Spoed?</th>
					<th className='min-w-50px text-end'>Totaal</th>
					<th className='min-w-50px text-center'>Status</th>
					<th className='min-w-50px text-end'>Acties</th>
				</TableHeader>
				<tbody>
					{orderlist.value && orderlist.value.map((order: OrderListView) =>
						<tr key={order.id}>
							{selectionEnabled &&
								<td className='p-0 fit-content' style={{ height: '1px' }}>
									<label
										className='w-100 h-100 d-flex justify-content-center align-items-center cursor-pointer'>
										<input
											className='form-check-input m-0'
											type='checkbox'
											id={`profile-${order.id}`}
											value={order.id}
											checked={selection?.has(order.id)}
											onChange={(e) => handleSelection(e.target.checked, order)}
										/>
									</label>
								</td>
							}
							<td>
								<Link to={SmRoutePath.link(SmRoutePath.orderEditLink(order.id!))}
									  className='text-hover-primary'>
									{order.code}
								</Link>
								<div className={'text-muted text-nowrap'}>
									{formatDate(order.orderDrafted, "dd-MM-yy HH:mm")}
								</div>
								<div className={'text-muted'}>{order.userName}</div>

							</td>
							<td>
								<div
									className='text-dark d-block fs-6'>
								{order.customerName}
								</div>
								<span className={'text-muted'}>{order.reference}</span>

							</td>
							<td className='text-center'>
								<div className={'text-nowrap cursor-pointer'} onClick={()=>editPlannedProductionDate(order.id,order.plannedProductionDate ? new Date(order.plannedProductionDate):null)}>
									{
										order.plannedProductionDate
											? formatDate(order.plannedProductionDate)
											: '-'
									}
								</div>
							</td>
							<td className='text-center'>
								<div className={'text-nowrap cursor-pointer'} onClick={()=>editPlannedExpeditionDate(order.id,order.plannedExpeditionDate ? new Date(order.plannedExpeditionDate):null)}>
									{
										order.plannedExpeditionDate
											? formatDate(order.plannedExpeditionDate)
											: '-'
									}
								</div>
							</td>
							<td className='text-center'>
								<UrgentBadge value={order.urgent}/>
							</td>
							<td className={'text-end'}>
								<div className='text-dark fw-bold fs-6 '>
									<Euro value={order.totalPrice} />
								</div>
								<span className='text-muted'>
									{order.totalProducts} item(s)
								</span>
							</td>
							<td className='text-center'>
								<OrderStatus status={order.status} />
							</td>
							<td className='text-end'>
								<Link to={SmRoutePath.link(SmRoutePath.orderEditLink(order.id!))}
									className={'btn btn-icon btn-sm btn-light me-2'}>
									<KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
								</Link>
							</td>
						</tr>,
					)}
				</tbody>
			</EkoTable>

			<div>
				<span className={`text-muted`}>{orderlist.value.length}/{orderlist["@odata.count"]} orders</span>
				<Pagination className='justify-content-start mt-3'>{paginationItems}</Pagination>
			</div>
		</>
	);
};

export { OrderTable };