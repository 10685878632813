import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {
    ExpeditionForProductionItemRepresentation,
    ExpeditionsForProductionItemRow
} from "../../../../../modules/api-client/generated";
import ApiClient from "../../../../../modules/api-client/ApiClient";
import Loading from "../../../../../shared/components/Loading";
import {
    usePutExpeditionListDelivered
} from "../../../sm/orders/hooks/use-put-expedition-status";
import classNames from "classnames";
import {NumericFormat} from "react-number-format";
import {EkoTable} from "../../../../../shared/components/table";
import {DateTime} from "luxon";

interface DeliverOrdersProps {
    orderId: string,
    handleClose: () => void
}
const DeliverOrders: React.FC<DeliverOrdersProps> = ({ orderId, handleClose}) => {
    const [checkedOrders, setCheckedOrders] = useState<{ [key: string]: boolean }>({});
    const [checkedOrderCount, setCheckedOrderCount] = useState<number>(0);
    const [inTransitCount, setInTransitCount] = useState<number>(0);
    const [btnClicked, setBtnClicked] = useState<boolean>(false);
    const {
        isInitialLoading,
        data: expeditionData,
        isError
    } = useQuery<ExpeditionForProductionItemRepresentation>(['emExpeditionOrderIdGet', orderId], () => ApiClient.Em.Expedition.emExpeditionOrderIdGet(orderId!).then((res) => res.data));


    useEffect(() => {
        if (expeditionData && expeditionData.relatedExpeditions) {
            setCheckedOrders(initializeCheckedOrders(expeditionData.relatedExpeditions));
        }
    }, [expeditionData]);

    const btnText = checkedOrderCount === 1 ? '1 Order Afleveren' : `${checkedOrderCount} Orders Afleveren`;

    useEffect(() => {
        if (inTransitCount === 1 && !btnClicked) {
            deliverOrders().then();
        }
    }, [inTransitCount]);

    const deliveredListMutation = usePutExpeditionListDelivered();

    const initializeCheckedOrders = (ordersByExpeditionDate: any) => {
        const initialCheckedOrders: { [key: string]: boolean } = {};
        let count = 0;
        Object.values(ordersByExpeditionDate).forEach((datum: any) => {
            datum.expeditions.forEach((order: ExpeditionsForProductionItemRow) => {
                const isInTransit = order.expeditionStatus === "inTransit";
                initialCheckedOrders[order.orderId!] = isInTransit;
                if (isInTransit) {
                    count++;
                }
            });
        });
        setCheckedOrderCount(count);
        setInTransitCount(count);
        return initialCheckedOrders;
    };


    const onDeliverBtnClick = async () => {
        setBtnClicked(true);
        await deliverOrders();
    }

    const deliverOrders = async () => {
        deliveredListMutation.mutate(getSelectedOrders(), {
            onSuccess: () => handleClose(),
            onError: () => {
                // Handle error if needed
            }
        });
    }

    const getSelectedOrders = () => {
        return Object.keys(checkedOrders).filter((key) => checkedOrders[key]);
    }

    if (isInitialLoading) {
        return <Loading />;
    }

    if (isError) {
        return <>Fout bij het ophalen van de gegevens!</>;
    }

    if (!expeditionData || !expeditionData.relatedExpeditions) return <></>;

    var ordersByExpeditionDate = expeditionData.relatedExpeditions;

    const hasExpeditionsToShow= (expeditions: ExpeditionsForProductionItemRow[]) => {
        return expeditions.filter(expedition => displayExpedition(expedition)).length > 0;
    }

    const displayExpedition = (expedition: ExpeditionsForProductionItemRow) =>
        expedition.expeditionStatus !== 'delivered' &&
        (expedition.expeditionStatus === "inTransit" || DateTime.fromISO(expedition.plannedExpeditionDate) <= DateTime.now());

    const customer = expeditionData.expedition.customer.name;

    function getSillCountOfSelectedOrders() {
        const selectedOrders = Object.keys(checkedOrders).filter(key => checkedOrders[key]);
        return Object.values(expeditionData?.relatedExpeditions || {}).reduce((count, { expeditions }) =>
            count + expeditions.filter(order => selectedOrders.includes(order.orderId!)).reduce((sum, order) => sum + order.itemCount, 0), 0);
    }
    function getLongestSillLengthOfSelectedOrders() {
        const selectedOrders = Object.keys(checkedOrders).filter(key => checkedOrders[key]);
        return Object.values(expeditionData?.relatedExpeditions || {}).reduce((longest, { expeditions }) =>
            Math.max(longest, ...expeditions.filter(order => selectedOrders.includes(order.orderId!)).map(order => order.maxSillLengthOrder)), 0);
    }

    const handleCheckboxChange = (orderId: string) => {
        setCheckedOrderCount(prevCount => prevCount + (checkedOrders[orderId] ? -1 : 1));
        setCheckedOrders(prevState => ({
            ...prevState,
            [orderId]: !prevState[orderId]
        }));
    };

    return (
        <>
            <EkoTable className={'card-xxl-stretch'} key="deliverHeader">
                <colgroup>
                    <col width={'5%'} />
                    <col width={'40%'} />
                    <col width={'20%'} />
                    <col width={'10%'} />
                    <col width={'20%'} />
                </colgroup>
                <thead>
                <tr>
                    <td></td>
                    <td>{customer}</td>
                    <td>
                        Langste dorpel:&nbsp;
                        <NumericFormat
                            className={'me-1 text-nowrap fs-4'} decimalScale={2}
                            value={getLongestSillLengthOfSelectedOrders() * 0.001}
                            displayType={'text'}
                            thousandSeparator={true} suffix={' m'}/>

                    </td>
                    <td>{getSillCountOfSelectedOrders()} dorpels</td>
                    <td>
                        <button className={`btn btn-success`}
                                disabled={checkedOrderCount === 0}
                                onClick={onDeliverBtnClick}>
                            {btnText}
                        </button>
                    </td>

                </tr>
                </thead>
            </EkoTable>
            {Object.keys(ordersByExpeditionDate).map((datum) => {
                var expeditions = ordersByExpeditionDate[datum].expeditions;
                if(!hasExpeditionsToShow(expeditions)) {
                    return null;
                }
                return (
                    <React.Fragment key={datum}>
                        {ordersByExpeditionDate[datum].expeditions.filter(expedition => expedition.expeditionStatus != 'delivered').map((order) => {
                            return (
                                <EkoTable className={'card-xxl-stretch'} key={order.orderId}>
                                    <colgroup>
                                        <col width={'5%'} />
                                        <col width={'15%'} />
                                        <col width={'25%'} />
                                        <col width={'20%'} />
                                        <col width={'10%'} />
                                        <col width={'20%'} />
                                    </colgroup>
                                    <tbody>
                                    <tr className={classNames(order.expeditionStatus !== 'inTransit' ? 'text-decoration-line-through text-muted' : '')
                                        }
                                    >
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={checkedOrders[order.orderId]}
                                                disabled={order.expeditionStatus !== 'inTransit'}
                                                onChange={() => handleCheckboxChange(order.orderId!)}
                                            />
                                        </td>
                                        <td>
                                            {order.orderCode}
                                        </td>
                                        <td>
                                            <span className='fs-6'>
                                                {order.plannedExpeditionDate}
                                            </span>
                                        </td>
                                        <td>
                                            Langste dorpel:&nbsp;
                                            <NumericFormat
                                                className={'me-1 text-nowrap fs-4'} decimalScale={2}
                                                value={order.maxSillLengthOrder * 0.001}
                                                displayType={'text'}
                                                thousandSeparator={true} suffix={' m'}/>
                                        </td>
                                        <td>
                                            {order.itemCount} dorpels
                                        </td>
                                        <td className={'d-flex justify-content-end'}>
                                            {order.expeditionStatus === 'planned' &&
                                                <button className={`btn btn-warning`} disabled={true}>
                                                    Order nog niet gereed
                                                </button>
                                            }
                                            {order.expeditionStatus === 'readyForShipment' &&
                                                <button className={`btn btn-info`}
                                                        disabled={true}>
                                                    Order onderweg melden
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </EkoTable>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </>
    );
}
export default DeliverOrders
