import React, {FC} from 'react';
import ApiClient from '../../../modules/api-client/ApiClient';
import EkoFormField from '../EkoFormField';
import {GlazingBarPicker} from '../../../backoffice/modules/pim/glazingbar/GlazingBarPicker';

export const CustomerPreferencesVastglasBuiten: FC = () => {
	const glazingBarQuery = () => ApiClient.Preferences.Preferences.getGlazingBars().then((res) => res.data);

	return (
		<>
			<EkoFormField
				row={true}
				label={'Standaard glaslat'}
				field={{
					name: 'glazingBarId',
					autoFocus: true,
					component: GlazingBarPicker({
						required: false,
						multi: false,
						queryFn: glazingBarQuery,
					}),
				}}
			/>
		</>
	);
};
