import {Formik, FormikHelpers, useFormikContext} from 'formik';
import React, {useEffect, useLayoutEffect} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {createPortal} from 'react-dom';
import {modalsRoot} from '../../../global-helpers';
import {FileCategory} from '../../../modules/api-client/generated';
import {ValidationErrors} from '../../../modules/api-client/Responses';
import SubmitButton from '../SubmitButton';
import TextField from '../TextField';
import {useFileUploadMutation} from './useFileUploadMutation';
import * as Yup from 'yup';

export interface FileModalProps {
	handleSave: (id: string) => void;
	handleDismiss: () => void;
	base64: string;
	file: File;
	fileCategory: FileCategory;
	show: boolean;
	uploadType: 'image' | 'other';
}

export interface FormValues {
	file: File;
	fileCategory: FileCategory;
	altText: string;
	title: string;
	description: string;
}

export const FileModal: React.FC<FileModalProps> = (props) => {
	const {mutate, serverValidationErrors, data: fileId} = useFileUploadMutation();
	const onSubmit = async (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		try {
			return await new Promise<void>((resolve, reject) => {
				mutate(values, {
					onSuccess: () => {
						resolve();
					},
					onError: () => {
						reject();
					},
				});
			});
		} finally {
			return setSubmitting(false);
		}
	};

	useEffect(() => {
		if (fileId) {
			props.handleSave(fileId);
		}
	}, [fileId]);

	const initialValues: FormValues = {
		file: props.file,
		fileCategory: props.fileCategory,
		altText: '',
		title: '',
		description: '',
	};

	const getSchema = (uploadType:string) => Yup.object().shape({
		title: Yup.string()
			.min(1, 'Titel is te kort')
			.max(100, 'Titel is te lang')
			.required('Geen titel ingevoerd'),
	
		description: Yup.string()
			.min(1, 'Omschrijving is te kort')
			.max(200, 'Omschrijving is te lang')
			.required('Geen omschrijving ingevoerd'),
	
		altText: uploadType === 'image'
			? Yup.string()
				.min(1, 'Alt-tekst is te kort')
				.max(200, 'Alt-tekst is te lang')
				.required('Geen Alt-tekst ingevoerd')
			: Yup.string(),
	});

	return (
		<>
			<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getSchema(props.uploadType)}>
				<InsertForm serverValidationErrors={serverValidationErrors} 
					base64={props.base64} 
					handleDismiss={props.handleDismiss} 
					show={props.show}
					uploadType={props.uploadType}
				/>
			</Formik>
		</>
	);
};

interface UpsertFormProps {
	base64: string;
	handleDismiss: () => void;
	serverValidationErrors: ValidationErrors | null;
	show: boolean;
	uploadType: 'image' | 'other';
}

export const InsertForm: React.FC<UpsertFormProps> = (props) => {
	const {handleSubmit, isSubmitting, errors, setErrors} = useFormikContext<FormValues>();
	const mergeServerErrors = () => {
		if (props.serverValidationErrors) {
			setErrors({...errors, ...props.serverValidationErrors});
		}
	};
	useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);

	return createPortal(
		<Modal id="upload_file_modal" tabIndex={-1} aria-hidden="true" dialogClassName="modal-dialog modal-lg" show={props.show} onHide={props.handleDismiss} animation={true} backdrop={true} container={modalsRoot}>
			<Modal.Header closeButton>
				<Modal.Title>Bestand uploaden</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={handleSubmit}>
					{props.uploadType === 'image' && (<div className="mb-4">
						<img className="rounded" src={props.base64} alt="" style={{height: '100%', width: '100%'}} />
					</div>)}

					<TextField row={true} name={'title'} label={'Titel'} autoFocus />
					<TextField row={true} name={'description'} label={'Omschrijving'} />
					{props.uploadType === 'image' && <TextField row={true} name={'altText'} label={'Alt-tekst'} />}

					<div className="row pt-5">
						<div className="offset-4 col-8 d-flex justify-content-end">
							<Button variant="link" className="mx-4" onClick={props.handleDismiss}>
								Annuleren
							</Button>
							<SubmitButton type="submit" className="btn btn-primary" isSubmitting={isSubmitting}>
								Opslaan
							</SubmitButton>
						</div>
					</div>
				</form>
			</Modal.Body>
		</Modal>,
		modalsRoot
	);
};
