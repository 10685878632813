import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../card';
import React, {FC} from 'react';
import SpinnerButtonV2 from '../SpinnerButtonV2';
import {useCustomerPreferencesOverview} from './useCustomerPreferencesOverview';
import {Mm} from '../Mm';
import {HefSchuifSlidingRailTypeText} from '../configurator/HefSchuifRailTypeText';
import {EkoFormRow} from '../EkoFormRow';

export type CustomerPreferencesOverviewProps = {
	customerId: string | undefined;
};

export const CustomerPreferencesOverview: FC<CustomerPreferencesOverviewProps> = (props) => {
	const {preferences, component, editPreferencesHefSchuif, editPreferencesVastglasBinnen, editPreferencesVastglasBuiten, editPreferencesDraaiKiep, editPreferencesDeurBinnendraaiend} =
		useCustomerPreferencesOverview(props.customerId);

	if (!preferences) return <></>;

	return (
		<>
			<div className="alert alert-info">
				De voorkeuren die je hier instelt, worden automatisch toegepast bij het configureren van nieuwe dorpels en kunnen tijdens het cofigureren op elk moment worden aangepast.
			</div>

			{/*<pre>{JSON.stringify(preferences, null, 2)}</pre>*/}

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Deur binnendraaiend'}>
					<EkoCardToolbar>
						<SpinnerButtonV2
							type="button"
							className="btn btn-primary"
							onClick={() => editPreferencesDeurBinnendraaiend({stopProfileId: preferences.deurBinnendraaiend?.stopProfile?.stopProfileId})}
							dotdotdot={false}
						>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Aanslag profiel</h6>
							<p>Standaard aanslagprofiel toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.deurBinnendraaiend?.stopProfile && preferences.deurBinnendraaiend.stopProfile.name) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Draaikiep'}>
					<EkoCardToolbar>
						<SpinnerButtonV2
							type="button"
							className="btn btn-primary"
							onClick={() =>
								editPreferencesDraaiKiep({
									stopProfileId: preferences.draaiKiep?.stopProfile?.stopProfileId,
									stopRabbetDepth: preferences.draaiKiep?.stopRabbetDepth
								})
							}
							dotdotdot={false}
						>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Aanslag profiel</h6>
							<p>Standaard aanslagprofiel toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.draaiKiep?.stopProfile && preferences.draaiKiep.stopProfile.name) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>

					<EkoFormRow>
						<div className="col">
							<h6>Aanslagsponning</h6>
							<p>Standaard aanslagsponning toepassen.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.draaiKiep?.stopRabbetDepth && <Mm value={preferences.draaiKiep.stopRabbetDepth} />) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Vastglas binnen'}>
					<EkoCardToolbar>
						<SpinnerButtonV2
							type="button"
							className="btn btn-primary"
							onClick={() =>
								editPreferencesVastglasBinnen({
									glazingBarId: preferences.vastglasBinnen?.glazingBar?.glazingBarId,
									glazingProfileId: preferences.vastglasBinnen?.glazingProfile?.glazingProfileId,
								})
							}
							dotdotdot={false}
						>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Glaslat</h6>
							<p>Standaard glaslat toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.vastglasBinnen?.glazingBar && preferences.vastglasBinnen.glazingBar.name) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>
					<EkoFormRow>
						<div className="col">
							<h6>Beglazingsprofiel</h6>
							<p>Standaard beglazingsprofiel toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.vastglasBinnen?.glazingProfile && preferences.vastglasBinnen.glazingProfile.name) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Vastglas buiten'}>
					<EkoCardToolbar>
						<SpinnerButtonV2
							type="button"
							className="btn btn-primary"
							onClick={() => editPreferencesVastglasBuiten({glazingBarId: preferences.vastglasBuiten?.glazingBar?.glazingBarId})}
							dotdotdot={false}
						>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Glaslat</h6>
							<p>Standaard glaslat toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.vastglasBuiten?.glazingBar && preferences.vastglasBuiten.glazingBar.name) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>
					<EkoFormRow>
						<div className="col">
							<h6>Beglazingsprofiel</h6>
							<p>Standaard beglazingsprofiel toepassen indien van toepassing.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.vastglasBinnen?.glazingProfile && preferences.vastglasBinnen.glazingProfile.name) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Hef-/schuif'}>
					<EkoCardToolbar>
						<SpinnerButtonV2
							type="button"
							className="btn btn-primary"
							onClick={() =>
								editPreferencesHefSchuif({
									slidingRailType: preferences.hefSchuif?.slidingRailType,
									doorBarCutout: preferences.hefSchuif?.doorBarCutout
										? {
												length: preferences.hefSchuif!.doorBarCutout!.length,
												width: preferences.hefSchuif!.doorBarCutout!.width,
										  }
										: undefined,
									fixedDoorBarInset: preferences.hefSchuif?.fixedDoorBarInset,
								})
							}
							dotdotdot={false}
						>
							Wijzigen
						</SpinnerButtonV2>
					</EkoCardToolbar>
				</EkoCardHeader>
				<EkoCardBody>
					<EkoFormRow>
						<div className="col">
							<h6>Looprail</h6>
							<p>Standaard looprail type.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.hefSchuif?.slidingRailType && <HefSchuifSlidingRailTypeText value={preferences.hefSchuif.slidingRailType} />) || (
								<span className="text-muted">Niet ingesteld</span>
							)}
						</div>
					</EkoFormRow>

					<EkoFormRow>
						<div className="col">
							<h6>Rubberblok</h6>
							<p>Standaard een uitsparing voor een rubberblok toepassen in hef-/schuifvakken.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.hefSchuif?.doorBarCutout && (
								<>
									{preferences.hefSchuif.doorBarCutout.length}x{preferences.hefSchuif.doorBarCutout.width}mm
								</>
							)) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>

					<EkoFormRow>
						<div className="col">
							<h6>Lat vastedeur inspringen</h6>
							<p>Standaard de lat vastedeur ingespringen ten opzichte van de vaste deur.</p>
						</div>
						<div className="col-2 d-flex align-items-center">
							{(preferences.hefSchuif?.fixedDoorBarInset && <Mm value={preferences.hefSchuif.fixedDoorBarInset} />) || <span className="text-muted">Niet ingesteld</span>}
						</div>
					</EkoFormRow>
				</EkoCardBody>
			</EkoCard>
			{component}
		</>
	);
};
