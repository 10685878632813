import {FileCategory, SillDetails, SillType} from '../../../../../modules/api-client/generated';
import React, {useCallback} from 'react';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../../../../shared/components/card';
import {Link} from 'react-router-dom';
import SillTypeText from '../SillTypeText';
import ActiveBadge from '../../../../../shared/components/ActiveBadge';
import {Mm} from '../../../../../shared/components';
import {FileUpload} from '../../../../../shared/components/file-upload/FileUpload';
import ProfileDropzone from '../../components/ProfileDropzone';
import SillContourSvg from '../../../../../shared/components/SillContourSvg';
import SillContraSvg from '../../../../../shared/components/SillContraSvg';
import {useSillUpdateContraProfileMutation} from '../hooks/use-sill-update-contra-profile-mutation';
import {useSillUpdateContourMutation} from '../hooks/use-sill-update-contour-mutation';
import {useSillUpsertImageMutation} from '../hooks/use-sill-upsert-image-mutation';

export interface SillDetailsCardProps {
	sill: SillDetails;
}

export const SillDetailsCard: React.FC<SillDetailsCardProps> = ({sill}) => {
	const updateContraProfileMutation = useSillUpdateContraProfileMutation(sill.id);
	const updateContourMutation = useSillUpdateContourMutation(sill.id);
	const updateImageMutation = useSillUpsertImageMutation(sill.id);

	const updateContraProfileCallback = useCallback(
		async (contraProfile: string) => {
			await updateContraProfileMutation.mutateAsync(contraProfile);
		},
		[updateContraProfileMutation]
	);

	const updateContourCallback = useCallback(
		async (contour: string) => {
			await updateContourMutation.mutateAsync(contour);
		},
		[updateContourMutation]
	);

	const updateImageCallback = useCallback(
		async (imageId: string) => {
			await updateImageMutation.mutateAsync(imageId);
		},
		[updateImageMutation]
	);

	return (
		<EkoCard className={'mb-3'}>
			<EkoCardHeader title={`Dorpel ${sill.code}`}>
				<EkoCardToolbar>
					<Link to="edit" relative="path" className="btn btn-primary ms-2">
						Wijzigen
					</Link>
				</EkoCardToolbar>
			</EkoCardHeader>
			<EkoCardBody>
				<div className="row mb-4">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Sponningtype</label>
					</div>
					<div className="col">
						<SillTypeText code={sill.type} />
					</div>
				</div>
				<div className="row mb-4">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Actief</label>
					</div>
					<div className="col">
						<ActiveBadge value={sill} />
					</div>
				</div>

				<div className="row mb-4">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Breedte (sponning + dam)</label>
					</div>
					<div className="col">
						<Mm value={sill.width} /> (<Mm value={sill.rabbetWidth} /> + <Mm value={sill.width - sill.rabbetWidth} />)
					</div>
				</div>

				{sill.type === SillType.HefSchuif && (
					<div className="row mb-4">
						<div className="col col-mb-5 col-lg-4 col-xxl-3">
							<label className="form-label">Rand</label>
						</div>
						<div className="col">
							<Mm value={sill.rand} />
						</div>
					</div>
				)}

				<div className="row mb-4">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Dorpelafbeelding</label>
					</div>
					<div className="col">
						<div className="position-relative" style={{height: '250px', width: '400px'}}>
							<FileUpload
								onFileUpload={updateImageCallback}
								show="drag"
								fileCategory={FileCategory.Dorpelafbeelding}
								sasUri={sill.imageReference?.blobUri ?? ''}
								uploadType="image"
								editImageLabel="Wijzig dorpelafbeelding"
							/>
						</div>
					</div>
				</div>

				<div className="row mb-4 position-relative">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Contour</label>
					</div>
					<div className="col">
						<ProfileDropzone onSelect={(selected) => updateContourCallback(selected)} preview={(profile) => <SillContourSvg profile={profile} />} show={'drag'}>
							{({openFileDialog}) => (
								<>
									<div
										className={`form-control d-flex justify-content-center align-items-center ${sill.contour ? '' : 'cursor-pointer'}`}
										onClick={() => {
											if (!sill.contour) openFileDialog();
										}}
										onDoubleClick={openFileDialog}
										style={{height: '200px', width: '400px'}}
									>
										{sill.contour && <SillContourSvg profileSvg={sill.contourSvg} svgHeight="140px" />}

										{!sill.contour && (
											<div className="d-flex flex-column justify-content-center align-items-center">
												<button type="button" className="btn btn-light-primary me-3" onClick={openFileDialog}>
													<i className="fa-regular fa-folder-open"></i> Blader
												</button>
												<div className="mt-2">of sleep een .DXF bestand hier</div>
											</div>
										)}
									</div>
									{sill.contour && (
										<div className="py-3 d-flex justify-content-start">
											<button type="button" className="btn btn-light-primary me-3 btn-sm" onClick={openFileDialog}>
												Wijzig contour
											</button>
										</div>
									)}
								</>
							)}
						</ProfileDropzone>
					</div>
				</div>

				<div className="row mb-4">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Contour profiel string</label>
					</div>
					<div className="col">
						<div className="input-group">
							<input className="form-control" value={sill.contour ?? ''} readOnly={true} />
							<button
								className="btn btn-outline-secondary"
								onClick={async () => {
									if (sill.contour) await navigator.clipboard.writeText(sill.contour);
								}}
								disabled={!sill.contour}
								type="button"
							>
								<i className="fa-regular fa-copy"></i>
							</button>
						</div>
					</div>
				</div>

				<div className="row mb-4 position-relative">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Contra profiel</label>
					</div>
					<div className="col">
						<ProfileDropzone
							onSelect={(selected) => updateContraProfileCallback(selected)}
							preview={(profile) => <SillContraSvg profile={profile} defaultNeutHeight={sill.neutOptions.defaultNeutHeight} />}
							show={'drag'}
						>
							{({openFileDialog}) => (
								<>
									<div
										className={`form-control d-flex justify-content-center align-items-center ${sill.contraProfile ? '' : 'cursor-pointer'}`}
										onClick={() => {
											if (!sill.contraProfile) openFileDialog();
										}}
										onDoubleClick={openFileDialog}
										style={{height: '200px', width: '400px'}}
									>
										{sill.contraProfile && <SillContraSvg profileSvg={sill.contraProfileSvg} defaultNeutHeight={sill.neutOptions.defaultNeutHeight} svgHeight="140px" />}

										{!sill.contraProfile && (
											<div className="d-flex flex-column justify-content-center align-items-center">
												<button type="button" className="btn btn-light-primary me-3" onClick={openFileDialog}>
													<i className="fa-regular fa-folder-open"></i> Blader
												</button>
												<div className="mt-2">of sleep een .DXF bestand hier</div>
											</div>
										)}
									</div>
									{sill.contraProfile && (
										<div className="py-3 d-flex justify-content-start">
											<button type="button" className="btn btn-light-primary me-3 btn-sm" onClick={openFileDialog}>
												Wijzig contra profiel
											</button>
											{/*<button type="button" className="btn btn-link"*/}
											{/*        onClick={() => console.log('reset')*/}
											{/*        }>Wissen*/}
											{/*</button>*/}
										</div>
									)}
								</>
							)}
						</ProfileDropzone>
					</div>
				</div>

				<div className="row mb-4">
					<div className="col col-mb-5 col-lg-4 col-xxl-3">
						<label className="form-label">Contra profiel string</label>
					</div>
					<div className="col">
						<div className="input-group">
							<input className="form-control" value={sill.contraProfile ?? ''} readOnly={true} />
							<button
								className="btn btn-outline-secondary"
								onClick={async () => {
									if (sill?.contraProfile) await navigator.clipboard.writeText(sill.contraProfile);
								}}
								disabled={!sill.contraProfile}
								type="button"
							>
								<i className="fa-regular fa-copy"></i>
							</button>
						</div>
					</div>
				</div>
			</EkoCardBody>
		</EkoCard>
	);
};
