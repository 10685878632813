/* eslint-disable jsx-a11y/anchor-is-valid */

import {Link, useNavigate, useParams} from 'react-router-dom';
import React, {Fragment, ReactElement, useState} from 'react';
import {PageTitle} from '../../../../_metronic/layout/core';
import Loading from '../../components/Loading';
import {EkoTable, TableHeader} from '../../components/table';
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from '../../components/card';
import {
    ImportOrderStatus,
    ImportStatus,
    ImportType,
    ImportValidationStatus,
    ImportValidationType, OrderStatusCodes,
    ResetImportValidationCommand,
    ResolveCompartmentProfileSuggestionImportValidationCommand,
    ResolveImportValidationCommandType,
} from '../../../modules/api-client/generated';
import {ImportRoutePath} from '../routes/ImportRoutes';
import {useGetImportSummary} from '../hooks/use-get-import-summary';
import ProfileMatchPreviewSvg from '../../components/ProfileMatchPreviewSvg';
import {
    useGetImportValidationResult
} from '../hooks/use-get-import-validation-result';
import {usePostCreateImportOrder} from '../hooks/use-post-create-import-order';
import {usePostCreateEdsOrder} from '../hooks/use-post-create-eds-order';
import {SmRoutePath} from '../../../backoffice/modules/sm/SmRoutes';
import {usePostRevalidateImport} from '../hooks/use-post-revalidate-import';
import {usePutValidationResolve} from '../hooks/use-put-validation-resolve';
import SpinnerButton from '../../components/SpinnerButton';
import BooleanBadge from '../../components/BooleanBadge';
import ImportStatusProgress from '../components/ImportStatusProgress';
import {useEkosietAuth0} from '../../../modules/auth0/core/useEkosietAuth0';
import {CustomerRoutePath} from '../../../customer-portal/routing/RoutePath';
import ConfiguredSillSpecification from "../../components/ConfiguredSillSpecification";
import {ImportModal} from "../components/ImportModal";
import {usePostRetrySillConfiguration} from "../hooks/use-post-retry-sill-configuration";

export function ImportPage() {
    const navigate = useNavigate();
    const {id} = useParams<{ id: string }>();
    const {data: importSummary, isLoading, isLoadingError} = useGetImportSummary(id);
    const createOrderMutation = usePostCreateImportOrder(id);
    const retrySillConfiguration = usePostRetrySillConfiguration(id);
    const createEdsOrderMutation = usePostCreateEdsOrder(id);
    const {isKlant} = useEkosietAuth0();
    const {
        data: importValidation,
        isLoading: isValidationLoading,
    } = useGetImportValidationResult(id, importSummary && importSummary?.type !== ImportType.Unknown);

    const [modalFullscreen, setModalFullscreen] = useState<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>(true)
    const [modalTitle, setModalTitle] = useState<string | undefined | null>(null);
    const [modalComponent, setModalComponent] = useState<ReactElement | undefined | null>(null);
    const [showEditModal, setShowEditModal] = useState<boolean>(false)

    var resolveMutation = usePutValidationResolve();
    var revalidateMutation = usePostRevalidateImport();


    const AcceptProfileSuggestion = async (id: string, type: ImportType, profileId: string) => {
        var command: ResolveCompartmentProfileSuggestionImportValidationCommand = {
            profileId: profileId,
            commandType: ResolveImportValidationCommandType.ResolveCompartmentProfileSuggestionImportValidationCommand,
            id: id,
            importType: type
        };
        await resolveMutation.mutateAsync(command);
    };

    const ResetImportValidation = async (id: string, type: ImportType) => {
        var command: ResetImportValidationCommand = {
            commandType: ResolveImportValidationCommandType.ResetImportValidationCommand,
            id: id,
            importType: type,
        };

        await resolveMutation.mutateAsync(command);
    };

    const onCreateOrder = async (importId: string) => {
        await createOrderMutation.mutateAsync(importId);
    };

    const onRetrySillConfiguration = async (importId: string) => {
        await retrySillConfiguration.mutateAsync(importId);
    };

    const onCreateEdsOrder = async (importId: string) => {
        const edsOrderId = await createEdsOrderMutation.mutateAsync(importId);
        if (isKlant) {
            navigate(CustomerRoutePath.link(CustomerRoutePath.orderWizardLink(edsOrderId)));
        } else {
            navigate(SmRoutePath.link(SmRoutePath.orderAddWithIdLink(edsOrderId)));
        }
    };

    const viewSillDetails = (catalogItemId: string) => {
        setModalComponent(<ConfiguredSillSpecification catalogItemId={catalogItemId}/>);
        setModalTitle('Dorpel details');
        setModalFullscreen(true);
        setShowEditModal(true);
    }

    const groupedValidations = importValidation?.importValidations.reduce((acc: any, validation: any) => {
        const {merk} = validation;
        if (!acc[merk]) {
            acc[merk] = [];
        }
        acc[merk].push(validation);
        return acc;
    }, {});

    var sortedGroupedValidations = [];
    if (groupedValidations) {

        // Step 1: Count the pending validations for each group
        const pendingValidationsCount = Object.keys(groupedValidations).reduce((acc: any, merk) => {
            acc[merk] = groupedValidations[merk].filter((validation: any) => validation.status === ImportValidationStatus.Pending).length;
            return acc;
        }, {});

// Step 2: Sort the groups based on whether they have pending validations
        sortedGroupedValidations = Object.keys(groupedValidations).sort((a, b) => {
            const aHasPending = pendingValidationsCount[a] > 0 ? 1 : 0;
            const bHasPending = pendingValidationsCount[b] > 0 ? 1 : 0;
            return bHasPending - aHasPending;
        }).reduce((acc: any, merk) => {
            acc[merk] = groupedValidations[merk];
            return acc;
        }, {});

    }
    if (!importSummary) return <></>;
    const importOrder = importSummary.importOrder;

    return (
        <>
            <PageTitle>Import</PageTitle>
            <div className={'alert alert-info'}>
                <span className={'fw-bold'}>Let op:</span> De import functionaliteit is nog in ontwikkeling.
                Controleer de geïmporteerde dorpels.
                <br/>
                <span className={'fw-bold'}>Nieuw:</span> Alle hefschuif schema's worden nu geimporteerd! Feedback
                vernemen we graag!
            </div>
            <ImportModal show={showEditModal} handleClose={() => setShowEditModal(false)}
                         title={modalTitle}
                         size={'lg'}
                         fullscreen={modalFullscreen}
                         component={modalComponent}
            />
            <ImportStatusProgress className={'my-5'} status={importSummary.status}/>

            {isLoading && <Loading/>}

            {isLoadingError &&
                <div>Er is iets fout gegaan. Neem contact op met Ekosiet als dit probleem zich blijft voordoen.</div>}

            {importSummary.status === ImportStatus.InvalidFile && (
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'alert alert-danger'}>Dit bestandstype wordt niet ondersteund. Neem contact op
                            met Ekosiet als dit probleem zich blijft voordoen.
                        </div>
                    </div>
                </div>
            )}
            {importSummary.status === ImportStatus.ValidatingEdsMapping && (
                <>
                    {isValidationLoading && <Loading/>}
                    {importValidation && (
                        <>
                            <EkoCard>
                                <EkoCardHeader title={'Import gegevens valideren'}>
                                    <EkoCardToolbar>
                                        <SpinnerButton
                                            onClick={async () =>
                                                window.confirm('Weet je het zeker? De complete import wordt opnieuw gevalideerd. Bestaande validaties worden vervangen.') &&
                                                (await revalidateMutation.mutateAsync({
                                                    importId: importValidation.importId,
                                                    type: importValidation.type,
                                                }))
                                            }
                                            className="btn btn-sm btn-light-danger ms-3"
                                            spinning={revalidateMutation.isLoading}
                                            disabled={revalidateMutation.isLoading}
                                        >
                                            Reset koppeling voor deze import
                                        </SpinnerButton>
                                    </EkoCardToolbar>
                                </EkoCardHeader>
                            </EkoCard>

                            {Object.keys(sortedGroupedValidations).map((merk) => {
                                const hasPendingValidations = groupedValidations[merk].some((validation: any) => validation.status === ImportValidationStatus.Pending);
                                return (
                                    <Fragment key={merk}>
                                        <EkoCard className={'mt-5'}>
                                            <EkoCardHeader title={'Merk: ' + merk}>
                                                <EkoCardToolbar>
                                                    {!hasPendingValidations ? (
                                                        <div className={'badge bg-success'}>Alle validaties zijn
                                                            opgelost</div>
                                                    ) : (
                                                        <div className={'badge bg-danger'}>Los de volgende validaties
                                                            op:</div>

                                                    )}
                                                </EkoCardToolbar>
                                            </EkoCardHeader>
                                            <EkoCardBody>
                                                <EkoTable>
                                                    <tbody>
                                                    {groupedValidations[merk].map((validation: any) => (
                                                        <tr key={validation.id}>
                                                            <td>
                                                                <div className={'d-flex align-items-center'}>
                                                                    {
                                                                        (validation.validationType === ImportValidationType.GroeneveldCompartmentProfileMissingImportValidation
                                                                            || validation.validationType === ImportValidationType.GroeneveldCompartmentProfileSuggestionImportValidation)
                                                                        &&
                                                                        validation.status === ImportValidationStatus.Pending && (
                                                                            <Link
                                                                                to={ImportRoutePath.importValidationLink(importSummary.id, importValidation.customerId, validation.id)}>
                                                                                <div
                                                                                    style={{
                                                                                        height: '150px',
                                                                                        width: '150px',
                                                                                    }}
                                                                                >
                                                                                    <ProfileMatchPreviewSvg
                                                                                        svgWidth={'150px'}
                                                                                        svgHeight={'150px'}
                                                                                        profileA={validation.edsGeometry}
                                                                                        profileB={validation.suggestedProfileGeometry}/>
                                                                                </div>
                                                                                {validation.sillTitle}
                                                                            </Link>
                                                                        )}

                                                                    <span className={'fs-3 '}>
                                                                        {validation.message}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {validation.status === ImportValidationStatus.Pending ? (
                                                                    <div className={'d-flex justify-content-end'}>
                                                                        {validation.validationType === ImportValidationType.GroeneveldCompartmentProfileSuggestionImportValidation ? (
                                                                            <>
                                                                                <SpinnerButton
                                                                                    onClick={async () => {
                                                                                        await AcceptProfileSuggestion(validation.id, validation.type, validation.suggestedProfileId);
                                                                                    }}
                                                                                    className="btn btn-light-success me-2">
                                                                                    Accepteren
                                                                                </SpinnerButton>
                                                                                <Link
                                                                                    to={ImportRoutePath.importValidationLink(importSummary.id, importValidation.customerId, validation.id)}
                                                                                    className="btn btn-primary"
                                                                                >
                                                                                    Kies ander profiel
                                                                                </Link>
                                                                            </>
                                                                        ) : (
                                                                            <Link
                                                                                to={ImportRoutePath.importValidationLink(importSummary.id, importValidation.customerId, validation.id)}
                                                                                className="btn btn-primary"
                                                                            >
                                                                                Oplossen
                                                                            </Link>)
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div
                                                                        className={'d-flex justify-content-end h3 align-items-center'}>
                                                                        {validation.status === ImportValidationStatus.Resolved ? (
                                                                            <em>
                                                                                <i className={'fa fa-check-circle text-success'}></i> Opgelost
                                                                            </em>
                                                                        ) : (
                                                                            <em>
                                                                                <i className={'fa fa-triangle-exclamation text-danger'}></i> Genegeerd
                                                                            </em>
                                                                        )}

                                                                        {/*we don't support resets for resolved GA import sill mappings validations at this point */}
                                                                        {(validation.validationType !== ImportValidationType.GroeneveldSillMappingImportValidation ||
                                                                            (validation.validationType === ImportValidationType.GroeneveldSillMappingImportValidation &&
                                                                                validation.status !== ImportValidationStatus.Resolved)) && (
                                                                            <a
                                                                                role="button"
                                                                                onClick={async () => {
                                                                                    await ResetImportValidation(validation.id, validation.type);
                                                                                }}
                                                                                className={'btn btn-sm btn-light ms-2'}
                                                                            >
                                                                                <i className={'fa fa-redo'}></i> Reset
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </td>
                                                            {/* Add more fields as necessary */}
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </EkoTable>
                                            </EkoCardBody>
                                        </EkoCard>
                                    </Fragment>
                                )
                            })}
                        </>
                    )}
                </>
            )}
            {(importSummary.status === ImportStatus.ConfiguringSills || importSummary.status === ImportStatus.SillConfigurationFailed || importSummary.status === ImportStatus.EdsMappingValidated) && (
                <>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 col-xl-4 offset-md-3 offset-xl-4'}>
                            <EkoCard shadow={true}>
                                <EkoCardBody>
                                    <h1 className={'mb-10'}> Aanmaken dorpels
                                    </h1>

                                    {isValidationLoading && <Loading/>}
                                    <>
                                        <div className={'lead fs-3 mb-5'}>
                                            <div>
                                <span>De gegevens zijn gevalideerd, in de deze stap worden dorpels geconfigureerd
                                    waarna een order aangemaakt kan worden. Let op: Dit kan een aantal minuten duren.</span>
                                            </div>
                                        </div>
                                        {(importSummary.status !== ImportStatus.ConfiguringSills && importSummary.orderStatus === ImportOrderStatus.NotStarted) && (

                                            <SpinnerButton
                                                onClick={async () => onCreateOrder(id!)}
                                                className="btn btn-primary"
                                                spinning={false}
                                            >
                                                <>Start het aanmaken van dorpels</>
                                            </SpinnerButton>
                                        )}
                                        {(importSummary.status === ImportStatus.ConfiguringSills || importSummary.orderStatus === ImportOrderStatus.Started) && (
                                            <SpinnerButton
                                                onClick={async () => onCreateOrder(id!)}
                                                className="btn btn-primary"
                                                spinning={true}
                                            >
                                                <>Dorpels worden aangemaakt...</>
                                            </SpinnerButton>
                                        )}
                                        {(importSummary.status !== ImportStatus.ConfiguringSills && importSummary.orderStatus === ImportOrderStatus.TimeOutExpired) && (
                                            <>
                                                <SpinnerButton onClick={async () => onCreateOrder(id!)}
                                                               className="btn btn-primary">
                                                    Time out, probeer opnieuw
                                                </SpinnerButton>
                                            </>
                                        )}
                                        {(importSummary.status === ImportStatus.SillConfigurationFailed && importSummary.orderStatus === ImportOrderStatus.Failed) && (
                                            <>
                                                <div
                                                    className={'alert alert-danger d-flex justify-content-between align-items-center'}>
                                                    <div>Het aanmaken van Ekosiet dorpels is mislukt. Blijft het
                                                        probleem zich voordoen?
                                                        Neem contact op met onze werkvoorbereiding.
                                                    </div>
                                                    <SpinnerButton onClick={async () => onRetrySillConfiguration(id!)}
                                                                   className="btn btn-primary">
                                                        Probeer opnieuw
                                                    </SpinnerButton>
                                                </div>
                                            </>
                                        )}
                                    </>
                                </EkoCardBody>
                            </EkoCard>
                        </div>
                    </div>
                </>
            )}

            {importSummary.status === ImportStatus.SillsConfigured && (
                <>
                    {importOrder && (
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 col-xl-4 offset-md-3 offset-xl-4'}>
                                <EkoCard shadow={true}>
                                    <EkoCardBody>
                                        <h1 className={'mb-10'}> Order aanmaken
                                        </h1>
                                        <p className={'lead fs-3'}>In de volgende stap kun je de geimporteerde dorpels controleren
                                            en eventueel
                                            aanpassen.</p>
                                        <div className={'row lead fs-3'}>
                                            <div className={'col'}>Project referentie</div>
                                            <div className={'col'}>{importOrder.reference}</div>
                                        </div>
                                        <div className={'mt-5'}>
                                            <SpinnerButton
                                                onClick={async () => onCreateEdsOrder(importSummary.id)}
                                                className="btn btn-primary"
                                                spinning={createEdsOrderMutation.isLoading}>
                                                Naar order plaatsen
                                            </SpinnerButton>
                                        </div>
                                        {/*<div className={'row'}>*/}
                                        {/*    <div className={'col'}>*/}
                                        {/*        <EkoCard className={'mb-5'}>*/}
                                        {/*            <EkoCardBody>*/}
                                        {/*                <div className={'d-flex align-items-center mb-5'}>*/}
                                        {/*                    Muuraansluitingen overgenomen?{' '}*/}
                                        {/*                    <span className={'fw-bold ms-2'}>*/}
                                        {/*            <BooleanBadge value={importSummary.detectWallConnections}/>*/}
                                        {/*        </span>*/}
                                        {/*                </div>*/}
                                        {/*                <EkoTable*/}
                                        {/*                    className={'card-xxl-stretch mb-5 mb-xxl-8 groeneveld-orderlines'}>*/}
                                        {/*                    <TableHeader>*/}
                                        {/*                        <th>Aantal</th>*/}
                                        {/*                        <th className="min-w-40px">Dorpel</th>*/}
                                        {/*                        <th className="min-w-40px">Merk</th>*/}
                                        {/*                    </TableHeader>*/}
                                        {/*                    <tbody>*/}
                                        {/*                    {importOrder.lines.map((orderline) => {*/}
                                        {/*                        return (*/}
                                        {/*                            <Fragment key={orderline.id}>*/}
                                        {/*                                <tr className={orderline.catalogItemId ? '' : 'danger'}>*/}
                                        {/*                                    <td>{orderline.quantity}</td>*/}
                                        {/*                                    <td>*/}
                                        {/*                                        <div*/}
                                        {/*                                            className={'d-flex align-items-start flex-column'}>*/}
                                        {/*                                            <div>*/}
                                        {/*                                                {orderline.catalogItemId ?*/}
                                        {/*                                                    <a href={'#'}*/}
                                        {/*                                                       className={'fw-bold'}*/}
                                        {/*                                                       onClick={() => viewSillDetails(orderline.catalogItemId as string)}>*/}
                                        {/*                                                        {orderline.title}*/}
                                        {/*                                                    </a> :*/}
                                        {/*                                                    <span*/}
                                        {/*                                                        className={'fw-bold'}>{orderline.title}</span>*/}
                                        {/*                                                }*/}
                                        {/*                                            </div>*/}
                                        {/*                                        </div>*/}
                                        {/*                                    </td>*/}
                                        {/*                                    <td>{orderline.merk}</td>*/}
                                        {/*                                    <td>*/}
                                        {/*                                        {!orderline.catalogItemId &&*/}
                                        {/*                                            <span>Geen dorpel geconfigureerd gevonden, deze*/}
                                        {/*                                    regel wordt genegeerd bij aanmaken van een*/}
                                        {/*                                    order</span>}*/}
                                        {/*                                    </td>*/}
                                        {/*                                </tr>*/}
                                        {/*                            </Fragment>*/}
                                        {/*                        );*/}
                                        {/*                    })}*/}
                                        {/*                    </tbody>*/}
                                        {/*                </EkoTable>*/}
                                        {/*            </EkoCardBody>*/}
                                        {/*        </EkoCard>*/}
                                    </EkoCardBody>
                                </EkoCard>
                            </div>
                        </div>

                    )}
                </>
            )}
            {importSummary.status === ImportStatus.Completed && (
                <div className={'row'}>
                    <div className={'col-12 col-md-6 col-xl-4 offset-md-3 offset-xl-4'}>
                        <EkoCard shadow={true}>
                            <EkoCardBody>
                                <h1 className={'mb-10'}><i
                                    className={'fa fa-circle-check fa-2x fs-1 text-success'}></i> Import
                                    voltooid</h1>
                                {importSummary.edsOrder ? (
                                    <>
                                        <div className={'lead fs-3 mb-5'}>
                                            Deze is import is voltooid, klik hier om naar de
                                            bestelling te gaan.
                                        </div>
                                        <div className={'d-flex justify-content-end'}>
                                            {isKlant ?
                                                <Link
                                                    to={CustomerRoutePath.link(CustomerRoutePath.orderLink(importSummary.edsOrder.id!))}
                                                    relative="path"
                                                    className='btn btn-inline-block btn-primary stretched-link'>
                                                    {importSummary.edsOrder.code}
                                                    <i className={'fa fa-caret-right fa-2x fs-1'}></i>
                                                </Link>
                                                :
                                                <Link
                                                    to={SmRoutePath.link(SmRoutePath.orderEditLink(importSummary.edsOrder.id!))}
                                                    relative="path"
                                                    className='btn btn-inline-block btn-primary stretched-link'>
                                                    {importSummary.edsOrder.code}
                                                    <i className={'fa fa-caret-right fa-2x fs-1'}></i>
                                                </Link>
                                            }
                                        </div>
                                    </>
                                ) : (
                                    <div className={'lead fs-3 mt-5 mb-5'}>
                                        Deze is import is voltooid, er is geen order gevonden, kijk
                                        in de lijst met orders.
                                    </div>
                                )}
                            </EkoCardBody>
                        </EkoCard>
                    </div>
                </div>
            )}
        </>
    );
}
