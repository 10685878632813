import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {Orders} from '../pages/Orders'
import {ErrorBoundary, FallbackProps} from "react-error-boundary";

import React from "react";
import {CustomerRoutePath} from "./RoutePath";
import axios from "axios";
import {CustomerDashboard} from "../pages/Dashboard";
import {Account} from "../../pages/Account";
import {StartOrder} from "../pages/StartOrder";
import {Order} from "../pages/Order";
import {OrderPlaced} from "../pages/OrderPlaced";
import {QuoteRequested} from "../pages/QuoteRequested";
import {AddOrderWizard} from "../../backoffice/modules/sm/orders/new-order/components/AddOrderWizard";
import {OrderLineConfigurator} from "../../backoffice/modules/sm/pages/OrderLineConfigurator";
import ImportRoutes from "../../shared/import/routes/ImportRoutes";
import {Preferences} from '../pages/Preferences';

const CustomerPortalRoutes = () => {
  return (
    <Routes>
        <Route element={<ErrorBoundary FallbackComponent={Fallback}><Outlet/></ErrorBoundary>}>
            <Route path={CustomerRoutePath.dashboard} element={<CustomerDashboard/>}/>
            <Route
                path={CustomerRoutePath.relative(CustomerRoutePath.import) + '*'}
                element={<ImportRoutes />}
            />
            <Route path={CustomerRoutePath.orders} element={<Orders />}/>
            <Route path={CustomerRoutePath.account} element={<Account/>}/>
            <Route path={CustomerRoutePath.preferences} element={<Preferences/>}/>

            <Route path={CustomerRoutePath.order} element={<><Order /><Outlet/></>}>
                <Route path='configurator/*' element={<OrderLineConfigurator isOrderEdit={true}/>} />
            </Route>
            <Route path={CustomerRoutePath.orderWizard} element={<><AddOrderWizard /><Outlet /></>}>
                <Route path='configurator/*' element={<OrderLineConfigurator isOrderEdit={false}/>} />
            </Route>
            <Route path={CustomerRoutePath.orderStart} element={<StartOrder/>}/>
            <Route path={CustomerRoutePath.orderPlaced} element={<OrderPlaced/>}/>
            <Route path={CustomerRoutePath.quoteRequested} element={<QuoteRequested/>}/>

        </Route>
        <Route index element={<Navigate to={CustomerRoutePath.dashboard}/>}/>
    </Routes>
  )
}
const Fallback: React.FC<FallbackProps> = (props) => {

    if (axios.isAxiosError(props.error)) {
        if (props.error.response?.status === 404) {
            return <>Not found</>;
        }
        if (props.error.response?.status === 500) {
            return <>Server error</>;
        }
    }

    return <>Error</>;
};

export default CustomerPortalRoutes
