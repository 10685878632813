/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement, useCallback, useRef} from 'react'
import {To} from "react-router-dom";
import {
    OrderLineRepresentation,
    OrderStatusCodes,
    ProductionStatusCodes,
} from "../../../../../../modules/api-client/generated";
import Euro from "../../../../../../shared/components/Euro";
import OrderlineTypeIcon from "../../../../../../shared/components/OrderlineTypeIcon";
import {PutOrderlineForm} from "./forms/orderline/PutOrderlineForm";
import {useDeleteOrderline} from "../../hooks/orderline/use-delete-orderline";
import {ConfirmDialog} from '../../../../../../shared/components/ConfirmDialog';
import {useGetOrder} from '../../hooks/use-get-order';
import {useCopyOrderLine} from '../../hooks/orderline/use-copy-orderline';
import {useNavigate} from "react-router-dom";
import {SmRoutePath} from "../../../SmRoutes";
import ConfiguredSillSpecification from "../../../../../../shared/components/ConfiguredSillSpecification";
import {CopySillForm} from "../../components/CopySillForm";
import {SbomPrice} from "../../components/SbomPrice";
import {PutSbomForm} from "./forms/orderline/PutSbomForm";
import {useEkosietAuth0} from "../../../../../../modules/auth0/core/useEkosietAuth0";
import {CustomerRoutePath} from "../../../../../../customer-portal/routing/RoutePath";
import ProductionSillSpecification from "../../../../../../shared/components/ProductionSillSpecification";

type Props = {
    index: number,
    customerId: string,
    pricelistId: string,
    orderLine: OrderLineRepresentation
    productionStatus?: ProductionStatusCodes | undefined,
    isOrderEdit: boolean
    status?: OrderStatusCodes
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setModalFullscreen: React.Dispatch<React.SetStateAction<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const OrderLineTableRow: React.FC<Props> = ({
                                                index,
                                                customerId,
                                                pricelistId,
                                                isOrderEdit,
                                                orderLine,
                                                productionStatus,
                                                setModalTitle,
                                                setModalComponent,
                                                setShowEditModal,
                                                setModalFullscreen,
                                                status
                                            }) => {


    const navigate = useNavigate();
    const mutateCopyOrder = useCopyOrderLine(orderLine.id);
    const {refetch: refetchOrder} = useGetOrder(orderLine.orderId, false);
    const {isKlant} = useEkosietAuth0();

    if (!orderLine.id || !status) return <></>;

    const editOrderline = (line: OrderLineRepresentation) => {
        setModalComponent(<PutOrderlineForm line={line} handleClose={() => setShowEditModal(false)}/>);
        setModalTitle('Orderregel aanpassen');
        setShowEditModal(true);
    }

    const copyOrderLine = async (line: OrderLineRepresentation) => {
        if (line.type === 'configurationLine') {
            setModalComponent(<CopySillForm line={line} handleClose={() => setShowEditModal(false)}/>
            );
            setModalTitle('Dorpel kopiëren');
            setModalFullscreen('xxl-down');
            setShowEditModal(true);
        } else {
            await mutateCopyOrder.mutateAsync(line);
            refetchOrder();
        }
    }
    const viewSillDetails = (catalogItemId: string) => {
        setModalComponent(<>
            {/*<ProductionSillSpecification catalogItemId={catalogItemId}/>*/}
            <ConfiguredSillSpecification catalogItemId={catalogItemId} showDrawingAndNeuten={true}/>
        </>);
        setModalTitle('Dorpel');
        setModalFullscreen('xxl-down');
        setShowEditModal(true);
    }

    const editConfiguredSill = (line: OrderLineRepresentation) => {

        if (line.type !== 'configurationLine') return;

        const params = {
            orderId: line.orderId,
            customerId: customerId,
            pricelistId: pricelistId,
            orderLineId: line.id,
            catalogItemId: line.catalogItemId ?? undefined,
            quantity: line.quantity.toString(),
            remark: line.remark ?? undefined,
            merk: line.merk ?? undefined
        };

        if (isKlant) {
            navigate(CustomerRoutePath.orderAddWithIdConfiguratorLink(params));
        } else {
            navigate(SmRoutePath.orderEditConfiguratorLineLink(params));
        }
    }

    const editSbom = (line: OrderLineRepresentation) => {
        setModalComponent(<PutSbomForm line={line} handleClose={() => setShowEditModal(false)}/>);
        setModalTitle('Prijs opbouw');
        setModalFullscreen('xxl-down');
        setShowEditModal(true);
    }

    return (
        <>
            <tr>
                {isOrderEdit &&
                    <>
                        <td className={'text-right'}>{index + 1}</td>
                        <td className={'text-center'}><OrderlineTypeIcon type={orderLine.type}/></td>
                    </>
                }
                <td className={'text-center'}>{orderLine.quantity}</td>
                <td>
                    {orderLine.type === 'configurationLine' ?
                        <a href={'#'} onClick={() => viewSillDetails(orderLine.catalogItemId as string)}>
                            {orderLine.title}
                        </a> :
                        <>{orderLine.title}</>
                    }
                    {orderLine.description &&
                        <>
                            <div className={'text-muted fs-7'}>{orderLine.description}</div>
                        </>
                    }
                    {orderLine.remark &&
                        <>
                            <div className={'text-muted fs-7'}>{orderLine.remark}</div>
                        </>
                    }
                </td>
                <td>{orderLine.merk}</td>

                <td className={'text-end'} style={{position: 'relative'}}>
                    {isOrderEdit ?
                        <>
                            <SbomPrice Sbom={orderLine.sbom} price={orderLine.price}/>
                            <button type="button" className={`btn btn-icon btn-sm btn-light me-2 `}
                                    title='Edit dorpel configuratie' onClick={() => editSbom(orderLine)}>
                                <i className="fas fa-edit"></i>
                            </button>
                        </>
                        :
                        <Euro value={orderLine.price}/>
                    }


                </td>
                <td className={'text-end'}><Euro value={orderLine.total}/></td>
                <td className='text-end fit-content'>
                    {
                        (status === OrderStatusCodes.Draft
                            || status === OrderStatusCodes.Placed
                            || status === OrderStatusCodes.Accepted)
                        && productionStatus === ProductionStatusCodes.NotStarted &&
                        <>
                            {orderLine.type === 'configurationLine' &&
                                <button type="button" className={`btn btn-icon btn-sm btn-light me-2 `}
                                        title='Edit dorpel configuratie' onClick={() => editConfiguredSill(orderLine)}>
                                    <i className="fas fa-wand-magic-sparkles"></i>
                                </button>
                            }
                            {orderLine.type === 'configurationLine' &&
                                <button type="button" className={`btn btn-icon btn-sm btn-light me-2 `}
                                        title='Maak een kopie van deze order regel'
                                        onClick={() => copyOrderLine(orderLine)}>
                                    <i className="fas fa-copy"></i>
                                </button>
                            }
                            <button type="button" className={`btn btn-icon btn-sm btn-light me-2 `}
                                    title='Edit order regel' onClick={() => editOrderline(orderLine)}>
                                <i className="fas fa-edit"></i>
                            </button>
                            <DeleteButton orderLine={orderLine} key={'delete-orderline-' + orderLine.id}/>
                        </>
                    }
                </td>
            </tr>
        </>
    )
}

const DeleteButton: React.FC<{ orderLine: OrderLineRepresentation }> = ({orderLine}) => {

    const openDeleteDialog = useRef<(show: boolean) => void>();
    const deleteMutation = useDeleteOrderline(orderLine.id);
    const {refetch: refetchOrder} = useGetOrder(orderLine.orderId, false, false);

    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(undefined, {});
        openDeleteDialog.current?.call(this, false)
        refetchOrder();
    }, [deleteMutation, refetchOrder]);

    return (
        <>
            <a href="#" className="btn btn-icon btn-sm btn-light-danger"
               onClick={() => openDeleteDialog.current?.call(this, true)}>
                <i className="fas fa-trash"></i>
            </a>

            <ConfirmDialog show={openDeleteDialog}
                           onConfirm={deleteCallback}
                           dialogStyle="danger"
                           title="Verwijderen"
                           message="Wil je doorgaan?"
                           confirmText="Definitief verwijderen"

            />
        </>
    )
}

export {OrderLineTableRow}
