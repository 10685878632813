import React, {FC} from 'react';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useQuery} from '@tanstack/react-query';
import Error from '../../../../shared/components/Error';
import Loading from '../../../../shared/components/Loading';
import NotFound from '../../../../shared/components/NotFound';
import {ConfiguredSillSpecificationDetails} from '../../../../shared/components/ConfiguredSillSpecification';
import {EkoTable} from '../../../../shared/components/table';
import {EkoCard, EkoCardHeader, EkoCardBody} from '../../../../shared/components/card';
import {RenovationRabbet, StandardWallConnection, WallConnection, WallConnectionType} from '../../../../modules/api-client/generated';
import {Mm} from '../../../../shared/components';

export interface ConfiguredSillDetailsProps {
	configuredSillId: string;
}

export const ConfiguredSillDetails: FC<ConfiguredSillDetailsProps> = (props) => {
	const {data, isInitialLoading, isLoadingError} = useQuery(['Core.ConfiguredSill.sillGetById', props.configuredSillId], async () => {
		const response = await ApiClient.Core.ConfiguredSill.sillGetById(props.configuredSillId);
		return response.data;
	});

	if (isLoadingError) return <Error />;
	if (isInitialLoading) return <Loading />;

	if (!data) return <NotFound />;

	const {id, sill, svg} = data;

	return (
		<>
			<h1>Dorpel {props.configuredSillId}</h1>

			{/*<ConfiguredSillDetailsDiffAlert configuredSillId={props.configuredSillId} />*/}

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Tekening'}></EkoCardHeader>
				<EkoCardBody>
					<ConfiguredSillSpecificationDetails configuredSillId={id} sill={sill} svg={svg} svgHeight="300px" />
				</EkoCardBody>
			</EkoCard>

			<EkoCard className="mb-5">
				<EkoCardHeader title={'Specificatie'}></EkoCardHeader>
				<EkoCardBody>
					<EkoTable>
						<tbody>
							<tr>
								<th colSpan={2} className="fw-bold">
									Dorpel
								</th>
							</tr>

							<tr>
								<td className="fit-content">Model</td>
								<th>{sill.model.code}</th>
							</tr>

							<tr>
								<td className="fit-content">Totaallengte</td>
								<th>
									<Mm value={sill.totalLength} />
								</th>
							</tr>

							<tr>
								<td className="fit-content">Breedte</td>
								<th>
									<Mm value={sill.model.width} />
								</th>
							</tr>
							<tr>
								<td className="fit-content">Sponningbreedte</td>
								<th>
									<Mm value={sill.model.rabbetWidth} />
								</th>
							</tr>

							<tr>
								<th colSpan={2} className="fw-bold">
									Muuraansluiting links
								</th>
							</tr>
							<WallConnectionRows wallConnection={sill.wallConnectionLeft} />

							<tr>
								<th colSpan={2} className="fw-bold">
									Muuraansluiting rechts
								</th>
							</tr>
							<WallConnectionRows wallConnection={sill.wallConnectionRight} />

							{sill.neuten && (
								<>
									<tr>
										<th colSpan={2} className="fw-bold">
											Neuten
										</th>
									</tr>
									<tr>
										<td className="fit-content">Aantal</td>
										<td>{(sill.neuten.length === 0 && <>Geen</>) || <>{sill.neuten.length} neuten</>}</td>
									</tr>
								</>
							)}

							{sill.compartments && (
								<>
									<tr>
										<th colSpan={2} className="fw-bold">
											Vakken
										</th>
									</tr>
									<tr>
										<td className="fit-content">Aantal</td>
										<td>{(sill.compartments.length === 0 && <>Geen</>) || <>{sill.compartments.length} vakken</>}</td>
									</tr>
								</>
							)}
						</tbody>
					</EkoTable>
				</EkoCardBody>
			</EkoCard>
		</>
	);
};

export const WallConnectionRows: FC<{wallConnection: WallConnection}> = ({wallConnection}) => {
	return (
		<>
			<tr>
				<td className="fit-content">Type</td>
				<td>{wallConnection.wallConnectionType === WallConnectionType.Standard && <>Standaard</>}</td>
			</tr>
			{wallConnection.wallConnectionType === WallConnectionType.Standard && <StandardWallConnectionRows wallConnection={wallConnection as StandardWallConnection} />}
		</>
	);
};

export const StandardWallConnectionRows: FC<{wallConnection: StandardWallConnection}> = ({wallConnection}) => {
	const renderRenovationRabbet = (renovationRabbet: RenovationRabbet | undefined, label: string) => {
		return <>TODO!</>;

		// return <>{renovationRabbet && <>
		// 	<tr>
		// 		<td className='fit-content'>{label} afmeting</td>
		// 		<td>
		// 			<Mm value={renovationRabbet.width} />x<Mm
		// 			value={renovationRabbet.depth} />
		// 		</td>
		// 	</tr>
		// 	{renovationRabbet.groef && <>
		// 		<tr>
		// 			<td className='fit-content'>{label} groef breedte/diepte</td>
		// 			<td>
		// 				<Mm value={renovationRabbet.groef.position} />x
		// 			</td>
		// 		</tr>
		//
		// 		<tr>
		// 			<td className='fit-content'>{label} groef breedte/diepte</td>
		// 			<td>
		// 				<Mm value={renovationRabbet.groef.width} /> x <Mm
		// 				value={renovationRabbet.groef.depth} /> <small
		// 				className='text-muted'>BxD</small>
		// 			</td>
		// 		</tr>
		// 	</>
		// 	}
		// </>
		// }
		// </>;
	};

	return (
		<>
			{wallConnection.renovationRabbetInside && renderRenovationRabbet(wallConnection.renovationRabbetInside, 'Renovatiesponning binnen')}

			{/*{wallConnection.kalkSponning &&*/}
			{/*    <>*/}
			{/*        <tr>*/}
			{/*            <td className='fit-content'>Kalksponning positie</td>*/}
			{/*            <td>*/}
			{/*                <Mm value={wallConnection.kalkSponning.position}/> <small className='text-muted'>vanuit de*/}
			{/*                binnenkant</small>*/}
			{/*            </td>*/}
			{/*        </tr>*/}
			{/*        <tr>*/}
			{/*            <td className='fit-content'>Kalksponning breedte/diepte</td>*/}
			{/*            <td>*/}
			{/*                <Mm value={wallConnection.kalkSponning.width}/> x <Mm*/}
			{/*                value={wallConnection.kalkSponning.depth}/>*/}
			{/*            </td>*/}
			{/*        </tr>*/}
			{/*    </>*/}
			{/*}*/}

			{wallConnection.renovationRabbetOutside && renderRenovationRabbet(wallConnection.renovationRabbetOutside, 'Renovatiesponning buiten')}
		</>
	);
};
