import {PageTitle} from '../../../_metronic/layout/core';
import React from 'react';
import {CustomerPreferencesOverview} from '../../shared/components/preferences/CustomerPreferencesOverview';

export function Preferences() {
	return (
		<>
			<PageTitle>Dorpelvoorkeuren</PageTitle>
			<CustomerPreferencesOverview customerId={undefined} />
		</>
	);
}
