import { useQueryClient } from "@tanstack/react-query";
import { NotFound, ValidationFailed } from "../../../../modules/api-client/Responses";
import { FormValues } from "../pages/newsletter/EmailInsert";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";

export const useEmailInsertMutation = () => {

    const queryClient = useQueryClient()

    return useEkosietMutation<void, NotFound | ValidationFailed, FormValues>((values) => {
            return ApiClient.Crm.Newsletter.crmNewsletterSubscribePost(values.email).then((response: any) => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['newsletterSubscribersListGet']).then();
            }
        }
    );
};