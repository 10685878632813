import {MenuItem} from './MenuItem'
import {useEkosietAuth0} from '../../../../../app/modules/auth0/core/useEkosietAuth0'
import {CustomerRoutePath} from "../../../../../app/customer-portal/routing/RoutePath";
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import {BackofficeRoutePath} from "../../../../../app/backoffice/RoutePath";
import { PmRoutePath } from '../../../../../app/backoffice/modules/pm/PmRoutes';
import {SmRoutePath} from "../../../../../app/backoffice/modules/sm/SmRoutes";
import {
    NewOrderCustomerForm
} from "../../../../../app/backoffice/modules/sm/orders/new-order/components/NewOrderCustomerForm";
import {Form, Formik, FormikValues} from "formik";
import {CustomerPicker} from "../../../../../app/backoffice/modules/crm/components/CustomerPicker";
import React from "react";
import * as Yup from "yup";
import {OrderPicker} from "../../../../../app/backoffice/modules/sm/components/OrderPicker";
import {EmRoutePath} from "../../../../../app/backoffice/modules/em/EmRoutes";
import {NeutPicker} from "../../../../../app/backoffice/modules/pim/components/NeutPicker";
import TextField from "../../../../../app/shared/components/TextField";
type IFormValues = {
    customerId: string
}

const initialFormValues: IFormValues = {
    customerId: ''
}
const formSchema = Yup.object().shape({
    customerId: Yup.string().required('Klant is verplicht.')
});
type INeutFormValue = {
    neutCode: string
}
type IOrderFormValues = {
    orderId: string
}
const initialOrderFormValues: IOrderFormValues = {
    orderId: ''
}
const initialNeutFormValues: INeutFormValue = {
    neutCode: ''
}
const orderFormSchema = Yup.object().shape({
    orderId: Yup.string().required('Order is verplicht.')
});
const neutFormSchema = Yup.object().shape({
    neutCode: Yup.string().required('Neutcode is verplicht.')
});



export function MenuInner() {
    const {isProductieMedewerker, isMedewerker,isTransportMedewerker,isExpeditieMedewerker, isKlant} = useEkosietAuth0();

    const navigate = useNavigate();

    const submitForm = (values: IFormValues, actions: FormikValues) => {
        navigate(SmRoutePath.link(SmRoutePath.orderAddWithCustomerIdLink(values.customerId)));
    }
    const submitOrderForm = (values: IOrderFormValues, actions: FormikValues) => {
        if(isExpeditieMedewerker) {
            navigate(EmRoutePath.link(EmRoutePath.expeditionLink(values.orderId)));
        } else if(isMedewerker) {
            navigate(SmRoutePath.link(SmRoutePath.orderEditLink(values.orderId)));
        }
    }

    const submitNeutForm = (values: INeutFormValue, actions: FormikValues) => {

        console.log(PmRoutePath.link(PmRoutePath.neutDetailLink(values.neutCode)));
            navigate(PmRoutePath.link(PmRoutePath.neutDetailLink(values.neutCode)));

    }
    return (
        <>

            {(isProductieMedewerker &&
                <>

                    <div className='menu-item me-lg-1'>
                        <Link to={PmRoutePath.link(PmRoutePath.stationsDashboard)}
                              className={clsx('btn btn-primary py-3')}>
                            Dashboard
                        </Link>
                    </div>
                    {/*<div className='menu-item me-lg-1'>*/}
                    {/*    <Link to={PmRoutePath.link(PmRoutePath.productionBatchStationList)}*/}
                    {/*          className={clsx('btn btn-primary py-3')}>*/}
                    {/*        Open batches*/}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    <div className='menu-item me-lg-1'>
                        <Link to={PmRoutePath.link(PmRoutePath.productionItemOperationDetail)}
                              className={clsx('btn btn-primary py-3')}>
                            Dorpel details
                        </Link>
                    </div>
                </>
            )}

            {(isKlant && !isProductieMedewerker && !isTransportMedewerker && !isExpeditieMedewerker &&
                <>
                    <div className='menu-item me-lg-1'>
                        <Link to={CustomerRoutePath.link(CustomerRoutePath.orderStart)}
                              className={clsx('btn btn-primary py-3')}>
                            Dorpel(s) bestellen
                        </Link>
                    </div>
                </>
            )}
            
            {(isMedewerker && !isProductieMedewerker && !isTransportMedewerker && !isExpeditieMedewerker &&
                <>
                    <Formik initialValues={initialFormValues} onSubmit={submitForm} validationSchema={formSchema}  >
                        {({
                              values,
                              handleSubmit,
                              isSubmitting,
                          }) => (

                            <Form className='d-flex align-items-center me-3 mt-3' noValidate>
                                <div className="input-group mb-3">
                                    <CustomerPicker fieldName='customerId' value={values.customerId} />
                                    <button
                                        type='submit'
                                        className='btn btn-sm btn-primary'>
                                        <span className='indicator-label d-none d-xl-inline'>Nieuwe bestelling</span>
                                        <span className='indicator-label d-xl-none'><i className={'fas fa-plus pe-0'}></i></span>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            {(isMedewerker && !isProductieMedewerker && !isTransportMedewerker &&
                <>
                    <Formik initialValues={initialOrderFormValues} onSubmit={submitOrderForm} validationSchema={orderFormSchema}  >
                        {({
                              values,
                              handleSubmit,
                              isSubmitting,
                          }) => (

                            <Form className='d-flex align-items-center mt-3' noValidate>
                                <div className="input-group mb-3">
                                    <OrderPicker fieldName='orderId' value={values.orderId}/>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
            {(isMedewerker  &&
                <>
                    <Formik initialValues={initialNeutFormValues} onSubmit={submitNeutForm}  >
                        {({

                          }) => (

                            <Form className='d-flex align-items-center ms-2 mt-3' >
                                <div className="input-group mb-3">
                                    <TextField name='neutCode' placeholder={'Neutcode...'} maxLength={4} minLength={4} onFocus={(e:React.FocusEvent<HTMLInputElement>)=>e.target.select()}/>
                                    <button
                                        type='submit'
                                        className='btn btn-sm btn-primary'>
                                        <span className='indicator-label d-none d-xl-inline'>Zoek</span>
                                        <span className='indicator-label d-xl-none'><i className={'fas fa-search pe-0'}></i></span>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            )}
        </>
    )
}
