import {SillDetails} from '../../../../../modules/api-client/generated';
import ApiClient from '../../../../../modules/api-client/ApiClient';
import {useQuery} from '@tanstack/react-query';

export const useSillQuery = (sillId: string | undefined) => {
	const queryKey = ['ApiClient.Pim.Sill.getSill', sillId];

	return useQuery<SillDetails>({
		queryKey: queryKey,
		queryFn: async () => {
			const res = await ApiClient.Pim.Sill.getSill(sillId!);
			return res.data;
		},
		enabled: !!sillId,
	});
};
