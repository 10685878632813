/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {	
	HefschuifDoorPositionMissingImportValidation,
	HefSchuifInnerDoorPosition,	
	IgnoreImportValidationCommand,	
	ResolveHefschuifDoorPositionMissingImportValidationCommand,
	ResolveImportValidationCommandType,
} from '../../../../modules/api-client/generated';
import {ValidationResolverProps} from '../../pages/ValidationResolverPage';
import {Form, Formik, FormikHelpers} from 'formik';
import SpinnerButton from '../../../components/SpinnerButton';
import clsx from "clsx";

type FormValues = {InnerDoorPosition: HefSchuifInnerDoorPosition|undefined};

export type InnerDoorPositionResolverProps = Omit<ValidationResolverProps, 'validation'> & {
	validation: HefschuifDoorPositionMissingImportValidation
};

export const InnerDoorPositionResolver = ({show, validation, handleClose, children}: PropsWithChildren<InnerDoorPositionResolverProps>) => {
	var resolveMutation = usePutValidationResolve();

	const initialFormValues: FormValues = {InnerDoorPosition: undefined};

	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveHefschuifDoorPositionMissingImportValidationCommand = {
				commandType: ResolveImportValidationCommandType.ResolveHefschuifDoorPositionMissingImportValidationCommand,
				innerDoorPosition: values.InnerDoorPosition!,
				id: validation.id,
				importType: validation.type,
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const ignore = async () => {

		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};

		await resolveMutation.mutateAsync(command);

		handleClose();
	};

	return (
		<Formik initialValues={initialFormValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Kies positie binnendeur</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">
							<p className={'lead'}>{validation.message} </p>
							<div className="d-flex my-5 text-start ">
								<div className="form-check form-switch d-flex justify-content-start align-items-center">
									<input
										{...getFieldProps('InnerDoorPosition')}
										className={clsx('form-check-input')}
										type="radio"
										name="InnerDoorPosition"
										id="innerDoorPositionLeft"
										value={HefSchuifInnerDoorPosition.Left}
										checked={values.InnerDoorPosition == HefSchuifInnerDoorPosition.Left}
										disabled={isSubmitting}
									/>
									<label className="fw-bold ms-4 fs-6 mb-2 form-check-label"
										   htmlFor="innerDoorPosition">
										Links									
									</label>
								</div>
							</div>
							<div className="d-flex my-5 text-start ">
								<div className="form-check form-switch d-flex justify-content-start align-items-center">
									<input
										{...getFieldProps('InnerDoorPosition')}
										className={clsx('form-check-input')}
										type="radio"
										name="InnerDoorPosition"
										id="innerDoorPositionRight"
										value={HefSchuifInnerDoorPosition.Right}
										checked={values.InnerDoorPosition == HefSchuifInnerDoorPosition.Right}
										disabled={isSubmitting}
									/>
									<label className="fw-bold ms-4 fs-6 mb-2 form-check-label"
										   htmlFor="innerDoorPositionRight">
										Rechts									
									</label>
								</div>
							</div>

						</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton 
								type="submit" 
								className="btn btn-primary" 
								spinning={isSubmitting}
								disabled={isSubmitting}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

