import {ModalProps} from 'react-bootstrap/Modal';
import {modalsRoot} from '../../global-helpers';
import {FormikConfig, FormikValues} from 'formik/dist/types';
import {Modal, ModalHeaderProps} from 'react-bootstrap';
import {FC, ReactNode, useRef} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import SpinnerButtonV2 from './SpinnerButtonV2';
import {ValidationFailed} from '../../modules/api-client/Responses';

const defaultEkoFormModalProps: ModalProps = {
	backdrop: 'static',
	animation: false,
	autoFocus: true,
	container: modalsRoot,
};

export type EkoModalProps<TValue = any> = {
	title: string;
	isOpen: boolean;
	onClose: () => void;
	// onConfirm?: (value?: TValue) => Promise<void>;
	onCancel?: () => Promise<void>;
	modal?: Omit<ModalProps, 'show' | 'onHide'>;
	modalHeader?: ModalHeaderProps;
	footer?: boolean | (() => ReactNode) | Omit<DefaultEkoModalFooterProps, 'onConfirm' | 'onCancel'>;
	children?: ReactNode;
};

export type EkoFormModalProps<TValues extends FormikValues = FormikValues> = Omit<EkoModalProps<TValues>, 'onConfirm'> & {
	values: TValues;
	onConfirm?: (value: TValues) => Promise<void>;
	formikConfig?: Omit<FormikConfig<TValues>, 'initialValues' | 'onSubmit'>;
};

export const EkoFormModal: FC<EkoFormModalProps> = (props) => {
	const formikRef = useRef<FormikProps<FormikValues> | null>(null);

	const onCancel = async () => {
		if (props.onCancel) {
			await props.onCancel();
		}
		props.onClose();
	};

	const onSubmit = async (values: FormikValues) => {
		try {
			if (props.onConfirm) {
				await props.onConfirm(values);
			}
		} catch (e) {
			if (e instanceof ValidationFailed) {
				formikRef.current?.setErrors(e.errors);
			}

			throw e;
		}
		props.onClose();
	};

	const modalProps: ModalProps = {
		...defaultEkoFormModalProps,
		...props.modal,
		show: props.isOpen,
		onHide: props.onClose,
	};

	const modalHeaderProps: ModalHeaderProps = {...props.modalHeader};

	const formikConfig: FormikConfig<FormikValues> = {
		...props.formikConfig,
		initialValues: props.values ?? {},
		onSubmit: onSubmit,
	};

	const renderContent = (formikProps: FormikProps<FormikValues>) => {
		const {isSubmitting, isValidating, submitForm} = formikProps;

		const footer =
			typeof props.footer === 'function' ? (
				props.footer()
			) : typeof props.footer === 'object' ? (
				<DefaultEkoFormModalFooter
					{...props.footer}
					onConfirm={submitForm}
					onCancel={onCancel}
					confirmBtnDisabled={isSubmitting || isValidating}
					cancelBtnDisabled={isSubmitting || isValidating}
				/>
			) : props.footer === false ? null : (
				<DefaultEkoFormModalFooter onConfirm={submitForm} onCancel={onCancel} confirmBtnDisabled={isSubmitting || isValidating} cancelBtnDisabled={isSubmitting || isValidating} />
			);

		return (
			<>
				<Modal.Header {...modalHeaderProps}>
					<Modal.Title>{props.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{props.children}</Modal.Body>
				{footer}
			</>
		);
	};

	const renderFormik = () => {
		return (
			<Formik innerRef={formikRef} {...formikConfig!}>
				{(formik) => <Form>{renderContent(formik)}</Form>}
			</Formik>
		);
	};

	return <Modal {...modalProps}>{renderFormik()}</Modal>;
	// return <Modal {...modalProps}>{formikConfig ? renderFormik() : renderContent()}</Modal>;
};

export type DefaultEkoModalFooterProps = {
	onConfirm: () => Promise<void> | void;
	onCancel: () => Promise<void> | void;
	confirmBtnText?: string;
	confirmBtnDisabled?: boolean;
	cancelBtnText?: string;
	cancelBtnDisabled?: boolean;
};

export const DefaultEkoFormModalFooter: FC<DefaultEkoModalFooterProps> = (props) => {
	return (
		<Modal.Footer className="d-flex justify-content-end">
			<div>
				<SpinnerButtonV2 type="button" className="btn btn-link mx-4" onClick={props.onCancel} disabled={props.cancelBtnDisabled ?? false}>
					{props.cancelBtnText ?? 'Annuleren'}
				</SpinnerButtonV2>
				<SpinnerButtonV2 type="submit" className="btn btn-primary" disabled={props.confirmBtnDisabled ?? false} onClick={props.onConfirm}>
					{props.cancelBtnText ?? 'Opslaan'}
				</SpinnerButtonV2>
			</div>
		</Modal.Footer>
	);
};
