import React, { useLayoutEffect } from "react";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import SubmitButton from "../../../../shared/components/SubmitButton";
import { ValidationErrors } from "../../../../modules/api-client/Responses";
import TextField from "../../../../shared/components/TextField";
import { useCompartmentLayoutTypeQuery, useCompartmentLayoutTypeUpsertMutation } from "./hooks";
import { CompartmentLayoutTypeCode, FileCategory, RabbetPosition } from "../../../../modules/api-client/generated";
import Loading from "../../../../shared/components/Loading";
import { SluitpotBeslagPicker } from "../sluitpotbeslag/SluitpotBeslagPicker";
import { RabbetPositionPicker } from "../RabbetPositionPicker";
import { FileUpload } from "../../../../shared/components/file-upload/FileUpload";

export interface FormValues {
    code: CompartmentLayoutTypeCode;
    name: string;
    rabbetPosition: RabbetPosition;
    sluitpotBeslagIds: string[];
    imageId?: string;
    blobUri?: string;
}

type Props = {
    handleClose: () => void,
    id?: string,
}

const CompartmentLayoutTypeUpsert: React.FC<Props> = ({ handleClose, id }) => {

    const { mutate, serverValidationErrors } = useCompartmentLayoutTypeUpsertMutation(id);

    const onSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {

        return new Promise<void>((resolve, reject) => {
            mutate(values, {
                onSuccess: () => {
                    resolve();
                    handleClose();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    const { data, isInitialLoading, isError } = useCompartmentLayoutTypeQuery(id);
    if (isInitialLoading) return <Loading />
    if (isError) return (<>Error...</>)
    if (id && !data) return (<>'{id}' niet gevonden.</>)

    const initialValues: FormValues = {
        code: data?.code ?? CompartmentLayoutTypeCode.VastglasBinnen,
        name: data?.name ?? '',
        rabbetPosition: data?.rabbetPosition ?? RabbetPosition.Inside,
        sluitpotBeslagIds: data?.sluitpotBeslags.map((b) => b.id) ?? [],
        imageId: data?.imageReference?.id ?? undefined,
        blobUri: data?.imageReference?.blobUri ?? undefined,
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <UpsertForm id={id} serverValidationErrors={serverValidationErrors} />
            </Formik>
        </>

    );
}

interface UpsertFormProps {
    id: string | undefined;
    serverValidationErrors: ValidationErrors | null;
}

export const UpsertForm: React.FC<UpsertFormProps> = (props) => {
    const { values, handleSubmit, isSubmitting, errors, setErrors, setFieldValue } = useFormikContext<FormValues>();
    const mergeServerErrors = () => {

        if (props.serverValidationErrors) {
            setErrors({ ...errors, ...props.serverValidationErrors });
        }
    }
    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);

    return (<form onSubmit={handleSubmit}>

        <TextField row={true} name={'code'} label={'Unieke code'} />

        <TextField row={true} name={'name'} label={'Naam'} />


        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label" htmlFor="type">Sponningspositie</label>
            </div>
            <div className="col-8">
                <RabbetPositionPicker name={'rabbetPosition'} multi={false} />
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label" htmlFor="type">Sluitpotten</label>
            </div>
            <div className="col-8">
                <SluitpotBeslagPicker name={'sluitpotBeslagIds'} multi={true} />
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label" htmlFor="type">Afbeelding</label>
            </div>
            <div className="col-8 position-relative">
                <FileUpload
                    onFileUpload={(fileId) => setFieldValue('imageId', fileId)}
                    show='drag'
                    fileCategory={FileCategory.CompartmentLayoutType}
                    sasUri={values.blobUri ?? ''}
                    uploadType='image'
                />
            </div>
        </div>

        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                    isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </form>);
}

export default CompartmentLayoutTypeUpsert;