import React, { useCallback, useRef } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiClient from '../../../../modules/api-client/ApiClient';
import { DownloadListItem, FileCategory } from '../../../../modules/api-client/generated';
import Loading from '../../../../shared/components/Loading';
import Error from "../../../../shared/components/Error";
import { FileUpload } from '../../../../shared/components/file-upload/FileUpload';
import { useDownloadDeleteMutation } from './hooks';
import { ConfirmDialog } from '../../../../shared/components/ConfirmDialog';

export const DownloadList: React.FC = () => {
    const { isInitialLoading, data: downloads, isError } = useQuery<DownloadListItem[]>(
        ['fileStorageGetDownloads'],
        () => ApiClient.FileStorage.getDownloads()
            .then(res => res.data));

    const queryClient = useQueryClient();

    if (isInitialLoading) return <Loading />;

    if (isError) return <Error />;

    const getIcon = (fileName: string) => {
        let extension = fileName.split('.').pop();
        switch (extension) {
            case "pdf": {
                return (<i className={'fa-duotone fa-solid fa-file-pdf mb-4'} style={{ fontSize: '500%' }}/>);
            }
             case "xlsx": {
                return (<i className={'fa-duotone fa-solid fa-file-excel mb-4'} style={{ fontSize: '500%' }}/>);
            }
             case "docx": {
                return (<i className={'fa-duotone fa-solid fa-file-word mb-4'} style={{ fontSize: '500%' }}/>);
            }
        }
        return (<i className={'fa-regular fa-file mb-4'} style={{ fontSize: '500%' }}/>);
    }

    return <div className={'col'}>
                <div className={'row mb-4 d-flex align-items-stretch'}>
                    <h4>Downloads</h4>
                    {downloads?.map((d) => (
                        <div className={'col-12 col-md-4 col-xl-2 mb-3'} key={d.id}>
                            <div className={'card h-100 d-flex flex-column'}>
                                <div className={'card-body flex-grow-1'}>
                                    <div className={'d-flex flex-column align-items-end mb-3'}>
                                        <DeleteButton id={d.id}/>
                                    </div>
                                    {getIcon(d.fileName)}
                                    <div className="text-truncate" style={{ maxWidth: '100%' }}>
                                        <h5 className={'card-title mb-4'}>{d.title}</h5>
                                    </div>
                                    <p className="card-text">{d.description}</p>
                                </div>
                                <a className="btn btn-primary px-5 btn-sm mt-auto w-100 rounded-top-0" href={d.blobUri?.toString()} download target="_blank">Download</a>
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{position: 'sticky', bottom: '20px' }}>
                    <div className='position-relative'>
                        <FileUpload 
                            fileCategory={FileCategory.Download}
                            onFileUpload={() => queryClient.invalidateQueries(['fileStorageGetDownloads'])}
                            show='drag'
                            uploadType='other'
                        />
                    </div>
                </div>
            </div>;
};

const DeleteButton: React.FC<{id:string}>= ({id}) => {
    const openDeleteDialog = useRef<(show: boolean) => void>();

    const deleteMutation = useDownloadDeleteMutation(id);
    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(id!);
    }, [deleteMutation, id]);
    return (
        <>
            <button type="button" className="text-danger btn btn-link"
                onClick={() => openDeleteDialog.current?.call(this, true)}>Verwijderen
            </button>
            <ConfirmDialog show={openDeleteDialog}
                           onConfirm={deleteCallback}
                           dialogStyle="danger"
                           title="Verwijderen"
                           message="Wil je doorgaan?"
                           confirmText="Definitief verwijderen"
            />
        </>
    )
}