/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../modules/api-client/ApiClient";
import {Link} from "react-router-dom";
import {KTSVG} from "../../../_metronic/helpers";
import {EkoTable, TableHeader} from "../../shared/components/table";
import {OrderStatus} from "../../backoffice/modules/sm/orders/edit-order/components/OrderStatus";
import {CustomerRoutePath} from "../routing/RoutePath";
import {formatDate} from "../../shared/components/date";
import {OrderListView, OrderListViewArrayODataValue, OrderStatusCodes} from "../../modules/api-client/generated";
import Loading from "../../shared/components/Loading";


type Props = {
    items?: number
    customerId?: string
}

const OrderTable: React.FC<Props> = ({items = 4, customerId}) => {
    const {
        isLoading,
        data: orderlist,
        isError
    } = useQuery<OrderListViewArrayODataValue, TypeError, OrderListViewArrayODataValue>([], () => ApiClient.Sm.Order.smOrderListOdataGet().then((res) => res.data));

    if (isLoading) {
        return <Loading/>;
    }
    if (isError) {
        return <>ERROR!</>;
    }
    if (!orderlist) return <></>;

    return (
        <EkoTable className={'card-xxl-stretch mb-5 mb-xxl-8'}>
            <TableHeader>
                <th className='min-w-40px'>Ordernmr.</th>
                <th className='min-w-140px'> Uw referentie</th>
                <th className='min-w-50px text-center'>Geplande leverdatum</th>
                <th className='min-w-50px '>Items</th>
                <th className='min-w-50px text-end'>Totaal</th>
                <th className='min-w-50px text-center'>Status</th>
                <th className='min-w-50px text-end'>Acties</th>
            </TableHeader>
            <tbody>
            {orderlist && orderlist.value.map((order) => <tr key={order.id}>

                <td>

                    {order.status == OrderStatusCodes.Draft ?
                        <Link to={CustomerRoutePath.link(CustomerRoutePath.orderWizardLink(order.id!))} relative="path"
                              className='text-hover-primary fs-6'>
                            {order.code}
                        </Link>
                        :

                        <Link to={CustomerRoutePath.link(CustomerRoutePath.orderLink(order.id!))} relative="path"
                              className='text-hover-primary fs-6'>
                            {order.code}
                        </Link>
                    }
                    <div
                        className={'text-muted'}>{order.orderDrafted ? formatDate(order.orderDrafted, "dd-MM-yyyy HH:mm:ss") : 'Onbekend'}</div>
                    <div className={'text-muted'}>{order.userName}</div>
                </td>
                <td>
                    <div
                        className=' fs-6'>
                        {order.reference}
                    </div>

                    <span className={'text-muted'}>Gewenste leverdatum:
                        {
                            order.preferredExpeditionDate
                                    ? <span
                                        className={'badge badge-light-primary'}>{formatDate(order.preferredExpeditionDate)}</span>
                                    : <span className={'badge badge-light'}>Zo snel mogelijk</span>
                            }</span>
                    </td>
                    <td className="text-center">
                        <div
                            className=''>


                            {
                                order.plannedExpeditionDate
                                    ?
                                    <span className={'badge badge-primary'}>{formatDate(order.plannedExpeditionDate)}</span>
                                    : <span className={'badge badge-light'}>Nog niet bekend</span>
                            }
                        </div>
                    </td>
                    <td>
                        <span className='text-nowrap'>
                            {order.totalProducts} item(s)
                        </span>
                    </td>
                    <td className='text-dark fw-bold fs-6 text-end'>
                        €{order.totalPrice}
                    </td>
                    <td className="text-center">
                        <OrderStatus status={order.status}/>
                    </td>
                    <td className='text-end'>
                        {order.status == OrderStatusCodes.Draft ?
                            <Link to={CustomerRoutePath.link(CustomerRoutePath.orderWizardLink(order.id!))} relative="path"
                                  className='text-hover-primary fs-6'>
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3'/>

                            </Link>
                            :
                            <Link to={CustomerRoutePath.link(CustomerRoutePath.orderLink(order.id!))} relative="path"
                                  className='text-hover-primary fs-6'>
                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3'/>

                            </Link>
                        }

                    </td>
                </tr>
            )}
            </tbody>
        </EkoTable>

    )
}

export {OrderTable}
