import React from 'react';
import {HefSchuifSlidingRailType} from '../../../modules/api-client/generated';

export interface HefSchuifSlidingRailTypeFieldProps {
	value: HefSchuifSlidingRailType | undefined;
}

export const HefSchuifSlidingRailTypeText: React.FC<HefSchuifSlidingRailTypeFieldProps> = (props) => {
	switch (props.value) {
		case HefSchuifSlidingRailType.Laag:
			return <>Lage looprail</>;
		case HefSchuifSlidingRailType.Hoog:
			return <>Hoge looprail</>;
		default:
			return <>Geen looprail</>;
	}
};
