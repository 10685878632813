import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {
    ConfiguredSill, ProductionSillRepresentation,
} from '../../modules/api-client/generated';
import ApiClient from '../../modules/api-client/ApiClient';
import Loading from './Loading';
import ConfiguredSillSvg from './ConfiguredSillSvg';
import {DestructCompartment} from '../../backoffice/modules/core';
import Error from './Error';
import ConfiguredNeutSvg from "./ConfiguredNeutSvg";
import configuredNeutSvg from "./ConfiguredNeutSvg";
import {ProductionNeutSpecification} from "./ProductionNeutSpecification";
import {useGetProductionSill} from "../../backoffice/modules/pm/hooks/use-get-production-sill";
import {useProductionItemGetNeuten} from "../../backoffice/modules/pm/hooks/use-get-production-item-neuten";
import BooleanBadge from "./BooleanBadge";
import {EkoCard, EkoCardBody} from "./card";
import {Link} from "react-router-dom";
import {PmRoutePath} from "../../backoffice/modules/pm/PmRoutes";
import {Mm} from "./Mm";

export interface ProductionSillSpecificationProps {
    catalogItemId?: string;
}

const ProductionSillSpecification: React.FC<ProductionSillSpecificationProps> = (props) => {

    const {
        isInitialLoading,
        isLoading,
        data: sill,
        isError: isProductionSillError,
    } = useGetProductionSill(props.catalogItemId)
    if (isLoading || isInitialLoading) {
        return <Loading message={'Dorpelspecificatie ophalen....'}/>;
    }
    if (isProductionSillError) return <Error/>;


    if (!sill) return <></>;

    return <ProductionSillSpecificationDetails sill={sill}/>;
};

interface ProductionSillSpecificationDetails {
    sill: ProductionSillRepresentation;
}

export const ProductionSillSpecificationDetails: React.FC<ProductionSillSpecificationDetails> = (props) => {

        const sill = props.sill;

        return (
            <>
                <ConfiguredSillSvg onNeutClick={() => {
                }} svg={sill.svg} svgHeight={'200px'}/>

                <div className={'row mt-3'}>
                    <div className={'col-6 col-xl-9'}>
                        <div className={'row'}>
                            {sill.neuten?.map((neut, i) => {
                                return (
                                    <div key={i} className={'col-6 col-md-4 col-lg-3 col-xl-3'}>
                                        <EkoCard className={'border mb-5'}>
                                            <EkoCardBody>
                                                <Link to={PmRoutePath.link(PmRoutePath.neutDetailLink(neut.code))}>
                                                    <div className={'text-center'}>
                                                        <h1>{neut.code}</h1>
                                                        {neut.parts.length > 1 && <span
                                                            className={'fw-bold'}>{neut.parts.length} onderdelen</span>}
                                                        <ConfiguredNeutSvg svg={neut.svg} svgHeight={'200px'}/>
                                                    </div>
                                                </Link>
                                            </EkoCardBody>
                                        </EkoCard>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={'col'}>
                        <EkoCard className={'border'}>
                            <EkoCardBody>
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between">Dorpel <span
                                        className={'fw-bold'}>{sill.model.code} </span></li>
                                    <li className="list-group-item d-flex justify-content-between">Beschermkappen <span
                                        className={'fw-bold'}>{sill.numberOfProtectors} </span></li>
                                    <li className="list-group-item d-flex justify-content-between">Inpakken <BooleanBadge
                                        value={sill.wrap}/></li>
                                    <li className="list-group-item d-flex justify-content-between">Lengte <span
                                        className={'fw-bold'}><Mm value={sill.totalLength}/></span></li>
                                </ul>
                            </EkoCardBody>
                        </EkoCard>
                    </div>
                </div>


                {/*<div className={'row'}>*/}
                {/*	{sill.compartments.map((compartment, i) => {*/}
                {/*        return (*/}
                {/*			<div className={'col-6 col-md-6 col-lg-6 col-xl-3 col-xxl-3'}>*/}
                {/*				/!*<EkoCard className={'border mb-5'}>*!/*/}
                {/*				/!*	<EkoCardBody>*!/*/}
                {/*				/!*		<h5 className="card-title">Vak {i + 1} {compartment.layoutType}</h5>*!/*/}
                {/*				/!*		<ul className="list-group list-group-flush">*!/*/}
                {/*				/!*			<li className="list-group-item d-flex justify-content-between">Vakindeling <span*!/*/}
                {/*				/!*				className={'fw-bold'}>{compartment.layoutType}</span></li>*!/*/}
                {/*                */}
                {/*				/!*		</ul>*!/*/}
                {/*				/!*	</EkoCardBody>*!/*/}
                {/*				/!*</EkoCard>*!/*/}
                {/*			</div>*/}
                {/*		);*/}
                {/*	})}*/}
                {/*</div>*/}
                <div className={'row'}>


                    {sill.compartments.map((compartment, i) => {
                        //
                        // const {
                        // 	glazingBar,
                        // 	glazingProfile,
                        // 	lProfile,
                        // 	stopProfile,
                        // 	aerationHoles,
                        // 	drainageHoles,
                        // 	sluitpotten,
                        // } = DestructCompartment(compartment);


                        return (
                            <div key={i} className={'col-3'}>

                                {/*<div className={'d-flex'}>*/}
                                {/*	<span><strong>Vak {i + 1}: {compartment.layoutType}</strong></span>*/}
                                {/*</div>*/}
                                {/*<span>Dagmaat: <span>{compartment.dagmaat} mm</span></span><br />*/}
                                {/*{compartment.protector &&*/}
                                {/*	<>*/}
                                {/*		<span>Beschermkap meeleveren!</span><br />*/}
                                {/*	</>*/}
                                {/*}*/}
                                {/*{aerationHoles?.length! > 0 &&*/}
                                {/*	<>*/}
                                {/*		<span>Beluchtingsgaten: ja</span><br />*/}
                                {/*	</>*/}
                                {/*}*/}
                                {/*{drainageHoles?.length! > 0 &&*/}
                                {/*	<>*/}
                                {/*		<span>Afwateringsgaten: ja</span><br />*/}
                                {/*	</>*/}
                                {/*}*/}
                                {/*{glazingBar &&*/}
                                {/*	<>*/}
                                {/*		<span>Glaslat {glazingBar.width}x{glazingBar.height}mm: <span>{glazingBar.length}mm  </span></span><br />*/}
                                {/*		<span>Hoekstukjes? {glazingBar.cornerPieceLeft?.x ? 'ja' : 'nee'}*/}
                                {/*            </span><br />*/}
                                {/*	</>*/}
                                {/*}*/}
                                {/*{glazingProfile &&*/}
                                {/*	<>*/}
                                {/*		<span>Beglazingsprofiel {glazingProfile.width}x{glazingProfile.height}mm: <span>{glazingProfile.length}mm  </span></span><br />*/}
                                {/*	</>*/}
                                {/*}*/}
                                {/*{lProfile &&*/}
                                {/*	<>*/}
                                {/*		<span>L-profiel {lProfile.width}x{lProfile.height}mm: <span>{lProfile.length}mm  </span></span><br />*/}
                                {/*	</>*/}
                                {/*}*/}
                                {/*{stopProfile &&*/}
                                {/*	<>*/}
                                {/*		<span>Aanslagprofiel {stopProfile.width}x{stopProfile.height}mm: <span>{stopProfile.length}mm  </span></span><br />*/}
                                {/*	</>*/}
                                {/*}*/}
                                {/*{sluitpotten?.map((sluitpot, i) => {*/}
                                {/*	return (*/}
                                {/*		<React.Fragment key={i}>*/}
                                {/*			<span>Sluitpot: <span>{sluitpot.sluitpotHardware?.code}</span></span><br />*/}
                                {/*			<span>Beslag meeleveren? <span>{sluitpot.includeHardware ? 'ja' : 'nee'}</span></span><br />*/}
                                {/*		</React.Fragment>*/}
                                {/*	);*/}
                                {/*})}*/}
                            </div>

                        );
                    })}
                </div>
                {/*<div className={'row mb-4'}>*/}
                {/*	<div className={'col-3 px-1'}>*/}
                {/*		/!*<SillContourSvg profile={sill.model.contour} />*!/*/}
                {/*		<img className="img-fluid"*/}
                {/*			 src={`https://bestellen.ekosiet.nl/media/dorpels/${sill.model.code}.png`}/>*/}
                {/*	</div>*/}
                {/*	<div className={'col-3'}>*/}
                {/*		<strong>{sill.model.code}</strong> <br/>*/}
                {/*		Breedte: {sill.model.width} mm <br/>*/}
                {/*		Lengte: {sill.totalLength} mm <br/>*/}
                {/*		{sill.compartments.length} vakken / {sill.neuten?.length ?? 0} neuten <br/>*/}
                {/*	</div>*/}
                {/*	<div className={'col-3'}>*/}
                {/*		Inpakken: {sill.wrap ? 'Ja' : 'Nee'} <br/>*/}
                {/*		Beschermkappen meeleveren: {sill.numberOfProtectors} <br/>*/}
                {/*	</div>*/}
                {/*</div>*/}
            </>
        )
            ;

    }
;

export default ProductionSillSpecification;

