import React from 'react';
import {useParams} from 'react-router-dom';
import {CustomerPreferencesOverview} from '../../../../../shared/components/preferences/CustomerPreferencesOverview';

export function CustomerPreferences() {

	const {id} = useParams<{id: string}>();

	return <CustomerPreferencesOverview customerId={id} />;
}
