import {useQueryClient} from '@tanstack/react-query';
import {useEkosietMutation} from '../../../../../shared/hooks/useEkosietMutation';
import {UpdateSillVariantsRequest} from '../../../../../modules/api-client/generated';
import {NotFound, ValidationFailed} from '../../../../../modules/api-client/Responses';
import ApiClient from '../../../../../modules/api-client/ApiClient';

export const useSillUpdateVariantsMutation = (sillId: string) => {
	const queryClient = useQueryClient();

	return useEkosietMutation<void, NotFound | ValidationFailed, UpdateSillVariantsRequest>(
		async (body: UpdateSillVariantsRequest) => {
			await ApiClient.Pim.Sill.putVariants(sillId, undefined, body);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(['ApiClient.Pim.Sill.getSill', sillId]).then();
			},
		}
	);
};
