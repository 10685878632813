/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, {PropsWithChildren} from 'react';
import {usePutValidationResolve} from '../../hooks/use-put-validation-resolve';
import {modalsRoot} from '../../../../global-helpers';
import {Button, Modal} from 'react-bootstrap';
import {
	GroeneveldHefSchuifSchemaLayoutImportValidation,
	HefschuifFixedDoorCutImportValidation,
	HefschuifFixedDoorMissingImportValidation,
	HefSchuifSlidingDirection,
	HefschuifSlidingDirectionMissingImportValidation,
	HefschuifSlidingRailCenterImportValidation,
	IgnoreImportValidationCommand,
	ResolveHefschuifFixedDoorCutImportValidationCommand,
	ResolveHefschuifFixedDoorMissingImportValidationCommand,
	ResolveHefSchuifSchemaLayoutImportValidationCommand,
	ResolveHefschuifSlidingDirectionMissingImportValidationCommand,
	ResolveHefschuifSlidingRailCenterImportValidationCommand,
	ResolveImportValidationCommandType,
} from '../../../../modules/api-client/generated';
import {ValidationResolverProps} from '../../pages/ValidationResolverPage';
import {Form, Formik, FormikHelpers} from 'formik';
import SpinnerButton from '../../../components/SpinnerButton';
import {
	HefSchuifSchemaPickerField
} from "../../../../backoffice/modules/pim/compartment-layout-type/HefSchuifSchemaPicker";
import TextField from "../../../components/TextField";
import clsx from "clsx";

type FormValues = {
	center: number
};
export type SlidingRailCenterMissingResolverProps = Omit<ValidationResolverProps, 'validation'> & {
	validation: HefschuifSlidingRailCenterImportValidation
};
export const SlidingRailCenterMissingResolver = ({show, validation, handleClose, children}: PropsWithChildren<SlidingRailCenterMissingResolverProps>) => {
	var resolveMutation = usePutValidationResolve();

	const initialFormValues: FormValues = {
		center: 0
	};
	const resolve = (values: FormValues, {setSubmitting}: FormikHelpers<FormValues>) => {
		return new Promise<void>((resolve, reject) => {
			var command: ResolveHefschuifSlidingRailCenterImportValidationCommand = {
				commandType: ResolveImportValidationCommandType.ResolveHefschuifSlidingRailCenterImportValidationCommand,
				center: values.center,
				id: validation.id,
				importType: validation.type,
			};
			resolveMutation.mutate(command, {
				onSuccess: (model) => {
					resolve();
					handleClose();
				},
				onError: () => {
					reject();
				},
			});
		}).finally(() => setSubmitting(false));
	};

	const ignore = async () => {

		var command: IgnoreImportValidationCommand = {
			commandType: ResolveImportValidationCommandType.IgnoreImportValidationCommand,
			id: validation.id,
			importType: validation.type,
		};

		await resolveMutation.mutateAsync(command);

		handleClose();
	};

	return (
		<Formik initialValues={initialFormValues} onSubmit={resolve}>
			{({values, getFieldProps, isSubmitting}) => (
				<Modal
					container={modalsRoot}
					tabIndex={-1}
					aria-hidden="true"
					autoFocus={true}
					className=""
					dialogClassName={'modal-dialog-centered'}
					contentClassName={'shadow-lg'}
					show={show}
					size="lg"
					onHide={handleClose}
					animation={false}
					backdrop={'static'}
				>
					<Form>
						<Modal.Header closeButton>
							<Modal.Title>Kies een schuifrichting</Modal.Title>
						</Modal.Header>

						<Modal.Body className="flex-fill">
							<p className={'lead'}>{validation.message} </p>

							<TextField row={true} type='number' name={'center'} label={'Hart van de schuifrail'}
									   help={'Geef de positie van het hart van de schuifrail op. Gezien vanuit binnenzijde van de dorpel.'} />

						</Modal.Body>

						<Modal.Footer>
							<Button variant="link" className="mx-4" onClick={handleClose}>
								Sluit
							</Button>
							<Button
								className="btn-light-danger mx-4"
								onClick={() => {
									ignore().then();
								}}
							>
								Negeer
							</Button>
							<SpinnerButton type="submit" className="btn btn-primary" spinning={isSubmitting}
										   disabled={isSubmitting}>
								Toepassen
							</SpinnerButton>
						</Modal.Footer>
					</Form>
				</Modal>
			)}
		</Formik>
	);
};

