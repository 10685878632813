import {
	AssemblyMethod,
	CompartmentLayoutTypeCode,
	ConfiguratorCompartmentLayoutHefSchuif,
	ConfiguratorCompartmentLayoutHefSchuifA,
	ConfiguratorCompartmentLayoutHefSchuifC,
	ConfiguratorCompartmentLayoutHefSchuifD,
	ConfiguratorCompartmentLayoutHefSchuifE,
	ConfiguratorCompartmentLayoutStandard,
	ConfiguratorMountingOption,
	ConfiguratorNeutPosition,
	HefSchuifSchemaCode,
	NeutDrillHolePositioningStrategyName,
	Vector,
	RabbetPosition,
	SetDefaultCompartmentProfileRequest,
	SillConfiguration,
	SillConfigurationAngledWallConnection,
	SillConfigurationCompartment,
	SillConfigurationHefSchuifLayoutSchemaA,
	SillConfigurationHefSchuifLayoutSchemaC,
	SillConfigurationHefSchuifLayoutSchemaD,
	SillConfigurationHefSchuifLayoutSchemaE,
	SillConfigurationNeut,
	SillConfigurationNeutDrillHole,
	SillConfigurationStandardWallConnection,
	SillType,
	WallConnectionType,
	ZijlichtConfiguration,
} from '../../../../modules/api-client/generated';
import React, {useMemo, useState} from 'react';
import {insideOutsideToLR, insideOutsideToLsRs} from '../CompartmentEditorContextProvider';
import ApiClient from '../../../../modules/api-client/ApiClient';
import {useFormikContext} from 'formik';
import {toast} from 'react-hot-toast';
import {CompartmentEditorOptions} from '../CompartmentEditor';
import {ConfiguratorContext} from '../ConfiguratorContextProvider';
import {Configurator} from '../Configurator';

export type DefaultCompartmentEditorOptions = Omit<CompartmentEditorOptions, 'compartmentIndex' | 'value' | 'onChange'>;

export const useConfigurator = (): Configurator => {
	const {configuratorData: data, customerId} = React.useContext(ConfiguratorContext);

	const {values: configuration, setValues, setFieldValue} = useFormikContext<SillConfiguration | ZijlichtConfiguration>();

	const [selectedCompartmentCount, setSelectedCompartmentCount] = useState<number>(() => {
		return 'compartments' in configuration ? configuration['compartments'].length : null!;
	});

	const changeAssemblyMethod = async (assemblyMethod: AssemblyMethod) => {
		const assemblyOption = data.assemblyOptions[assemblyMethod];

		if (!assemblyOption) return;

		const mountingOptions = assemblyOption.mountingOptions ?? [];

		let mountingOptionId = mountingOptions.find((x) => x.id === configuration.mountingOptionId)?.id ?? '';

		if (!mountingOptionId && mountingOptions.length === 1) {
			mountingOptionId = mountingOptions[0].id;
		}

		await setValues((current) => {
			return {...current, assemblyMethod: assemblyMethod, mountingOptionId: mountingOptionId};
		});
	};

	const compartmentCountOptions: number[] = range(data.compartmentOptions.maxCompartmentCount).map((b) => b);

	const preAssembly = data.assemblyOptions[AssemblyMethod.PreAssembly];
	const postAssembly = data.assemblyOptions[AssemblyMethod.PostAssembly];

	const preAssemblyMountingOptions = (preAssembly?.mountingOptions?.length ?? 0) > 0 ? preAssembly?.mountingOptions : undefined;
	const postAssemblyMountingOptions = (postAssembly?.mountingOptions?.length ?? 0) > 0 ? postAssembly?.mountingOptions : undefined;

	let assemblyOption = configuration.assemblyMethod ? data.assemblyOptions[configuration.assemblyMethod] ?? null : null;

	let mountingOptions: Array<ConfiguratorMountingOption> = assemblyOption?.mountingOptions ?? [];

	const hasNeuten = () => 'neuten' in configuration;

	const setWrap = async (wrap: boolean): Promise<void> => {
		await setValues((current) => {
			return {...current, wrap: wrap};
		});
	};

	const setMountingOption = async (mountingOptionId: string): Promise<void> => {
		await setValues((current) => {
			return {...current, mountingOptionId: mountingOptionId};
		});
	};

	const setInsulated = async (insulated: boolean): Promise<void> => {
		await setValues((current) => {
			let updateKopIsolator = {};

			if ('kopIsolator' in current) {
				updateKopIsolator = {
					kopIsolator: !insulated,
				};
			}

			return {
				...current,
				...updateKopIsolator,
				insulated: insulated,
			};
		});
	};

	const setWallConnectionLeft = async (value: SillConfigurationStandardWallConnection | SillConfigurationAngledWallConnection | undefined, alsoApplyToOppositeSide: boolean): Promise<void> => {
		await setWallConnection(value, false, alsoApplyToOppositeSide);
	};

	const setWallConnectionRight = async (value: SillConfigurationStandardWallConnection | SillConfigurationAngledWallConnection | undefined, alsoApplyToOppositeSide: boolean): Promise<void> => {
		await setWallConnection(value, true, alsoApplyToOppositeSide);
	};

	const setWallConnection = async (
		value: SillConfigurationStandardWallConnection | SillConfigurationAngledWallConnection | undefined,
		right: boolean,
		alsoApplyToOppositeSide: boolean
	): Promise<void> => {
		await setValues((current) => {
			let neuten = 'neuten' in current ? [...current.neuten.map((n) => (n ? {...n} : null))] : undefined;

			if (neuten) {
				if (value && 'angle' in value) {
					const angle = 90 - Math.abs(value.angle);

					const minNeutWidth = Math.ceil(Math.tan((angle * Math.PI) / 180) * data.width);

					const applyMinWidth = (index: number) => {
						if (!neuten![index]) return;

						if (neuten![index] && neuten![index]!.width < minNeutWidth) {
							neuten![index] = {...neuten![index]!, width: minNeutWidth};
						}
					};

					const index = right ? neuten.length - 1 : 0;
					const oppositeIndex = right ? 0 : neuten.length - 1;

					applyMinWidth(index);
					if (alsoApplyToOppositeSide) applyMinWidth(oppositeIndex);
				} else {
					const wc = right ? current.wallConnectionRight : current.wallConnectionLeft;

					if (wc && 'angle' in wc) {
						if (data.neutOptions.standardWidths) {
							const index = right ? neuten.length - 1 : 0;
							const oppositeIndex = right ? 0 : neuten.length - 1;

							const standardWidth = data.neutOptions.standardWidths[0];

							const neut = neuten[index];

							if (neut) {
								const n = neuten[index];

								if (!data.neutOptions.standardWidths.includes(neut.width)) {
									neut.width = standardWidth;
								}
							}

							const neutOppositeSide = neuten[oppositeIndex];
							if (neutOppositeSide && alsoApplyToOppositeSide) {
								if (!data.neutOptions.standardWidths.includes(neutOppositeSide.width)) {
									neutOppositeSide.width = standardWidth;
								}
							}
						}
					}
				}
			}

			value ??= {wallConnectionType: WallConnectionType.Standard};

			const update: SillConfiguration | ZijlichtConfiguration = {
				...current,
				wallConnectionLeft: !right || alsoApplyToOppositeSide ? value : current.wallConnectionLeft,
				wallConnectionRight: right || alsoApplyToOppositeSide ? value : current.wallConnectionRight,
			};

			if ('neuten' in update) {
				update.neuten = neuten! as Array<SillConfigurationNeut>;
			}

			return update;
		});
	};

	const getCompartmentProfileIds = (compartment: SillConfigurationCompartment): {left: string; right: string} => {
		const compartmentLayoutOption = data.compartmentLayoutOptions.find((o) => o.code === compartment.compartmentLayoutTypeCode)!;

		if (compartmentLayoutOption.isHefSchuif) {
			if (!compartment.hefSchuifLayout) return undefined!;

			switch (compartment.hefSchuifLayout.schemaCode) {
				case HefSchuifSchemaCode.A: {
					const l = compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaA;
					return insideOutsideToLsRs(l.slidingDirection, l.profileIdInside, l.profileIdOutside);
				}
				case HefSchuifSchemaCode.C: {
					const l = compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaC;
					return {left: l.profileIdOutside, right: l.profileIdOutside};
				}
				case HefSchuifSchemaCode.D: {
					const l = compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaD;
					return insideOutsideToLR(l.innerDoorPosition, l.profileIdInside, l.profileIdOutside);
				}
				case HefSchuifSchemaCode.E: {
					const l = compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaE;
					return insideOutsideToLsRs(l.slidingDirection, l.profileIdInside, l.profileIdOutside);
				}
				default:
					throw 'Not implemented.';
			}
		} else if (compartmentLayoutOption.isStandard) {
			return {left: compartment.profileId!, right: compartment.profileId!};
		} else throw 'Not implemented.';
	};

	const createCompartmentProto = async (neutPositionId: string, compartmentLayoutTypeCode: CompartmentLayoutTypeCode, width: number | undefined): Promise<SillConfigurationCompartment> => {
		return await ApiClient.Pim.Configurator.getDefaultCompartment(data.sillId, neutPositionId, compartmentLayoutTypeCode, customerId, width).then((res) => res.data);
	};

	const createNeutProto = (height: number): SillConfigurationNeut => {
		return {
			width: 90,
			height: height,
			drillHoles: [],
		};
	};

	const changeCompartmentLayoutType = async (compartmentLayoutTypeCode: CompartmentLayoutTypeCode, width: number): Promise<SillConfigurationCompartment> => {
		if (!('neutPositionId' in configuration)) throw 'Invalid configuration type';

		const {neutPositionId} = configuration;

		return await ApiClient.Pim.Configurator.getDefaultCompartment(data.sillId, neutPositionId!, compartmentLayoutTypeCode, customerId, width).then((res) => res.data);
	};

	const changeNeutPosition = async (neutPositionId: string): Promise<void> => {
		if (!('neutPositionId' in configuration)) return;

		const currentNeutPosition = data.compartmentOptions.neutPositions!.find((np) => np.id === configuration.neutPositionId)!;

		const newNeutPosition = data.compartmentOptions.neutPositions!.find((np) => np.id === neutPositionId)!;

		const allDrillHoles: SillConfigurationNeutDrillHole[][] = await ApiClient.Pim.Configurator.calculateDrillHoles(data.neutOptions.drillHoleOptions!.positioningStrategyName, undefined, {
			...configuration,
			neutPositionId: neutPositionId,
		}).then((res) =>
			res.data.map((points) =>
				points.map((p) => ({
					position: p,
				}))
			)
		);

		// Remove a compartments that are not compatible with the new neut position.
		const updatedCompartments: SillConfigurationCompartment[] = [];
		const updatedNeuten: SillConfigurationNeut[] = [];
		for (let i = 0; i < configuration.compartments.length; i++) {
			let compartment: SillConfigurationCompartment = configuration.compartments[i];

			const compartmentLayout = newNeutPosition.compartmentLayouts.find((cl) => cl.compartmentLayoutTypeCode === compartment.compartmentLayoutTypeCode);

			if (compartmentLayout) {
				const compartmentLayoutOption = data.compartmentLayoutOptions.find((o) => o.code === compartmentLayout.compartmentLayoutTypeCode)!;

				// If the profile width has changed, reset the profile to the default profile of the new neut position.
				if (newNeutPosition.profileWidth !== currentNeutPosition.profileWidth) {
					if (compartmentLayoutOption.isStandard) {
						compartment = {
							...compartment,
							profileId: (compartmentLayout as ConfiguratorCompartmentLayoutStandard).defaultProfileId,
						};
					} else if (compartmentLayoutOption.isHefSchuif) {
						const hsCompartmentLayout = compartmentLayout as ConfiguratorCompartmentLayoutHefSchuif;

						switch (hsCompartmentLayout.schemaCode) {
							case HefSchuifSchemaCode.A: {
								const hsCompartmentLayoutA = hsCompartmentLayout as ConfiguratorCompartmentLayoutHefSchuifA;

								compartment = {
									...compartment,
									profileId: null,
									hefSchuifLayout: {
										...(compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaA),
										profileIdInside: hsCompartmentLayoutA.defaultProfileIdInside,
										profileIdOutside: hsCompartmentLayoutA.defaultProfileIdOutside,
									},
								};
								break;
							}
							case HefSchuifSchemaCode.C: {
								const hsCompartmentLayoutC = hsCompartmentLayout as ConfiguratorCompartmentLayoutHefSchuifC;

								compartment = {
									...compartment,
									profileId: null,
									hefSchuifLayout: {
										...(compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaC),
										profileIdOutside: hsCompartmentLayoutC.defaultProfileIdOutside,
									},
								};
								break;
							}
							case HefSchuifSchemaCode.D: {
								const hsCompartmentLayoutD = hsCompartmentLayout as ConfiguratorCompartmentLayoutHefSchuifD;

								compartment = {
									...compartment,
									profileId: null,
									hefSchuifLayout: {
										...(compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaD),
										profileIdInside: hsCompartmentLayoutD.defaultProfileIdInside,
										profileIdOutside: hsCompartmentLayoutD.defaultProfileIdOutside,
									},
								};
								break;
							}

							case HefSchuifSchemaCode.E: {
								const hsCompartmentLayoutE = hsCompartmentLayout as ConfiguratorCompartmentLayoutHefSchuifE;

								compartment = {
									...compartment,
									profileId: null,
									hefSchuifLayout: {
										...(compartment.hefSchuifLayout as SillConfigurationHefSchuifLayoutSchemaE),
										profileIdInside: hsCompartmentLayoutE.defaultProfileIdInside,
										profileIdOutside: hsCompartmentLayoutE.defaultProfileIdOutside,
									},
								};
								break;
							}
							default:
								continue;
						}
					}
				}

				updatedCompartments.push(compartment);
				updatedNeuten.push({...configuration.neuten[i], drillHoles: allDrillHoles[i]});
			}
		}

		if (updatedCompartments.length === 0) {
			updatedNeuten.push({...configuration.neuten[0], drillHoles: allDrillHoles[0]});

			if (!newNeutPosition.compartmentLayouts) return;

			const defaultCompartmentLayout = newNeutPosition.compartmentLayouts.find((cl) => cl.compartmentLayoutTypeCode === data.defaultCompartmentLayoutTypeCode);

			const compartmentLayout = defaultCompartmentLayout ?? newNeutPosition.compartmentLayouts[0];

			const proto = await createCompartmentProto(newNeutPosition.id, compartmentLayout.compartmentLayoutTypeCode, undefined);

			updatedCompartments.push(proto);
		}

		updatedNeuten.push({
			...configuration.neuten[configuration.neuten.length - 1],
			drillHoles: allDrillHoles[configuration.neuten.length - 1],
		});

		await setValues((current) => {
			return {
				...current,
				neutPositionId: neutPositionId,
				compartments: updatedCompartments,
				neuten: updatedNeuten,
			};
		});
	};

	const getSelectedNeutPosition = (): ConfiguratorNeutPosition => {
		if (!('compartments' in configuration)) throw 'Invalid configuration type';

		const neutPosition = data.compartmentOptions.neutPositions!.find((np) => np.id === configuration.neutPositionId);

		if (!neutPosition) throw new Error(`Neut position ${configuration.neutPositionId} not found.`);

		return neutPosition;
	};

	const getCompartmentLayout = (compartmentLayoutTypeCode: CompartmentLayoutTypeCode) => {
		const neutPosition = getSelectedNeutPosition();

		return neutPosition.compartmentLayouts.find((cl) => cl.compartmentLayoutTypeCode === compartmentLayoutTypeCode);
	};

	const changeCompartmentCount = async (option: number): Promise<void> => {
		if (!('compartments' in configuration)) throw 'Invalid configuration type';

		if (!data.neutOptions.hasNeuten) return;

		setSelectedCompartmentCount(option);

		if (configuration.compartments.length === option) return;

		const compartments = [...configuration.compartments];
		const neuten = [...configuration.neuten];

		const update = {...configuration, compartments: compartments, neuten: neuten};

		const diff = option - compartments.length;
		const absDif = Math.abs(diff);

		if (diff < 0) {
			// Remove compartments and neuten but preserve last neut.
			compartments.splice(compartments.length - absDif, absDif);
			neuten.splice(neuten.length - absDif - 1, absDif);
		} else {
			// Add compartments and neuten

			const neutPosition = getSelectedNeutPosition();

			const compartmentLayout = getCompartmentLayout(data.defaultCompartmentLayoutTypeCode) ?? neutPosition.compartmentLayouts[0];

			const compartmentProto = await createCompartmentProto(neutPosition.id, compartmentLayout.compartmentLayoutTypeCode, undefined);

			const neutProto = createNeutProto(data.neutOptions.defaultNeutHeight);

			compartments.splice(compartments.length, 0, ...Array(absDif).fill(compartmentProto));
			neuten.splice(neuten.length - 1, 0, ...Array(absDif).fill(neutProto));

			Array.from({length: absDif}, (_) => compartmentProto);
			Array.from({length: absDif}, (_) => neutProto);

			const newNeutIndexes = Array.from({length: absDif}, (_, index) => compartments.length - absDif + index);

			await applyDrillHolesOnNewNeut(update, newNeutIndexes);
		}

		await setValues(update);
	};

	const applyDrillHolesOnNewNeut = async (c: SillConfiguration, neutIndexes: number[]): Promise<void> => {
		const allDrillHoles = await ApiClient.Pim.Configurator.calculateDrillHoles(data.neutOptions.drillHoleOptions!.positioningStrategyName, undefined, c).then((res) => res.data);

		neutIndexes.forEach((neutIndex) => {
			c.neuten[neutIndex].drillHoles = [];

			allDrillHoles[neutIndex].forEach((p) => {
				c.neuten[neutIndex].drillHoles?.push({position: p});
			});
		});
	};

	const setCompartment = async (index: number, newCompartment: SillConfigurationCompartment): Promise<void> => {
		if (!('compartments' in configuration)) throw 'Invalid configuration type';

		const newConfiguration = {...configuration, compartments: [...configuration.compartments]};

		newConfiguration.compartments[index] = newCompartment;

		const currentProfileIds = getCompartmentProfileIds(configuration.compartments[index]);
		const newProfileIds = getCompartmentProfileIds(newCompartment);

		const isProfileChanged = currentProfileIds.left !== newProfileIds.left || currentProfileIds.right !== newProfileIds.right;

		let calculatedDrillHoles: Vector[][] | undefined = undefined;

		if (isProfileChanged) {
			calculatedDrillHoles = await ApiClient.Pim.Configurator.calculateDrillHoles(data.neutOptions.drillHoleOptions!.positioningStrategyName, undefined, newConfiguration).then(
				(res) => res.data
			);
		}

		await setValues((curr) => {
			if (!hasNeuten()) return curr;

			const update = {...(curr as SillConfiguration)};
			if (isProfileChanged && calculatedDrillHoles) {
				update.neuten = update.neuten.map((neut, neutIndex) => {
					if (!neut) return neut;
					if (neutIndex !== index && neutIndex !== index + 1) return neut;

					return {
						...neut,
						drillHoles: neut.drillHoles?.map((dh, dhIndex) => {
							return {...dh, position: calculatedDrillHoles![neutIndex][dhIndex]};
						}),
					};
				});

				toast('Let op: Boorgat(en) voor neuten ' + (index + 1) + ' en ' + (index + 2) + ' opnieuw berekend!', {
					icon: '🔔',
					duration: 5000,
					style: {
						padding: '20px',
						marginTop: '40vh',
						background: 'lightgrey',
					},
				});
			}

			update.compartments[index] = newCompartment;

			return update;
		});
	};

	const setNeut = async (index: number, value: SillConfigurationNeut | null): Promise<void> => {
		if (!hasNeuten()) throw 'Invalid configuration type';

		await setFieldValue(`neuten[${index}]`, value);
	};

	const calculateDrillHoles = async (
		neutIndex: number,
		neut: SillConfigurationNeut,
		positioningStrategyName: NeutDrillHolePositioningStrategyName
	): Promise<null | Array<SillConfigurationNeutDrillHole>> => {
		if (!('compartments' in configuration)) throw 'Invalid configuration type';

		const c = {...configuration, neuten: [...configuration.neuten]};

		c.neuten[neutIndex] = neut;

		const points = await ApiClient.Pim.Configurator.calculateDrillHoles(positioningStrategyName, undefined, c).then((res) => res.data);

		return points[neutIndex].map((p) => ({position: p}));
	};

	let defaultCompartmentOptions: DefaultCompartmentEditorOptions | undefined;

	if (data.type !== SillType.Zijlicht) {
		if (!('neutPositionId' in configuration)) throw 'Invalid configuration type';

		if (!configuration.neutPositionId) throw new Error(`No neut position selected`);

		if (!data.compartmentOptions.neutPositions || data.compartmentOptions.neutPositions.length === 0) throw new Error(`No neut positions available`);

		const neutPosition: ConfiguratorNeutPosition = getSelectedNeutPosition();

		const compartmentLayoutOptions = data.compartmentLayoutOptions.filter((cl) => {
			return !!neutPosition!.compartmentLayouts.find((x) => x.compartmentLayoutTypeCode == cl.code);
		});

		defaultCompartmentOptions = {
			sillType: data.type,
			sillId: data.sillId,
			sillWidth: data.width,
			neutPosition: neutPosition,
			areas: data.areas,
			compartmentLayoutOptions: compartmentLayoutOptions,
			compartmentLayouts: neutPosition?.compartmentLayouts!,
			defaultCompartmentLayoutTypeCode: data.defaultCompartmentLayoutTypeCode,
			glazingBars: data.glazingBars,
			glazingProfiles: data.glazingProfiles,
			stopProfiles: data.stopProfiles,
			stopRabbetDepths: data.stopRabbetDepths,
			sluitpotHardware: data.sluitpotBeslag,
		};
	}

	const searchProfiles = async (compartmentLayoutTypeCode: CompartmentLayoutTypeCode, rabbetPosition?: RabbetPosition, filters?: Array<string>) => {
		return await ApiClient.Pim.Configurator.searchProfiles(
			data.sillId,
			(configuration as SillConfiguration).neutPositionId!,
			compartmentLayoutTypeCode,
			customerId ?? undefined,
			rabbetPosition,
			filters
		).then((res) => res.data);
	};

	const addFavoriteProfile = async (compartmentLayoutTypeId: string, profileId: string, name?: string): Promise<void> => {
		await ApiClient.Preferences.Preferences.addFavoriteProfile(data.sillId, compartmentLayoutTypeId, profileId, name, customerId ?? undefined);
	};

	const removeFavoriteProfile = async (compartmentLayoutTypeId: string, profileId: string): Promise<void> => {
		await ApiClient.Preferences.Preferences.deleteFavoriteProfile(data.sillId, compartmentLayoutTypeId, profileId, customerId ?? undefined);
	};

	const renameFavoriteProfile = async (compartmentLayoutTypeId: string, profileId: string, name: string): Promise<void> => {
		await ApiClient.Preferences.Preferences.renameFavoriteProfile(data.sillId, compartmentLayoutTypeId, profileId, name, customerId ?? undefined);
	};

	const canSetDefaultCompartmentProfile = useMemo(() => {
		return 'compartments' in configuration && getSelectedNeutPosition().indention.inside === 0 && getSelectedNeutPosition().indention.outside === 0;
	}, [configuration, data]);

	const setDefaultCompartmentProfile = async (compartmentLayoutTypeId: string, defaultProfile: SetDefaultCompartmentProfileRequest): Promise<void> => {
		await ApiClient.Preferences.Preferences.setDefaultCompartmentProfile(data.sillId, compartmentLayoutTypeId, customerId, undefined, defaultProfile);
	};

	const clearDefaultCompartmentProfile = async (compartmentLayoutTypeId: string): Promise<void> => {
		await ApiClient.Preferences.Preferences.clearDefaultCompartmentProfile(data.sillId, compartmentLayoutTypeId, customerId);
	};

	return {
		data: data,

		isZijlicht: data.type === SillType.Zijlicht,
		hasNeuten: data.neutOptions.hasNeuten,

		setInsulated,

		selectedAssemblyOption: assemblyOption,
		mountingOptions,
		preAssemblyMountingOptions,
		postAssemblyMountingOptions,
		compartmentCountOptions,
		selectedCompartmentCountOption: selectedCompartmentCount,

		changeAssemblyMethod,
		setMountingOption,
		setWrap,
		changeNeutPosition,
		changeCompartmentCount,

		wallConnectionOptions: data.wallConnectionOptions,
		setWallConnectionLeft,
		setWallConnectionRight,
		setCompartment,
		setNeut,

		changeCompartmentLayoutType,

		getCompartmentProfileIds,
		createCompartmentProto,

		calculateDrillHoles,
		defaultCompartmentOptions,
		configuration,
		searchProfiles,

		addFavoriteProfile,
		removeFavoriteProfile,
		renameFavoriteProfile,

		canSetDefaultCompartmentProfile,
		setDefaultCompartmentProfile,
		clearDefaultCompartmentProfile,
	};
};

function range(max: number) {
	let a: number[] = [];
	for (let i = 1; i <= max; i++) {
		a.push(i);
	}
	return a;
}
