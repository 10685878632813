import React from 'react';
import {Field, FieldProps} from 'formik';
import {
	ConfiguratorGlazingBar,
	ConfiguratorGlazingProfile,
	GlazingBar,
	GlazingProfile,
} from '../../../../modules/api-client/generated';
import {useQuery} from '@tanstack/react-query';
import CustomSelect, {SelectOption, OptionsType} from '../../../../shared/components/CustomSelect';
import ApiClient from '../../../../modules/api-client/ApiClient';

export interface GlazingProfilePickerSelectProps {
	name: string;
	multi?: boolean;
	required?: boolean;
	queryFn?: () => Promise<ItemCollectionType>;
	queryKey?: string;
}

export type GlazingProfilePickerProps = {
	required?: boolean;
	multi?: boolean;
	queryFn?: () => Promise<ItemCollectionType>;
	queryKey?: string;
};

export type ItemCollectionType = GlazingProfile[] | ConfiguratorGlazingProfile[];


const defaultQueryFn = () => ApiClient.Pim.GlazingProfile.pimGlazingProfilesGet().then((res) => res.data);

export const GlazingProfilePicker = ({required, multi, queryFn, queryKey}: GlazingProfilePickerProps): React.ComponentType<FieldProps<string>> => {
	let options: OptionsType<SelectOption> = [];

	const {data: list} = useQuery<ItemCollectionType>([queryKey ?? 'GlazingProfilePicker'], queryFn ?? defaultQueryFn);

	if (list) {
		options = list.map((item) => {
			return {value: item.id!, label: item.name!};
		});
	}

	required ??= true;

	return (props: FieldProps<string>) => <CustomSelect<SelectOption> options={options} isMulti={multi ?? false} required={required} isClearable={!required} placeholder={'Selecteer...'} {...props} />;
};

export const GlazingProfilePickerField: React.FC<GlazingProfilePickerSelectProps> = ({name, multi, required, queryFn, queryKey}) => {
	let options: OptionsType<SelectOption> = [];

	const {data: list} = useQuery<ItemCollectionType>([queryKey ?? 'GlazingProfilePickerField'], queryFn ?? defaultQueryFn);

	if (list) {
		options = list.map((item) => {
			return {value: item.id!, label: item.name!};
		});
	}

	return (
		<>
			<Field
				className="custom-select"
				name={name}
				options={options}
				component={CustomSelect}
				placeholder={multi ? 'Kies een of meerdere glaslatten...' : 'Kies een glaslat'}
				isMulti={multi}
				isClearable={!required}
			/>
		</>
	);
};
