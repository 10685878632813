import React, {FC, useId} from 'react';
import EkoFormField, {EkoFormLabel} from '../EkoFormField';
import {EkoFormRow} from '../EkoFormRow';
import {Field, FieldProps} from 'formik';
import {HefSchuifSlidingRailTypeComponent} from '../configurator/HefSchuifRailTypeField';

export const CustomerPreferencesHefSchuif: FC = () => {
	const fieldId = useId();

	return (
		<>
			<EkoFormField
				row={true}
				label={'Standaard glaslat'}
				field={{
					name: 'slidingRailType',
					// autoFocus: true,
					component: HefSchuifSlidingRailTypeComponent(),
				}}
			/>

			<EkoFormRow>
				<div className="col-4">
					<EkoFormLabel label={'Standaard rubberblok (LxB)'} />
				</div>
				<div className={'col'}>
					<div className="input-group">
						<Field name="doorBarCutout.length">
							{({field, form}: FieldProps) => <input {...field} id={`${fieldId}-length`} type="number" className="form-control" placeholder="Lengte" required min={1} />}
						</Field>
						<Field name="doorBarCutout.width">
							{({field, form}: FieldProps) => <input {...field} id={`${fieldId}-width`} type="number" className="form-control" placeholder="Breedte" required min={1} />}
						</Field>
						<span className="input-group-text">mm</span>
					</div>
					{/*<FieldErrors field={field.name} />*/}
				</div>
			</EkoFormRow>

			<EkoFormField
				row={true}
				label={'Standaard lat vastedeur inspringen'}
				field={{
					name: 'fixedDoorBarInset',
					className: 'form-control',
					type: 'number',
					min: 0,
				}}
			/>
		</>
	);
};
