/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {MasterdataRoutePath} from "../../masterdata/MasterdataRoutes";
import {MasterDataMenuItem} from "../../masterdata/MasterDataMenuItem";


const PimMasterData: FC = () => {
    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Configurator</span>
                </div>
            </div>

            {/* Configurator*/}
            {/*<MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.sillList)} title={'Dorpels'}/>*/}
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.rabbetList)} title={'Sponningen'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.colorList)} title={'Kleuren'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.drainageOptionList)} title={'Afwateringopties'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.mountingOptionList)} title={'Bevestigingsopties'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.wrappingOptionList)} title={'Inpakopties'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.glazingBarList)} title={'Glaslatten'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.cornerPieceList)} title={'Hoekstukken'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.glazingProfileList)} title={'Beglazingsprofielen'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.stopProfileList)} title={'Aanslagprofielen'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.beslagList)} title={'Sluitpotten'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.sluitpotList)} title={'Sluitpotblokjes'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.sluitpotBlockTypeList)} title={'Sluitpotblok types'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.turboholTypeList)} title={'Turbohol Types'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.compartmentLayoutType)} title={'Vakindeling Types'}/>

            {/* Catalogus */}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Catalogus</span>
                </div>
            </div>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.categoryList)} title={'Categorieen'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.pricelistList)} title={'Prijslijsten'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.discountGroupList)} title={'Kortingsgroepen'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.discountRuleList)} title={'Kortingsregels'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.catalogList)} title={'Catalogussen'}/>

            {/* Homepage */}
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Homepage</span>
                </div>
            </div>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.contentBlockList)} title={'Content blokken'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.contentImageList)} title={'Afbeeldingen'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.contentNewsItemList)} title={'Nieuws items'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.contentNotificationList)} title={'Notificaties'}/>
            <MasterDataMenuItem to={MasterdataRoutePath.link(MasterdataRoutePath.downloads)} title={'Downloads'}/>
        </>
    )
}

export {PimMasterData}
