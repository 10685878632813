import {createContext} from "react";

import {
    CompartmentLayoutTypeCode,
    ConfiguratorCompartmentProfiles, ConfiguratorProfilePickerProfileProfileSearchResult,
    HefSchuifInnerDoorPosition,
    HefSchuifSlidingDirection, RabbetPosition,
} from "../../../modules/api-client/generated";


export interface CompartmentEditorContextValue {
    profiles: ConfiguratorCompartmentProfiles | null;
    setProfiles: (profiles: ConfiguratorCompartmentProfiles) => void;
    flipProfiles: () => void;
    initializeProfiles: () => Promise<void>;

    isProfilesInitializing: boolean;
    profilesLoadError: string | undefined;
    clearProfilesLoadError: () => void;
    changeCompartmentLayoutType: (compartmentLayoutTypeCode: CompartmentLayoutTypeCode) => Promise<void>;

    searchProfiles: (compartmentLayoutTypeCode: CompartmentLayoutTypeCode, rabbetPosition?: RabbetPosition, filters?: Array<string>) => Promise<ConfiguratorProfilePickerProfileProfileSearchResult>;
    makeFavoriteProfile: (profileId: string, name?: string) => Promise<void>;
    removeFavoriteProfile: (profileId: string) => Promise<void>;
    renameFavoriteProfile: (profileId: string, name: string) => Promise<void>;

    canSetDefaultCompartmentProfile: boolean;
    setDefaultProfile: () => Promise<void>;
    clearDefaultProfile: () => Promise<void>;
}

export function insideOutsideToLsRs<T>(slidingDirection: HefSchuifSlidingDirection, inside: T, outside: T): {
    left: T,
    right: T
} {
    return slidingDirection === HefSchuifSlidingDirection.LeftSliding
        ? {left: inside, right: outside}
        : {left: outside, right: inside};
}

export function lsRsToInsideOutside<T>(slidingDirection: HefSchuifSlidingDirection, left: T, right: T): {
    inside: T,
    outside: T
} {
    return slidingDirection === HefSchuifSlidingDirection.LeftSliding
        ? {inside: left, outside: right}
        : {inside: right, outside: left};
}

export function insideOutsideToLR<T>(innerDoorPosition: HefSchuifInnerDoorPosition, inside: T, outside: T): {
    left: T,
    right: T
} {
    return innerDoorPosition === HefSchuifInnerDoorPosition.Left
        ? {left: inside, right: outside}
        : {left: outside, right: inside};
}

export function lrToInsideOutside<T>(innerDoorPosition: HefSchuifInnerDoorPosition, left: T, right: T): {
    inside: T,
    outside: T
} {
    return innerDoorPosition === HefSchuifInnerDoorPosition.Left
        ? {inside: left, outside: right}
        : {inside: right, outside: left};
}

export const CompartmentEditorContext = createContext<CompartmentEditorContextValue>(undefined!);