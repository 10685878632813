import React from 'react';
import {HefSchuifSlidingRailType} from '../../../modules/api-client/generated';
import {FieldProps} from 'formik';
import {HefSchuifSlidingRailTypeText} from './HefSchuifRailTypeText';

export const HefSchuifSlidingRailTypeField: React.FC<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>> = (props) => {
	props = {...props, value: props.value ?? '', className: 'form-select'};

	return (
		<>
			<select {...props}>
				<option value={''}>
					<HefSchuifSlidingRailTypeText value={undefined} />
				</option>
				<option value={HefSchuifSlidingRailType.Laag}>
					<HefSchuifSlidingRailTypeText value={HefSchuifSlidingRailType.Laag} />
				</option>
				<option value={HefSchuifSlidingRailType.Hoog}>
					<HefSchuifSlidingRailTypeText value={HefSchuifSlidingRailType.Hoog} />
				</option>
			</select>
		</>
	);
};

export const HefSchuifSlidingRailTypeComponent = (): React.ComponentType<FieldProps<string>> => {
	return ({field, form, ...props}) => {
		return <HefSchuifSlidingRailTypeField {...field} {...props} />;
	};
};
