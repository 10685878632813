/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {ReactElement, useCallback, useRef} from 'react'
import OrderlineTypeIcon from "../../shared/components/OrderlineTypeIcon";
import Euro from "../../shared/components/Euro";
import {OrderLineRepresentation, OrderStatusCodes} from "../../modules/api-client/generated";
import ConfiguredSillSpecification from "../../shared/components/ConfiguredSillSpecification";
import {createSearchParams, useNavigate} from "react-router-dom";
import {CustomerRoutePath} from "../routing/RoutePath";
import {useDeleteOrderline} from "../../backoffice/modules/sm/orders/hooks/orderline/use-delete-orderline";
import {useGetOrder} from "../../backoffice/modules/sm/orders/hooks/use-get-order";
import {ConfirmDialog} from "../../shared/components/ConfirmDialog";
import {
    PutOrderlineForm
} from "../../backoffice/modules/sm/orders/edit-order/components/forms/orderline/PutOrderlineForm";
import {CopySillForm} from "../../backoffice/modules/sm/orders/components/CopySillForm";
import {useCopyOrderLine} from "../../backoffice/modules/sm/orders/hooks/orderline/use-copy-orderline";
import {SbomPrice} from "../../backoffice/modules/sm/orders/components/SbomPrice";

type Props = {
    index: number,
    customerId: string | undefined,
    pricelistId: string,
    orderLine: OrderLineRepresentation
    status?: OrderStatusCodes
    setModalComponent: React.Dispatch<React.SetStateAction<ReactElement | undefined | null>>,
    setModalTitle: React.Dispatch<React.SetStateAction<string | undefined | null>>,
    setModalFullscreen: React.Dispatch<React.SetStateAction<true | string | 'sm-down' | 'md-down' | 'lg-down' | 'xl-down' | 'xxl-down'>>,
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>,
}
const OrderLineTableRow: React.FC<Props> = ({
                                                index
                                                , customerId
                                                , pricelistId
                                                , orderLine
                                                , setModalTitle
                                                , setModalFullscreen
                                                , setModalComponent
                                                , setShowEditModal
                                                , status
                                            }) => {

    const navigate = useNavigate();
    const mutateCopyOrder = useCopyOrderLine(orderLine.id);
    const {refetch: refetchOrder} = useGetOrder(orderLine.orderId, false, true, (data, query) => data?.status !== OrderStatusCodes.Completed && data?.status !== OrderStatusCodes.Cancelled && data?.status !== OrderStatusCodes.Accepted ? 5000 : false);


    if (!orderLine.id || !status) return <></>;

    const viewSillDetails = (catalogItemId: string) => {
        setModalComponent(<>
            <ConfiguredSillSpecification catalogItemId={catalogItemId} showDrawingAndNeuten={true}/>
        </>);
        setModalTitle('Dorpel');
        setModalFullscreen('xxl-down');
        setShowEditModal(true);
    }
    const editOrderline = (line: OrderLineRepresentation) => {
        setModalComponent(<PutOrderlineForm line={line} handleClose={() => setShowEditModal(false)}/>);
        setModalTitle('Orderregel aanpassen');
        setModalFullscreen('xxl-down');
        setShowEditModal(true);
    }

    const copyOrderLine = async (line: OrderLineRepresentation) => {
        if (line.type === 'configurationLine') {
            setModalComponent(<CopySillForm line={line} handleClose={() => setShowEditModal(false)}/>
            );
            setModalTitle('Dorpel kopiëren');
            setModalFullscreen('xxl-down');
            setShowEditModal(true);
        } else {
            await mutateCopyOrder.mutateAsync(line);
            refetchOrder();
        }
    }
    const editConfiguredSill = (line: OrderLineRepresentation) => {
        if (line.type !== 'configurationLine') return;
        navigate({
            pathname: CustomerRoutePath.link(CustomerRoutePath.orderConfiguratorLink(line.orderId)),
            search: createSearchParams({
                orderLineId: line.id,
                ...(line.catalogItemId ? {configurationId: line.catalogItemId} : {}),
                ...(customerId ? {customerId: customerId} : {}),
                pl: pricelistId,
                q: line.quantity.toString(),
                ...(line.remark ? {remark: line.remark} : {}),
                ...(line.merk ? {merk: line.merk} : {})
            }).toString()
        });
    }
    return (
        <>
            <tr>
                <td className={'text-right'}>{index + 1}</td>
                <td className={'text-center'}><OrderlineTypeIcon type={orderLine.type}/></td>
                <td className={'text-center'}>{orderLine.quantity}</td>
                <td>
                    {orderLine.type === 'configurationLine' ?
                        <a href={'#'} onClick={() => viewSillDetails(orderLine.catalogItemId as string)}>
                            {orderLine.title}
                        </a> :
                        <>{orderLine.title}</>
                    }
                    {orderLine.description &&
                        <>
                            <div className={'text-muted fs-7'}>{orderLine.description}</div>
                        </>
                    }
                    {orderLine.remark &&
                        <>
                            <div className={'text-muted fs-7'}>{orderLine.remark}</div>
                        </>
                    }
                </td>
                <td>{orderLine.merk}</td>
                <td className={'text-end'}>
                    <SbomPrice Sbom={orderLine.sbom} price={orderLine.price}/>
                </td>
                <td className={'text-end'}><Euro value={orderLine.total}/></td>
                <td className='text-end fit-content'>
                    {(status === OrderStatusCodes.Draft || status === OrderStatusCodes.Placed) && <>

                        {orderLine.type === 'configurationLine' &&
                            <button type="button" className={`btn btn-icon btn-sm btn-light me-2 `}
                                    title='Edit dorpel configuratie' onClick={() => editConfiguredSill(orderLine)}>
                                <i className="fas fa-wand-magic-sparkles"></i>
                            </button>
                        }

                        {orderLine.type === 'configurationLine' &&
                            <button type="button" className={`btn btn-icon btn-sm btn-light me-2 `}
                                    title='Maak een kopie van deze order regel'
                                    onClick={() => copyOrderLine(orderLine)}>
                                <i className="fas fa-copy"></i>
                            </button>
                        }

                        <button type="button" className={`btn btn-icon btn-sm btn-light me-2 `}
                                title='Edit order regel' onClick={() => editOrderline(orderLine)}>
                            <i className="fas fa-edit"></i>
                        </button>

                        <DeleteButton orderLine={orderLine} key={'delete-orderline-' + orderLine.id}/>
                    </>
                    }

                </td>
            </tr>
        </>
    )
}
const DeleteButton: React.FC<{ orderLine: OrderLineRepresentation }> = ({orderLine}) => {

    const openDeleteDialog = useRef<(show: boolean) => void>();
    const deleteMutation = useDeleteOrderline(orderLine.id);
    const {refetch: refetchOrder} = useGetOrder(orderLine.orderId, false, false);

    const deleteCallback = useCallback(async () => {
        await deleteMutation.mutateAsync(undefined, {});
        openDeleteDialog.current?.call(this, false)
        refetchOrder();
    }, [deleteMutation, refetchOrder]);

    return (
        <>
            <a href="#" className="btn btn-icon btn-sm btn-light-danger"
               onClick={() => openDeleteDialog.current?.call(this, true)}>
                <i className="fas fa-trash"></i>
            </a>

            <ConfirmDialog show={openDeleteDialog}
                           onConfirm={deleteCallback}
                           dialogStyle="danger"
                           title="Verwijderen"
                           message="Wil je doorgaan?"
                           confirmText="Definitief verwijderen"

            />
        </>
    )
}

export {OrderLineTableRow}
