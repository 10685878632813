import ApiClient from '../../../modules/api-client/ApiClient';
import {PreferencesDeurBinnendraaiend, PreferencesDraaiKiep, PreferencesHefSchuif, PreferencesVastglasBinnen, PreferencesVastglasBuiten} from '../../../modules/api-client/generated';
import React from 'react';
import {useQuery, useQueryClient} from '@tanstack/react-query';
import {CustomerPreferencesVastglasBinnen} from './CustomerPreferencesVastglasBinnen';
import {OpenOptions, usePreferenceEditModal} from './usePreferenceEditModal';
import {CustomerPreferencesVastglasBuiten} from './CustomerPreferencesVastglasBuiten';
import {CustomerPreferencesDraaiKiep} from './CustomerPreferencesDraaiKiep';
import {CustomerPreferencesDeurBuitendraaiend} from './CustomerPreferencesDeurBuitendraaiend';
import {CustomerPreferencesHefSchuif} from './CustomerPreferencesHefSchuif';

export function useCustomerPreferencesOverview(customerId: string | undefined) {
	const queryClient = useQueryClient();
	const queryKey = ['customerPreferences', customerId];

	const {
		data: preferences,
		isInitialLoading,
		isError,
	} = useQuery(['customerPreferences', customerId], async () => {
		return await ApiClient.Preferences.Preferences.getPreferences(customerId).then((x) => x.data);
	});

	const modal = usePreferenceEditModal();

	const invalidate = () => queryClient.invalidateQueries(queryKey);

	const editPreferencesHefSchuif = async (initialValue: PreferencesHefSchuif) => {
		const options: OpenOptions<PreferencesHefSchuif> = {
			title: 'Hef-/schuif',
			value: initialValue,
			formFields: () => <CustomerPreferencesHefSchuif />,
			save: async (update) => {
				await ApiClient.Preferences.Preferences.setPreferencesHefSchuif(customerId, undefined, update);
				await invalidate();
			},
			reset: async () => {
				await ApiClient.Preferences.Preferences.unsetPreferencesHefSchuif(customerId, undefined, undefined);
				await invalidate();
			},
		};
		modal.open(options);
	};

	const editPreferencesVastglasBinnen = async (initialValue: PreferencesVastglasBinnen) => {
		const options: OpenOptions<PreferencesVastglasBinnen> = {
			title: 'Vastglas binnen',
			value: initialValue,
			formFields: () => <CustomerPreferencesVastglasBinnen />,
			save: async (update) => {
				await ApiClient.Preferences.Preferences.setPreferencesVastglasBinnen(customerId, undefined, update);
				await invalidate();
			},
			reset: async () => {
				await ApiClient.Preferences.Preferences.unsetPreferencesVastglasBinnen(customerId, undefined, undefined);
				await invalidate();
			},
		};

		modal.open(options);
	};

	const editPreferencesVastglasBuiten = async (initialValue: PreferencesVastglasBuiten) => {
		const options: OpenOptions<PreferencesVastglasBuiten> = {
			title: 'Vastglas buiten',
			value: initialValue,
			formFields: () => <CustomerPreferencesVastglasBuiten />,
			save: async (update) => {
				await ApiClient.Preferences.Preferences.setPreferencesVastglasBuiten(customerId, undefined, update);
				await invalidate();
			},
			reset: async () => {
				await ApiClient.Preferences.Preferences.unsetPreferencesVastglasBuiten(customerId, undefined, undefined);
				await invalidate();
			},
		};
		modal.open(options);
	};

	const editPreferencesDraaiKiep = async (initialValue: PreferencesDraaiKiep) => {
		const options: OpenOptions<PreferencesDraaiKiep> = {
			title: 'Draai-/kiep',
			value: initialValue,
			formFields: () => <CustomerPreferencesDraaiKiep />,
			save: async (update) => {
				await ApiClient.Preferences.Preferences.setPreferencesDraaiKiep(customerId, undefined, update);
				await invalidate();
			},
			reset: async () => {
				await ApiClient.Preferences.Preferences.unsetPreferencesDraaiKiep(customerId, undefined, undefined);
				await invalidate();
			},
		};

		modal.open(options);
	};

	const editPreferencesDeurBinnendraaiend = async (initialValue: PreferencesDeurBinnendraaiend) => {
		const options: OpenOptions<PreferencesDeurBinnendraaiend> = {
			title: 'Deur binnendraaiend',
			value: initialValue,
			formFields: () => <CustomerPreferencesDeurBuitendraaiend />,
			save: async (update) => {
				await ApiClient.Preferences.Preferences.setPreferencesDeurBinnendraaiend(customerId, undefined, update);
				await invalidate();
			},
			reset: async () => {
				await ApiClient.Preferences.Preferences.unsetPreferencesDeurBinnendraaiend(customerId, undefined, undefined);
				await invalidate();
			},
		};

		modal.open(options);
	};

	return {
		component: modal.component,
		isInitialLoading,
		isLoadingError: isError,
		preferences,
		editPreferencesHefSchuif,
		editPreferencesVastglasBinnen,
		editPreferencesVastglasBuiten,
		editPreferencesDraaiKiep,
		editPreferencesDeurBinnendraaiend,
	};
}
