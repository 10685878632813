import React from 'react';
import {useQuery} from '@tanstack/react-query';
import {ConfiguredSill} from '../../modules/api-client/generated';
import ApiClient from '../../modules/api-client/ApiClient';
import Loading from './Loading';
import ConfiguredSillSvg from './ConfiguredSillSvg';
import {DestructCompartment} from '../../backoffice/modules/core';
import Error from './Error';
import {useProductionItemGetNeuten} from "../../backoffice/modules/pm/hooks/use-get-production-item-neuten";
import {EkoCard, EkoCardBody} from "./card";
import BooleanBadge from "./BooleanBadge";
import {Mm} from "./Mm";

export interface ConfiguredSillSpecificationProps {
    catalogItemId: string;
    showDrawingAndNeuten?: boolean,

}

const ConfiguredSillSpecification: React.FC<ConfiguredSillSpecificationProps> = (props) => {
    const queryKey = ['ApiClient.Core.ConfiguredSill.getForCatalogItem', props.catalogItemId];

    const {
        isInitialLoading,
        isLoading,
        data,
        isError,
    } = useQuery(queryKey, async () => await ApiClient.Core.ConfiguredSill.getForCatalogItem(props.catalogItemId!).then((x) => x.data));


    if (isLoading || isInitialLoading) {
        return <Loading message={'Dorpelspecificatie ophalen....'}/>;
    }

    if (isError) return <Error/>;

    const {id, sill, svg} = data;

    if (!sill) return <></>;

    return <ConfiguredSillSpecificationDetails configuredSillId={id} sill={sill} svg={svg} showDrawingAndNeuten={props.showDrawingAndNeuten}/>;
};

interface ConfiguredSillSpecificationDetails {
    configuredSillId: string
    sill: ConfiguredSill,
    svg: string,
    svgHeight?: string,
    showDrawingAndNeuten?: boolean,
}

export const ConfiguredSillSpecificationDetails: React.FC<ConfiguredSillSpecificationDetails> = (props) => {
    const sill = props.sill;

    const protectors = sill.compartments.filter((c) => c.protector).length;
    return (
        <>
        {props.showDrawingAndNeuten == true && (
            <>
            <ConfiguredSillSvg svg={props.svg} svgHeight={props.svgHeight ?? '200px'}
                               onNeutClick={(index) => console.log(index)} />
            <div className={'my-4'}>
                <div className={'row mb-4'}>
                    <div className={'col-3 px-1'}>
                        <img className="img-fluid"
                             src={`https://bestellen.ekosiet.nl/media/dorpels/${sill.model.code}.png`}
                             title={sill.model.code} alt={sill.model.code} />
                    </div>
                    <div className={'col-3'}>
                        <strong>{sill.model.code}</strong> <br />
                        Breedte: {sill.model.width} mm <br />
                        Lengte: {sill.totalLength} mm <br />
                        {sill.compartments.length} vakken / {sill.neuten?.length ?? 0} neuten <br />
                    </div>
                    <div className={'col-3'}>
                        Inpakken: {sill.wrap ? 'Ja' : 'Nee'} <br />
                        Beschermkappen meeleveren: {protectors} <br />
                    </div>
                </div>
                <hr />
                <div className={'row mb-4'}>
                    {sill.neuten?.map((neut, i) => {
                        return (
                            <div key={i} className={'col-3'}>
                                <div className="p-3">{/*{RenderNeut(neut)}*/}</div>
                                <div className="d-flex flex-column">
                                    {neut ? (
                                        <>
                                            <div>
                                                <strong>
                                                    Neut {i + 1}: {neut.width}mm
                                                </strong>
                                            </div>
                                            <div>Hoogte: {neut.height}mm</div>
                                        </>
                                    ) : (
                                        <>
                                            <div>
                                                <strong>Neut {i + 1}: Weggelaten</strong>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {/*{(rabbetLeft != null ? $"<div>Sponning links: {rabbetLeft.Width}*{rabbetLeft.Depth}mm</div>" : "")}*/}
                                {/*{(rabbetRight != null ? $"<div>Sponning rechts: {rabbetRight.Width}*{rabbetRight.Depth}mm</div>" : "")}*/}
                            </div>
                        );
                    })}
                </div>
                </div>
            </>
            )}

            <div className={'row'}>
                {sill.compartments.map((compartment, i) => {
                    const {
                        glazingBar,
                        glazingProfile,
                        lProfile,
                        stopProfile,
                        aerationHoles,
                        drainageHoles,
                        sluitpotten,
                    } = DestructCompartment(compartment);

                    return (
                        <div key={i} className={'col-3'}>
                            <EkoCard className={'border'}>
                                <EkoCardBody>
                                    <h1 className={'text-center'}>Vak {i + 1}</h1>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item d-flex justify-content-between">Vakindeling: <span
                                            className={'fw-bold'}> {compartment.compartmentLayoutTypeCode} </span></li>
                                        <li className="list-group-item d-flex justify-content-between">Dagmaat: <span
                                            className={'fw-bold'}> <Mm value={compartment.dagmaat}/> </span></li>
                                        <li className="list-group-item d-flex justify-content-between">Beschermkap: <span
                                            className={'fw-bold'}> <BooleanBadge value={compartment.protector}/> </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">Beluchtingsgaten: <span
                                            className={'fw-bold'}> <BooleanBadge
                                            value={aerationHoles?.length! > 0}/> </span>
                                        </li>
                                        <li className="list-group-item d-flex justify-content-between">Afwateringsgaten: <span
                                            className={'fw-bold'}> <BooleanBadge
                                            value={drainageHoles?.length! > 0}/> </span>
                                        </li>
                                        {glazingBar && (
                                            <>
                                                <li className="list-group-item d-flex justify-content-between">Glaslat: <span
                                                    className={'fw-bold'}> {glazingBar.width}x{glazingBar.height}mm: <span>{glazingBar.length}mm </span></span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between">Hoekstukjes: <span
                                                    className={'fw-bold'}><BooleanBadge value={glazingBar.cornerPieceLeft != undefined}/></span>
                                                </li>
                                            </>
                                        )}
										{glazingProfile && (
											<li className="list-group-item d-flex justify-content-between">Beglazingsprofiel: <span
												className={'fw-bold'}>{glazingProfile.width}x{glazingProfile.height}mm: <span>{glazingProfile.length}mm </span></span>
											</li>
										)}

                                        {lProfile && (
												<li className="list-group-item d-flex justify-content-between">L-profiel: <span
													className={'fw-bold'}>{lProfile.width}x{lProfile.height}mm: <span>{lProfile.length}mm </span></span>
												</li>
										)}
										{stopProfile && (
											<li className="list-group-item d-flex justify-content-between">Aanslagprofiel: <span
												className={'fw-bold'}>{stopProfile.width}x{stopProfile.height}mm: <span>{stopProfile.length}mm </span></span>
											</li>
										)}

                                        {sluitpotten?.map((sluitpot, i) => {
                                            return (
												<React.Fragment key={i}>
													<li className="list-group-item d-flex justify-content-between">Sluitpot: <span
														className={'fw-bold'}>{sluitpot.sluitpotHardware?.code}</span>
													</li>
													<li className="list-group-item d-flex justify-content-between">Beslag meeleveren?: <span
														className={'fw-bold'}><BooleanBadge value={sluitpot.includeHardware}/></span>
													</li>
												</React.Fragment>
											);
										})}

									</ul>
								</EkoCardBody>
							</EkoCard>
						</div>

					);
				})}
			</div>
		</>
	);
};

export default ConfiguredSillSpecification;
