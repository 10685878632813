import React from "react";
import {Form, Formik, FormikHelpers} from "formik";
import {usePriceMutation} from "./hooks";
import {
    ChangePriceCommand,
    ItemPrice,
    PriceChangeType,
    PutProductionItemDateRepresentation
} from "../../../../modules/api-client/generated";
import {useQuery} from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import TextField from "../../../../shared/components/TextField";
import Euro from "../../../../shared/components/Euro";
import SubmitButton from "../../../../shared/components/SubmitButton";
import {EkoTable} from "../../../../shared/components/table/EkoTable";
import {TableHeader} from "../../../../shared/components/table/TableHeader";
import Loading from "../../../../shared/components/Loading";
import {formatDate} from "../../../../shared/components/date";
import {DateField} from "../../../../shared/components/date/DateField";
import {DateInput, DateTime} from "luxon";

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
    }, {} as Record<K, T[]>);

type Props = {
    handleClose: () => void,
    id: string,
}
type FormData = Omit<ChangePriceCommand, 'effectiveDay'> & {
    effectiveDay: DateTime;
};
export const defaultPriceListId = "00000000-0000-0000-0000-000000000000";

const CatalogItemPricingUpsert: React.FC<Props> = ({handleClose, id}) => {

    const {mutate, serverValidationErrors} = usePriceMutation();

    const onSubmit = (values: FormData, {setSubmitting}: FormikHelpers<FormData>) => {
        return new Promise<void>((resolve, reject) => {
            const mappedValues: ChangePriceCommand = {
                ...values,
                effectiveDay: values.effectiveDay ? values.effectiveDay.toISODate()! : DateTime.now().toISODate()!,
            };
            mutate(mappedValues, {
                onSuccess: () => {
                    resolve();
                    // handleClose();

                },
                onError: () => {
                    reject();
                }
            });

        }).finally(() => setSubmitting(false));
    };

    const {
        data: data,
        isInitialLoading,
        isError,
    } = useQuery<ItemPrice[]>(['pimPricesGet'], () => ApiClient.Sm.Pricing.smPricesGet([id!]).then((res) => res.data));

    if (isInitialLoading) return (<Loading />)
    if (isError) return (<>Error...</>)
    if (id && !data) return (<>'{id}' niet gevonden.</>)
    // if (data!.length === 0) return (<>Geen prijs voor dit product gevonden.</>)

    const grouped = groupBy(data!.filter((price: ItemPrice) => price.priceList?.id !== defaultPriceListId), i => i.priceList?.name!)

    Object.entries(grouped).map((value) => console.log(value));

    const initialValues: FormData = {
        priceChangeType: PriceChangeType.Change,
        catalogItemId: id,
        effectiveDay: DateTime.now(),
        newPrice: 0.00,
        note: null,
    };


    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({values, handleSubmit, setFieldValue}) =>
                    <>

                        <div className="row mb-4">
                            <div className="col-4">
                                <label className="form-label" htmlFor="type">Standaard prijs</label>
                            </div>
                            <div className="col-8">
                                <Form>
                                    <EkoTable>
                                        <TableHeader>
                                            <th>Vanaf</th>
                                            <th className={'text-center'}>Prijs</th>
                                            <th></th>
                                        </TableHeader>
                                        <tbody>
                                        {data!.filter((price: ItemPrice) => price.priceList?.id === defaultPriceListId).map((price: ItemPrice, index) =>
                                            <tr key={index}>
                                                <td>{formatDate(price.startDate)}</td>
                                                <td className={'text-center'}>
                                                    <Euro value={price.price?.toFixed(2)}/>
                                                </td>
                                            </tr>
                                        )
                                        }
                                        <tr>
                                            <td>
                                                <input type="date" id="effectiveDay" className={'form-control'} name="effectiveDay"
                                                       value={values.effectiveDay.toISODate()!}
                                                       onChange={e => setFieldValue('effectiveDay',DateTime.fromISO(e.target.value))}
                                                />
                                            </td>
                                            <td className={'text-end'}><TextField row={false} name={'newPrice'}
                                                                                  type={'number'}/></td>
                                            <td><SubmitButton type="submit" className="btn btn-primary"><i
                                                className={'fas fa-plus'}></i></SubmitButton></td>
                                        </tr>
                                        </tbody>
                                    </EkoTable>
                                </Form>

                            </div>
                        </div>

                        {Object.keys(grouped).map((key, index) => {
                                return (
                                    <div className="row mb-4" key={index}>
                                        <div className="col-4">
                                            <label className="form-label" htmlFor="type">{key}</label>
                                        </div>
                                        <div className="col-8">
                                            <EkoTable>
                                                <TableHeader>
                                                    <th>Vanaf</th>
                                                    <th className={'text-center'}>Prijs</th>
                                                    <th></th>
                                                </TableHeader>
                                                <tbody>
                                                {grouped[key].map((price: ItemPrice, index) =>
                                                    <tr key={index}>
                                                        <td>{formatDate(price.startDate)}</td>
                                                        <td className={'text-center'}>
                                                            <Euro value={price.price?.toFixed(2)}/>
                                                        </td>
                                                    </tr>
                                                )
                                                }
                                                </tbody>
                                            </EkoTable>
                                        </div>
                                    </div>
                                )
                            }
                        )
                        }

                    </>
                }

            </Formik>
        </>

    );
}


export default CatalogItemPricingUpsert;