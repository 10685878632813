import React, { useLayoutEffect } from "react";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import SubmitButton from "../../../../shared/components/SubmitButton";
import { ValidationErrors } from "../../../../modules/api-client/Responses";
import TextField from "../../../../shared/components/TextField";
import { useSluitpotBeslagQuery, useSluitpotBeslagUpsertMutation } from "./hooks";
import Loading from "../../../../shared/components/Loading";
import SvgDropzone from "../components/SvgDropzone";
import SluitpotBeslagSvg from "../../../../shared/components/SluitpotBeslagSvg";
import { FileUpload } from "../../../../shared/components/file-upload/FileUpload";
import { FileCategory } from "../../../../modules/api-client/generated";

export interface FormValues {
    code: string;
    active: boolean;
    name: string;
    description: string;
    svg: string;
    imageId?: string;
    blobUri?: string;
}

type Props = {
    handleClose: () => void,
    id?: string,
}

const SluitpotBeslagUpsert: React.FC<Props> = ({ handleClose, id }) => {

    const { mutate, serverValidationErrors } = useSluitpotBeslagUpsertMutation(id);

    const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
        try {
            return await new Promise<void>((resolve, reject) => {
                mutate(values, {
                    onSuccess: () => {
                        resolve();
                        handleClose();

                    },
                    onError: () => {
                        reject();
                    }
                });

            });
        } finally {
            return setSubmitting(false);
        }
    };

    const { data: beslag, isInitialLoading, isError } = useSluitpotBeslagQuery(id);
    if (isInitialLoading) return <Loading />
    if (isError) return (<>Error...</>)
    if (id && !beslag) return (<>'{id}' niet gevonden.</>)

    const initialValues: FormValues = {
        code: beslag?.code ?? '',
        active: beslag?.active ?? false,
        name: beslag?.name ?? '',
        description: beslag?.description ?? '',
        svg: beslag?.svg ?? '',
        imageId: beslag?.imageReference?.id ?? undefined,
        blobUri: beslag?.imageReference?.blobUri ?? undefined,
    };

    return (
        <>
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                <SluitpotBeslagUpsertForm id={id} serverValidationErrors={serverValidationErrors} />
            </Formik>
        </>
    );
}

interface SluitpotBeslagUpsertFormProps {
    id: string | undefined;
    serverValidationErrors: ValidationErrors | null;
}

export const SluitpotBeslagUpsertForm: React.FC<SluitpotBeslagUpsertFormProps> = (props) => {
    const { values, handleSubmit, handleChange, isSubmitting, errors, setFieldValue, setErrors } = useFormikContext<FormValues>();
    const mergeServerErrors = () => {

        if (props.serverValidationErrors) {
            setErrors({ ...errors, ...props.serverValidationErrors });
        }
    }
    useLayoutEffect(mergeServerErrors, [props.serverValidationErrors, errors, setErrors]);
    return (<form onSubmit={handleSubmit}>
        <TextField row={true} name={'code'} label={'Unieke code'} />

        <TextField row={true} name={'name'} label={'Naam'} />

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label" htmlFor="type">Actief</label>
            </div>
            <div className="col-8">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch"
                        name="active"
                        value="true"
                        onChange={handleChange}
                        checked={values.active}
                        id="active" />
                    <label className="form-check-label" htmlFor="active">Actief</label>
                </div>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label">Omschrijving</label>
            </div>
            <div className="col-8">
                <textarea rows={5} className="form-control" name="description"
                    placeholder=""
                    onChange={handleChange}
                    value={values.description}></textarea>
            </div>
        </div>
        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label">Svg</label>
            </div>
            <div className="col-8 position-relative">
                <SvgDropzone
                    onSelect={(selected) => setFieldValue('svg', selected)}
                    preview={(svg) => <SluitpotBeslagSvg svg={svg} />}
                    show={'drag'}>
                    {({ openFileDialog }) => <>
                        <div
                            className={`form-control d-flex justify-content-center align-items-center ${values.svg ? '' : 'cursor-pointer'}`}
                            onClick={() => {
                                if (!values.svg) openFileDialog();
                            }}
                            onDoubleClick={openFileDialog}
                            style={{ height: '200px' }}>

                            {values.svg &&
                                <SluitpotBeslagSvg svg={values.svg} />
                            }

                            {!values.svg &&
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center">
                                    <button type="button"
                                        className="btn btn-light-primary me-3"
                                        onClick={openFileDialog}><i
                                            className="fa-regular fa-folder-open"></i> Blader
                                    </button>
                                    <div className="mt-2">of sleep een .SVG bestand hier
                                    </div>
                                </div>}
                        </div>
                        {values.svg &&
                            <div className="py-3 pb-0 d-flex justify-content-start">
                                <button type="button" className="btn btn-light-primary me-3"
                                    onClick={openFileDialog}>Wijzig svg
                                </button>
                            </div>}
                    </>
                    }
                </SvgDropzone>
            </div>
        </div>

        <div className="row mb-4">
            <div className="col-4">
                <label className="form-label" htmlFor="type">Afbeelding</label>
            </div>
            <div className="col-8 position-relative" style={{ height: '100%' }}>
                <FileUpload
                    onFileUpload={(fileId) => setFieldValue('imageId', fileId)}
                    show='drag'
                    fileCategory={FileCategory.SluitpotBeslag}
                    sasUri={values.blobUri ?? ''}
                    uploadType='image'
                />
            </div>
        </div>

        <div className="row pt-5">
            <div className="offset-4 col-8 d-flex justify-content-end">
                <SubmitButton type="submit" className="btn btn-primary"
                    isSubmitting={isSubmitting}>Opslaan</SubmitButton>
            </div>
        </div>

    </form>);
}

export default SluitpotBeslagUpsert;