import { useQueryClient } from "@tanstack/react-query";
import ApiClient from "../../../../modules/api-client/ApiClient";
import { NotFound, ValidationFailed } from "../../../../modules/api-client/Responses";
import { useEkosietMutation } from "../../../../shared/hooks/useEkosietMutation";

export const useNewsletterSubscriberDeleteMutation = (id: string) => {
    const queryClient = useQueryClient()
    return useEkosietMutation<void, NotFound | ValidationFailed, string>((id) => {
            return ApiClient.Crm.Newsletter.crmNewsletterIdDelete(id, undefined).then((response: any) => response.data);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['newsletterSubscribersListGet']).then();
            }
        }
    );
};