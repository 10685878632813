import React, {FC} from 'react';
import ApiClient from '../../../modules/api-client/ApiClient';
import EkoFormField from '../EkoFormField';
import {StopProfilePicker} from '../../../backoffice/modules/pim/stopprofile/StopProfilePicker';
import {StopRabbetPicker} from '../configurator/StopRabbetPicker';

export const CustomerPreferencesDraaiKiep: FC = () => {
	const stopProfilesQueryFn = () => ApiClient.Preferences.Preferences.getStopProfiles().then((res) => res.data);
	const stopRabbetsQueryFn = () => ApiClient.Preferences.Preferences.getStopRabbets().then((res) => res.data);

	return (
		<>
			<EkoFormField
				row={true}
				label={'Standaard aanslagprofiel'}
				field={{
					name: 'stopProfileId',
					component: StopProfilePicker({
						required: false,
						multi: false,
						queryFn: stopProfilesQueryFn,
					}),
				}}
			/>

			<EkoFormField
				row={true}
				label={'Standaard aanslagsponning'}
				field={{
					name: 'stopRabbetDepth',
					component: StopRabbetPicker({
						required: false,
						queryFn: stopRabbetsQueryFn,
					}),
				}}
			/>
		</>
	);
};
