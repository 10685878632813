import React from 'react';
import {
    SillDetails
} from "../../../../../modules/api-client/generated";
import {EkoCard, EkoCardBody, EkoCardHeader, EkoCardToolbar} from "../../../../../shared/components/card";
import {EkoTable, TableHeader} from "../../../../../shared/components/table";
import {useSillCompartmentLayoutsManage} from "../hooks/use-sill-compartment-layouts-manage";
import SpinnerButton from "../../../../../shared/components/SpinnerButton";

export interface SillDetailsCompartmentLayoutsCardProps {
    sill: SillDetails;
}

export const SillDetailsCompartmentLayoutsCard: React.FC<SillDetailsCompartmentLayoutsCardProps> = ({sill}) => {

    const {
        options,
        add,
        confirmRemove,
        upsertModalElement,
        isSaving,
        openEditModal,

    } = useSillCompartmentLayoutsManage(sill);

    return <EkoCard className={'mb-3'}>
        <EkoCardHeader title={'Vakindelingen'}>
            <EkoCardToolbar>

                <div className="dropdown">
                    <SpinnerButton type="button" className="btn btn-primary dropdown-toggle"
                                   data-bs-toggle="dropdown"
                                   aria-expanded="false" spinning={isSaving}>Toevoegen
                    </SpinnerButton>
                    <ul className="dropdown-menu">
                        {options.map(o => <li key={o.id} className="dropdown-item"
                                              onClick={async () => await add(o)}>{o.name}</li>)}
                    </ul>
                </div>

            </EkoCardToolbar>
        </EkoCardHeader>
        <EkoCardBody>

            <div className="row">
                <div className="col-12 col-xl-8 col-xxl-6">
                    <div className="mb-5">

                        <EkoTable>
                            <TableHeader>
                                <th>Naam</th>
                                <th>Opties</th>
                                <th></th>
                            </TableHeader>
                            <tbody>
                            {sill.compartmentLayouts.map((cl) => {
                                return <tr key={cl.id}>
                                    <td>{cl.name}</td>
                                    <td>
                                        {'defaultSlidingDirection' in cl && <div>
                                            {cl.defaultSlidingDirection}
                                        </div>}

                                        {'defaultInnerDoorPosition' in cl && <div>
                                            {cl.defaultInnerDoorPosition}
                                        </div>}
                                    </td>
                                    <td>
                                        <div className="dropdown">
                                            <a className="dropdown-toggle text-body px-2" href="#" role="button"
                                               data-bs-toggle="dropdown" aria-expanded="false">
                                            </a>

                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li>
                                                    <button type="button" className="dropdown-item"
                                                            onClick={() => openEditModal(cl)}>
                                                        Wijzigen
                                                    </button>
                                                </li>

                                                <li className="dropdown-divider"></li>

                                                <li>
                                                    <button type="button" className="dropdown-item text-danger"
                                                            onClick={async () => await confirmRemove(cl.id)}>
                                                        Verwijderen
                                                    </button>
                                                </li>
                                            </ul>

                                        </div>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                        </EkoTable>
                    </div>
                </div>
            </div>
            {upsertModalElement}
        </EkoCardBody>
    </EkoCard>
};



