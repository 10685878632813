import React from 'react';
import {Field} from 'formik';
import FieldErrors from './FieldErrors';
import {FieldAttributes} from 'formik/dist/Field';
import {EkoFormRow} from './EkoFormRow';

export type EkoFormFieldProps = {
	row?: boolean;
	label?: string | null;
	help?: string | null;
	field: FieldAttributes<any>;
};

const EkoFormField: React.FC<EkoFormFieldProps> = ({row, label, help, field}) => {

	const labelComponent = label && <EkoFormLabel htmlFor={field.id} label={label} />;

	return (
		<>
			{row ? (
				<EkoFormRow>
					<div className="col-4">
						{labelComponent}
						{help && <small className="d-block text-muted">{help}</small>}
					</div>
					<div className={'col'}>
						<Field {...field} />
						<FieldErrors field={field.name} />
					</div>
				</EkoFormRow>
			) : (
				<div className={'mb-4'}>
					{labelComponent}
					<Field {...field} />
					{help && <small className="form-text text-muted">{help}</small>}
					<FieldErrors field={field.name} />
				</div>
			)}
		</>
	);
};

export const EkoFormLabel: React.FC<{htmlFor?: string; label: string}> = ({htmlFor, label}) => {
	return (
		<label className="form-label" htmlFor={htmlFor}>
			{label}
		</label>
	);
};

export default EkoFormField;
